#plaid-success {
  padding-top: 50px;
  text-align: left;
}
#plaid-success .gongxiImg {
  width: 60px;
  height: 60px;
  margin-bottom: 16px;
  margin-left: 38px;
}
#plaid-success .closeIcon {
  position: absolute;
  top: 5px;
  right: 15px;
}
#plaid-success .chahuaImg {
  height: 196px;
  object-fit: contain;
  margin: 50px 0;
}
#plaid-success p {
  font-family: 'Alibaba PuHuiTi';
  padding-left: 36px;
}
#plaid-success p:nth-of-type(1) {
  font-size: 28px;
  line-height: 32px;
  font-weight: normal;
  color: #000000;
  margin-bottom: 5px;
}
#plaid-success p:nth-of-type(2) {
  font-size: 28px;
  line-height: 38px;
  font-weight: bold;
  color: #000000;
}
