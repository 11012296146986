@keyframes cursor-blinks {
  0% {
    opacity: 1;
    display: block;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
.editTransactionPasswordB-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 12px 32px;
}
.editTransactionPasswordB-page .adm-nav-bar {
  padding: 0;
}
.editTransactionPasswordB-page .editTransactionPasswordB {
  background: #fff;
  height: 100vh;
  padding-top: 44px;
}
.editTransactionPasswordB-page .editTransactionPasswordB .title-warp-editTransactionPasswordB {
  margin: 10px auto;
  margin-bottom: 10px;
  text-align: left;
}
.editTransactionPasswordB-page .editTransactionPasswordB .title-warp-editTransactionPasswordB p {
  width: 310px;
  font-size: 26px;
  color: #000;
  margin-bottom: 6px;
}
.editTransactionPasswordB-page .editTransactionPasswordB .verification-code {
  margin-top: 20px;
  width: 100%;
}
.editTransactionPasswordB-page .editTransactionPasswordB .verification-code .adm-passcode-input-cell-container {
  display: flex;
  justify-content: flex-start;
}
.editTransactionPasswordB-page .editTransactionPasswordB .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #EFEFEF;
  border-radius: 4px;
  border: none;
}
.editTransactionPasswordB-page .editTransactionPasswordB .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell.focused {
  box-shadow: 0 0 2px 0 #1F49DA;
}
.editTransactionPasswordB-page .editTransactionPasswordB .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #FF3841;
  box-shadow: none;
}
.editTransactionPasswordB-page .editTransactionPasswordB .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell.dot::before {
  background-color: #FFF;
}
.editTransactionPasswordB-page .editTransactionPasswordB .varification-tips-b {
  margin-top: 20px;
  color: #999;
  text-align: left;
  width: 100%;
}
.editTransactionPasswordB .title-warp-editTransactionPasswordB .sub-title-PasswordB {
  width: 310px;
  font-size: 12px;
  margin: 15px auto;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.editTransactionPasswordB .verification-warp {
  width: 310px;
  margin: 26px auto 0;
}
.editTransactionPasswordB .verification-warp .lista {
  display: flex;
  justify-content: space-between;
  height: 48px;
}
.editTransactionPasswordB .verification-warp .lista .input-warp {
  width: 38px;
  height: 48px;
  position: relative;
  left: 0;
  top: 0;
}
.editTransactionPasswordB .verification-warp .lista input {
  width: 38px;
  height: 48px;
  border-radius: 5px;
  text-align: center;
  display: block;
  opacity: 0;
  margin: 0 auto;
  border: none;
}
.editTransactionPasswordB .verification-warp .lista .input-b {
  width: 38px;
  height: 48px;
  line-height: 48px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.editTransactionPasswordB .verification-warp .lista .input-a {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 30px;
  z-index: 0;
  width: 38px;
  height: 48px;
  line-height: 58px;
  border-radius: 5px;
  background: #EFEFEF;
  text-align: center;
  display: block;
}
.editTransactionPasswordB .verification-warp .lista .err-pass .input-a {
  background: #FF3841 !important;
  color: #fff;
}
.editTransactionPasswordB .verification-warp .lista .input-active::before {
  display: block;
  content: " ";
  background-color: #0a0a0a;
  letter-spacing: 0.88px;
  width: 2px;
  height: 26px;
  margin: 10px 5px;
  position: absolute;
  right: 0;
  z-index: 11;
  animation: cursor-blinks 1.5s infinite steps(1, start);
}
.editTransactionPasswordB .varification-tips {
  width: 310px;
  display: flex;
}
.editTransactionPasswordB .unvla {
  color: #FF3841;
}
.editTransactionPasswordB .dispaly-n {
  display: none;
}
.editTransactionPasswordB .erro-tips {
  width: 310px;
  margin-bottom: 12px;
  text-align: left;
  display: block;
  color: #FF3841;
}
.editTransactionPasswordB .is-reg {
  background: #FF8F94;
  color: #fff;
}
.editTransactionPasswordB .success-warp {
  font-size: 24px;
}
.editTransactionPasswordB .success-warp .icons {
  width: 72px;
  height: 72px;
  margin: 0 auto 17px;
}
.editTransactionPasswordB .success-warp .icons img {
  width: 72px;
  height: 72px;
}
