#associated-bank {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 28px 24px 100px;
  max-width: 375px;
}
#associated-bank .title {
  font-size: 20px;
  color: #333;
}
#associated-bank .tip {
  margin-top: 8px;
  margin-bottom: 12px;
  font-size: 12px;
  color: #999;
}
#associated-bank .waiting-list {
  width: 325px;
  background: rgba(250, 158, 15, 0.1);
  border-radius: 23px;
  color: #F9941E;
  display: flex;
  justify-content: space-between;
  padding: 9px 12px;
  margin-bottom: 20px;
}
#associated-bank .waiting-list .waiting-list-txt {
  display: inline-block;
  width: 240px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#associated-bank .choose-bank .bank-option .up {
  display: flex;
  align-items: center;
  width: 100%;
}
#associated-bank .choose-bank .bank-option .up .option-title {
  margin-left: 12px;
  display: flex;
  align-items: self-start;
  text-align: left;
}
#associated-bank .choose-bank .bank-option .up .option-title .txt {
  font-size: 16px;
  color: #333;
}
#associated-bank .choose-bank .bank-option .up .option-title .icon_tip {
  margin-left: 4px;
  color: #F9F9F9;
  font-size: 12px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  padding: 2px 6px;
  position: relative;
  z-index: 0;
}
#associated-bank .choose-bank .bank-option .up .option-title .icon_tip::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(338.24deg, #FD810E -20.34%, #F5B50E 110.89%);
  border-radius: 2px;
  transform: skewX(-10deg);
  z-index: -1;
}
#associated-bank .choose-bank .bank-option .down {
  margin-left: 28px;
  text-align: left;
}
#associated-bank .choose-bank .bank-option .down .down_tip {
  margin-top: 8px;
  margin-bottom: 16px;
}
#associated-bank .choose-bank .bank-option .down .imgs {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
#associated-bank .choose-bank .bank-option .down .imgs .acTillstar {
  width: 61.8px;
  height: 48px;
}
#associated-bank .choose-bank .bank-option .down .imgs .acBank {
  width: 60px;
  height: 41px;
}
#associated-bank .choose-bank .bank-option .down .imgs .acPlaid {
  width: 45px;
  height: 50px;
}
#associated-bank .choose-bank .bank-option .down .imgs .acPC {
  width: 67.28px;
  height: 54px;
}
#associated-bank .choose-bank .bank-option .down .imgs .icon_link {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
}
#associated-bank .bottom-btn {
  width: 100vw;
  max-width: 375px;
  background: #FFFFFF;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-top: 12px;
  padding-bottom: 28px;
}
#associated-bank .bottom-btn button {
  width: 327px;
  height: 40px;
  line-height: inherit;
  background-color: #FF3841;
  border-radius: 40px;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
}
