#bill-address-edit .item {
  display: flex;
  padding: 10px 15px;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  max-height: 38px;
}
#bill-address-edit .item .prefix {
  color: red;
  font-size: 15px;
}
#bill-address-edit .item .content {
  flex: 1;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #bbbbbb;
  padding-left: 10px;
}
#bill-address-edit .adm-list {
  position: relative;
  overflow: scroll;
  padding-bottom: 200px;
}
#bill-address-edit .adm-list-item {
  padding: 0px 10px;
}
#bill-address-edit .adm-list-item-content {
  padding: 5px 12px;
  border: none;
  border-bottom: none;
}
#bill-address-edit .title-label {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 13.9583px;
  padding-bottom: 10px;
  text-align: left;
  color: #333333;
}
#bill-address-edit .adm-input {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #bdbdbd;
  padding-left: 10px;
}
#bill-address-edit .adm-list-default {
  border-bottom: none;
}
#bill-address-edit .adm-list-inner {
  margin-top: 10px;
}
#bill-address-edit .adm-input {
  color: #666;
}
