#recharge-password {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 11;
  max-width: 375px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: calc(100% + 100px);
  top: -100px;
}
#recharge-password .overlayContent {
  width: 320px;
  padding: 36px 24px;
  background: white;
  border-radius: 6px;
  height: fit-content;
  position: relative;
}
#recharge-password .title-wrapper {
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
}
#recharge-password .title-wrapper p:nth-of-type(1) {
  font-size: 24px;
  color: #161719;
  margin-bottom: 8px;
}
#recharge-password .title-wrapper p:nth-of-type(2) {
  font-size: 14px;
  color: #999999;
}
#recharge-password .title-wrapper p:nth-of-type(3) {
  font-size: 14px;
  color: #999999;
}
#recharge-password .title-wrapper .sub-title {
  width: 310px;
  font-size: 14px;
  margin: 4px auto;
  color: #999;
}
#recharge-password .title-wrapper .money {
  font-size: 28px;
  color: #FF3841;
}
#recharge-password .title-wrapper .sxf {
  color: #999;
  font-size: 14px;
}
#recharge-password .passcode-input .adm-passcode-input-cell-container {
  display: flex;
  justify-content: center;
}
#recharge-password .passcode-input .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #EFEFEF;
  border-radius: 4px;
  border: none;
}
#recharge-password .passcode-input .adm-passcode-input-cell-container .adm-passcode-input-cell.focused {
  box-shadow: 0 0 2px 0 #1F49DA;
}
#recharge-password .passcode-input.adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #FF3841;
  box-shadow: none;
}
#recharge-password .passcode-input.adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell.dot::before {
  background-color: #FFF;
}
#recharge-password .varification-tips {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
#recharge-password .varification-tips .forget-password {
  width: 100%;
  color: #FF3841;
  margin-top: 12px;
}
#recharge-password .varification-tips .unvla {
  margin-top: 0;
}
#recharge-password .tips-w {
  color: #FF3841;
  font-size: 12px;
  margin-top: 80px;
}
#recharge-password .erro-warp .erro-icon {
  margin: 28px auto;
  text-align: center;
}
#recharge-password .erro-warp .erro-icon img {
  width: 60px;
  height: 60px;
}
#recharge-password .erro-warp .erro-bx {
  text-align: center;
}
#recharge-password .erro-warp .erro-bx h2 {
  font-size: 20px;
  text-align: center;
}
#recharge-password .erro-warp .erro-bx span {
  font-size: 14px;
  text-align: center;
  margin: 10px auto;
  color: #666;
  display: block;
}
#recharge-password .erro-warp .erro-bx .copy-btn {
  background: #FF3841;
  border-radius: 40px;
  width: 240px;
  height: 40px;
  color: #fff;
  text-align: center;
  margin: 10px auto;
}
#recharge-password .close-img {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0px;
  top: -35px;
}
#recharge-password .unvla {
  margin-top: 20px;
  position: absolute;
  left: 20px;
  color: red;
}
#recharge-password .adm-passcode-input {
  display: block;
}
#recharge-password .password-error-msg {
  color: red;
  margin-bottom: 8px;
}
#recharge-password .tyop {
  margin-top: 15px !important;
}
