.submit-application-container {
  position: relative;
  padding: 0 25px;
}
.submit-application-container .submit-application-close {
  position: absolute;
  right: 10px;
  top: 10px;
}
.submit-application-container .submit-info-container {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.submit-application-container .submit-info-container .submit-info-img {
  width: 70px;
  height: 70px;
}
.submit-application-container .submit-info-container .submit-info-title {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 600;
}
.submit-application-container .submit-info-container .submit-info-subtitle {
  margin-top: 5px;
  font-size: 12px;
  color: #999;
}
.submit-application-container .order-info-container {
  margin-top: 50px;
}
.submit-application-container .order-info-container .submit-info-subtitle {
  margin-top: 5px;
  font-size: 12px;
  color: #999;
}
.submit-application-container .order-info-container .order-info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.submit-application-container .order-info-container .order-info-item .order-info-title {
  font-size: 16px;
  color: #999;
}
.submit-application-container .order-info-container .order-info-item .order-info-number {
  font-size: 20px;
  font-weight: 400;
}
.submit-application-container .gotoHome {
  margin-top: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #999;
}
.submit-application-container .product-introduce {
  position: absolute;
  top: 1.5rem;
  width: 315px;
}
.submit-application-container .product-introduce .product-introduce-one {
  width: 315px;
  text-align: center;
  color: #e5e5e5;
}
.submit-application-container .product-introduce .product-introduce-two {
  width: 315px;
  text-align: center;
  color: #e5e5e5;
}
.submit-application-container .gotoHomeBtn {
  width: 315px;
  height: 40px;
  background: #FF3841;
  border-radius: 40px;
  color: #FFFFFF;
  font-size: 16px;
}
.bottomHome {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 374px !important;
  background: #FFFFFF;
  z-index: 100;
  filter: drop-shadow(0px -4px 4px rgba(133, 133, 133, 0.05));
}
.bottomHome .copyright {
  text-align: center;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 0.37333rem;
  line-height: 0.32rem;
  color: #CCCCCC;
  margin-bottom: 8px;
}
