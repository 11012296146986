.home-visa-newcard-wrapper {
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  height: 116px;
  width: 200px;
  transition: all 0.7s;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  margin: 0;
}
.home-visa-newcard-wrapper .imgBgCard {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  right: 0;
  z-index: 0;
}
.home-visa-newcard-wrapper .visa-content {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 20px 15px 10px 11px;
  z-index: 9;
}
.home-visa-newcard-wrapper .content-top {
  width: 100%;
  text-align: right;
}
.home-visa-newcard-wrapper .content-top .eyes {
  display: inline-block;
  cursor: pointer;
}
.home-visa-newcard-wrapper .textAlignLeft {
  text-align: left;
  margin-top: -8px;
}
.home-visa-newcard-wrapper .content-bottom {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.home-visa-newcard-wrapper .imgMagneticArea {
  width: 47px;
  height: 33px;
}
.home-visa-newcard-wrapper .logoTillstar {
  height: 14px;
  margin-bottom: 4px;
}
.home-visa-newcard-wrapper .textVisa {
  width: 68px;
  height: 20px;
}
.home-visa-newcard-wrapper .card-title {
  padding-top: 4px;
  font-weight: 600;
  color: #fff;
  font-size: 17px;
  line-height: 11px;
  padding-right: 13px;
}
.home-visa-newcard-wrapper .card-title .remaining-money {
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}
.home-visa-newcard-wrapper .card-title .eye-img {
  width: 18px;
  height: 12px;
  margin-left: 6px;
}
.home-visa-newcard-wrapper .card-title .card-money {
  font-size: 40px;
  line-height: 40px;
  margin-top: 10px;
}
.home-visa-newcard-wrapper .card-title .card-sub-title {
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  margin-top: 12px;
  font-weight: normal;
}
.home-visa-newcard-wrapper .card-btn {
  width: 86px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  overflow: hidden;
  border: 1px solid #fff;
  font-size: 12px;
  color: #fff;
  background: none;
}
.home-visa-newcard-wrapper .logo-container span {
  font-weight: 300;
  font-size: 11px;
  color: #999999;
}
.newCardImage {
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
}
.bank-num {
  width: 100%;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1em;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bank-num .num {
  height: 16px;
}
.bank-num .xin {
  font-size: 14px;
  letter-spacing: 2px;
}
.content-bottom {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #FAFAFA;
}
.content-bottom .btm-color {
  z-index: 100;
  color: #fafafa;
  line-height: 17px;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
}
.packDown {
  height: 217px;
  width: 332px;
  transition: all 0.7s;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.packDown .visa-content {
  top: 46%;
}
.packDown .content-bottom {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #FAFAFA;
}
.packDown .bank-num {
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1em;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.packDown .bank-num .num {
  height: 24px;
}
.packDown .bank-num .xin {
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 4px;
}
