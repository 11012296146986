.freezeAccount {
  background: #F4F4F4;
  height: 100vh;
  padding-top: 12px;
}
.freezeAccount .freeze-account-tip {
  margin-top: 72px;
}
.freezeAccount .freeze-account-tip img {
  width: 56px;
  height: 56px;
}
.freezeAccount p {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #000;
  margin: 12px 0 64px;
  padding-left: 24px;
  padding-right: 24px;
}
.freezeAccount .btn-container .warning-btn {
  margin-top: 0;
}
