#transaction-list-detail {
  background-color: #F9F9F9;
}
#transaction-list-detail .transaction-content {
  padding-bottom: 90px;
}
#transaction-list-detail .transaction-content .center-banner {
  padding: 10px 10px 0;
}
#transaction-list-detail .transaction-content .center-banner img {
  width: 100%;
}
#transaction-list-detail .adm-nav-bar {
  height: 64px;
  background-color: #fff;
}
#transaction-list-detail .adm-nav-bar-title {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #000000;
}
#transaction-list-detail .transfer-header-flow {
  text-align: center;
  background-color: #fff;
  padding-bottom: 24px;
}
#transaction-list-detail .transfer-header-flow img {
  width: 72px;
  margin: 20px 0 10px ;
}
#transaction-list-detail .transfer-header-flow .success-transfer {
  font-weight: 500;
  font-size: 18px;
  padding: 6px 0;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
}
#transaction-list-detail .transfer-header-flow .success-transfer img {
  width: 20px;
  margin: 0;
  border-radius: 50%;
  margin-right: 4px;
}
#transaction-list-detail .transfer-header-flow .fail-transfer {
  font-weight: 500;
  font-size: 18px;
  padding: 6px 0;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
}
#transaction-list-detail .transfer-header-flow .fail-transfer img {
  width: 20px;
  margin: 0;
}
#transaction-list-detail .transfer-header-flow .tip {
  display: inline-block;
  background: #F2F5FF;
  border-radius: 36px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #1F49DA;
  padding: 2px 10px;
}
#transaction-list-detail .transfer-header-flow .savings-tip {
  font-size: 14px;
  color: #333333;
  font-weight: 500;
}
#transaction-list-detail .transfer-header-flow .fee-tip {
  display: inline-block;
  background: #FFF6EB;
  border-radius: 41px;
  color: #FFA338;
  padding: 1px 12px;
}
#transaction-list-detail .transfer-header-flow .error-tip {
  font-size: 14px;
  line-height: 19px;
  color: #BBBBBB;
}
#transaction-list-detail .amount {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #161719;
  margin: 8px 0px;
}
#transaction-list-detail .amount.free-amount {
  text-decoration: line-through;
}
#transaction-list-detail .deposit-header-flow {
  background-color: #fff;
  padding-bottom: 24px;
}
#transaction-list-detail .deposit-header-flow img {
  width: 57px;
  height: 57px;
  margin: 20px 0 10px ;
  border-radius: 57px;
}
#transaction-list-detail .deposit-header-flow .name {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #222222;
}
#transaction-list-detail .deposit-header-flow .amount {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #161719;
  margin: 8px 0px;
}
#transaction-list-detail .deposit-header-flow .amount.free-amount {
  text-decoration: line-through;
}
#transaction-list-detail .deposit-header-flow .status {
  font-size: 12px;
  text-align: center;
  color: #1F49DA;
  background: #F2F5FF;
  border-radius: 21px;
  padding: 2px 8px;
}
#transaction-list-detail .deposit-header-flow .tip {
  font-weight: 500;
  color: #333;
  font-size: 14px;
  line-height: 18px;
}
#transaction-list-detail .deposit-header-flow .fee-tip {
  display: inline-block;
  background: #FFF6EB;
  border-radius: 41px;
  color: #FFA338;
  padding: 1px 12px;
}
#transaction-list-detail .deposit-header-flow .autoEnterIn {
  width: 90%;
  height: 32px;
  line-height: 32px;
  background: #F9F9F9;
  border-radius: 42px;
  margin-top: 18px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.165px;
  color: #161719;
}
#transaction-list-detail .deposit-header-flow .autoEnterIn .goView {
  display: inline-block;
  background: #FF3841;
  border-radius: 42px;
  padding: 0px 8px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.165px;
  color: #FFFFFF;
  margin-left: 10px;
}
#transaction-list-detail .transaction-info {
  margin-top: 8px;
  background-color: #fff;
  padding: 12px 24px;
}
#transaction-list-detail .transaction-info .instant-info {
  margin-top: 12px;
  padding: 0;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-header {
  padding: 0;
  border-bottom: none;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-header-title {
  font-size: 14px;
  color: #161719;
  font-weight: 400;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-body .deposit {
  border: 1px solid #F2F2F2;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  padding: 17px 12px;
  width: 160px;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-body .deposit .deposit-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-body .deposit .deposit-header .lighting {
  width: 12px;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-body .deposit .deposit-header .waiting {
  width: 16px;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-body .deposit .deposit-header .label {
  font-size: 14px;
  color: #333;
  margin-left: 8px;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-body .deposit .deposit-header .tip {
  margin-left: 8px;
  font-size: 12px;
  color: #1F49DA;
  background: #F5F8FF;
  border-radius: 4px;
  padding: 1px 4px;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-body .deposit .deposit-header .tip.error {
  background: #FFF3F4;
  color: #FF3841;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-body .deposit .deposit-header .tip.warning {
  background: #FFF2E2;
  color: #F9941E;
}
#transaction-list-detail .transaction-info .instant-info .adm-card-body .deposit .deposit-amount {
  margin-top: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #333;
}
#transaction-list-detail .transaction-info .item {
  display: flex;
  padding: 7px 0;
  justify-content: space-between;
}
#transaction-list-detail .transaction-info .item .key {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.165px;
  color: #999999;
  text-align: left;
}
#transaction-list-detail .transaction-info .item .value {
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  color: #000;
  line-height: 24px;
  flex: 1;
}
#transaction-list-detail .transaction-info .item .value .balance-icon {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
#transaction-list-detail .transaction-info .item .value .code-copy {
  font-size: 11px;
  line-height: 12px;
  border: 1px solid #F9941E;
  text-align: right;
  color: #F9941E;
  border-radius: 10px;
  padding: 1px 4px;
  margin-left: 4px;
}
#transaction-list-detail .transaction-info .item .value .fee-tips {
  margin-left: 8px;
  background: #FFF6EB;
  border-radius: 17px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  height: 17px;
  text-align: right;
  color: #FFA338;
  padding: 2px 4px;
}
#transaction-list-detail .transaction-info .item .value .fee {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  text-decoration-line: line-through;
  color: #000;
}
#transaction-list-detail .transaction-info .item .value .origin-fee {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  color: #000;
}
#transaction-list-detail .transaction-info .item .adm-steps-vertical {
  padding: 0;
  padding-left: 32px;
}
#transaction-list-detail .transaction-info .item .adm-steps-vertical .adm-step {
  margin-bottom: 4px;
}
#transaction-list-detail .transaction-info .item .adm-steps-vertical .adm-step .adm-step-indicator:after {
  transform: translateX(0);
  border-style: none none none solid;
  background-color: transparent;
  width: 0;
}
#transaction-list-detail .transaction-info .item .adm-steps-vertical .adm-step.adm-step-status-finish {
  color: #1F49DA;
}
#transaction-list-detail .transaction-info .item .adm-steps-vertical .adm-step.adm-step-status-wait {
  color: #999;
}
#transaction-list-detail .transaction-info .item .adm-steps-vertical .adm-step.adm-step-status-error {
  color: #FF3841;
}
#transaction-list-detail .transaction-info .item .adm-steps-vertical .adm-step-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#transaction-list-detail .transaction-info .item .adm-steps-vertical .adm-step-content .adm-step-title {
  --title-font-size: 12px;
}
#transaction-list-detail .transaction-info .item .adm-steps-vertical .adm-step-content .adm-step-description {
  font-size: 12px;
  color: #999999;
}
#transaction-list-detail .back-deposit {
  margin: 40px 24px 12px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  border: 1px solid #FF3841;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  background-color: #FF3841;
  color: #fff;
}
#transaction-list-detail .operater-order-action {
  height: 90px;
  width: 100%;
  background-color: #fff;
  filter: drop-shadow(0px -2px 7px rgba(0, 0, 0, 0.1));
  vertical-align: middle;
  padding: 0 20px;
  position: fixed;
  bottom: 0;
  z-index: 1;
  max-width: 375px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#transaction-list-detail .operater-order-action .back-home {
  width: 150px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  border: 1px solid #FF3841;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #FF3841;
}
#transaction-list-detail .operater-order-action .cs {
  font-size: 12px;
  color: #666;
  display: flex;
  align-items: center;
}
#transaction-list-detail .operater-order-action .cs img {
  width: 14px;
  margin-right: 4px;
}
