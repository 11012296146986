@keyframes blink {
  0% {
    background-color: white;
  }
  50% {
    background-color: #000000;
  }
  100% {
    background-color: white;
  }
}
@keyframes blink {
  0% {
    background-color: white;
  }
  50% {
    background-color: #000000;
  }
  100% {
    background-color: white;
  }
}
.steps-page {
  margin: 0 auto;
  min-height: 480px !important;
  height: 100vh;
}
.steps .input-container {
  margin: 24px;
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 17px;
  font-size: 16px;
  color: #333333;
}
.steps .imitate-placeholder {
  color: #bbbbbb;
}
.steps .imitate-input {
  margin: 32px 30px 48px 30px;
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 17px;
  font-size: 16px;
  color: #333333;
  font-size: 14px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  font-size: px2rem(16);
  border: px2rem(1) solid #999999;
  user-select: text;
  -webkit-user-select: text;
}
.steps .imitate-input .selected::after {
  content: '';
  display: block;
  width: 1px;
  height: 16px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
.steps .yes::after {
  content: '';
  display: block;
  width: 1px;
  height: 16px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
.steps .not:empty::before {
  content: '';
  display: block;
  width: 1px;
  height: 16px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
.steps .not:empty::after {
  content: attr(placeholder);
  color: #999999;
  font-size: px2rem(16);
}
.messages {
  width: 85%;
  margin: 0 auto;
  margin-top: 0.5rem;
}
.messages input {
  border: none;
  margin-left: 0.4rem;
  line-height: 2.2rem;
  font-size: 16px;
}
.messages .messages-top {
  display: flex;
  justify-content: space-between;
}
.messages .messages-top .messagesBtn {
  width: 150px;
  height: 44px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 38px;
}
.messages .messages-bootom {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.messages .messages-bootom .messages-year {
  width: 150px;
  height: 44px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 38px;
  position: relative;
}
.messages .messages-bootom .messages-year img {
  position: absolute;
  top: 45%;
  right: 0.4rem;
  width: 9.5px;
  height: 5.5px;
}
.messages .messages-bootom .messages-data {
  width: 64px;
  height: 44px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 38px;
}
.messages .messages-bootom .messages-month {
  width: 82px;
  height: 44px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 38px;
}
.messagesBtn {
  position: relative;
}
.messagesBtn span {
  position: absolute;
  top: 25%;
  margin-left: 0.3rem;
  width: 9px;
  height: 22px;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #FF3841;
  left: -2%;
}
.messagesBtn input {
  margin-left: 0.6rem;
}
.stmonths {
  width: 85%;
  height: 420px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.stmonths ul {
  margin-top: 0.1rem;
}
.stmonths ul li {
  height: 22px;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 0.9rem;
  color: #555;
  margin-left: 0.2rem;
}
.zhMonthStyle {
  width: 16%;
  text-align: right;
}
.user-info {
  padding: 24px;
}
.user-info .user-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-info .user-name .input-box {
  position: relative;
  border: none;
}
.user-info .user-name .input-box img {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.user-info .user-name .input-box .name {
  width: 150px;
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 38px;
  padding-left: 20px;
  font-size: 14px;
  position: relative;
  outline: none;
  box-shadow: none;
}
.user-info .user-name .input-box .name::placeholder {
  font-size: 14px;
  color: #cccccc;
}
.user-info .user-name .input-box .name.active {
  border: 1px solid #1F49DA;
}
.user-info .user-name .input-box .name.error {
  border: 1px solid #FFD7D7;
}
.user-info .notice {
  text-align: left;
  background-color: #F5F7FF;
  border-radius: 15px;
  margin-top: 8px;
}
.user-info .notice .notice-img {
  margin-left: 8px;
}
.user-info .notice .notice-text {
  margin-left: 4px;
  font-family: "Roboto";
  font-size: 14px;
  color: #1F49DA;
}
.user-info .notice .notice-text.is_en {
  font-size: 12px;
}
.user-info .notice.notice-error {
  background-color: #FFF8F8;
}
.user-info .notice.notice-error .notice-text {
  margin-left: 12px;
  color: #FF3841;
}
.user-info .birthday-container {
  margin-top: 16px;
  position: relative;
}
