#verify-code {
  margin: 0 auto;
  max-width: 400px;
}
#verify-code .setting-pasword-a {
  background: #fff;
  margin-left: 32px;
  margin-right: 32px;
}
#verify-code .setting-pasword-a .close {
  position: relative;
  left: -6px;
  margin-top: 16px;
  text-align: left;
}
#verify-code .setting-pasword-a .title-warp {
  width: 100%;
  padding: 36px 0 30px;
  top: auto;
  text-align: left;
}
#verify-code .setting-pasword-a .title-warp .title {
  width: 100%;
  font-size: 24px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 20px;
}
#verify-code .setting-pasword-a .title-warp .sub-title {
  margin-top: 20px;
  width: 320px;
  font-size: 12px;
  color: #FF3841;
  cursor: pointer;
}
#verify-code .setting-pasword-a .title-warp .white-title {
  font-size: 12px;
  color: #999;
}
#verify-code .setting-pasword-a .error-msg {
  width: 100%;
  font-size: 14px;
  color: #FF3841;
  text-align: left;
  margin-bottom: 8px;
  line-height: 20px;
}
#verify-code .setting-pasword-a .verification-code {
  width: 100%;
}
#verify-code .setting-pasword-a .verification-code .adm-passcode-input-cell-container {
  display: flex;
  justify-content: flex-start;
}
#verify-code .setting-pasword-a .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #EFEFEF;
  border-radius: 4px;
  border: none;
}
#verify-code .setting-pasword-a .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell.focused {
  box-shadow: 0 0 2px 0 #1F49DA;
}
#verify-code .setting-pasword-a .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #FF3841;
  box-shadow: none;
  color: #FFF;
}
.freezeAccountB .title-warp .white-title {
  font-size: 12px;
  color: #999999;
  cursor: pointer;
}
.setting-pasword-a .varification-tips {
  text-align: left;
  display: flex;
  color: #999;
  width: 100%;
  margin-top: 24px;
}
.setting-pasword-a .varification-tips .times span {
  font-size: 12px;
  color: #FF3841;
  cursor: pointer;
}
.setting-pasword-a .unvla {
  color: #FF3841;
}
.adm-number-keyboard-popup.adm-popup .adm-popup-body {
  background-color: unset;
}
.adm-number-keyboard {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  max-width: 373px;
  background-color: #f5f5f5;
}
