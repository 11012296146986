.tabbar-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  height: 65px;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.06);
  max-width: 375px !important;
  margin: 0 auto;
  border-left: 0.1px solid #eee;
  border-right: 0.1px solid #eee;
}
.tabbar-bottom .adm-tab-bar-item {
  color: #cccccc;
  font-size: 14px;
}
.tabbar-bottom .adm-tab-bar-item-active {
  color: #000000 !important;
}
.tabbar-bottom .adm-tab-bar-item-title {
  padding-top: 8px;
}
.tabbar-bottom img {
  width: 28px !important;
  height: 28px !important;
  object-fit: contain;
}
.tabbar-bottom .badge {
  width: 10px !important;
  height: 10px !important;
  position: absolute;
  margin-left: -2px;
}
.t-bottom {
  max-width: 496px !important;
  height: 1.7333rem;
}
