.message-Ppge {
  min-height: 100vh;
}
.message-Ppge .titleBar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.message-Ppge .titleBar .clearImg {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}
.message-Ppge .adm-list-item-content {
  padding-bottom: 0;
  padding-top: 5px;
}
.message-Ppge .message-list {
  min-height: calc(100vh - 45px);
  text-align: left;
}
.message-Ppge .message-list .message-icon img {
  width: 44px;
  height: 44px;
}
.message-Ppge .message-list .message-item {
  padding-left: 8px;
  padding: 10px;
  border-bottom: 1px solid #f9f9f9;
  padding-left: 5px;
}
.message-Ppge .message-list .message-item .message-item-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message-Ppge .message-list .message-item .message-item-line .message-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
}
.message-Ppge .message-list .message-item .message-item-line .message-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  text-align: right;
  color: #999999;
}
.message-Ppge .message-list .message-item .message-desc {
  min-height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
}
.popContent {
  max-width: 375px;
  min-height: 128px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.popContent .title {
  height: 55px;
  line-height: 55px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.165px;
  color: #828282;
  border-bottom: 1px solid #F2F2F2;
}
.popContent .clickRead {
  height: 60px;
  line-height: 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #ff3841;
}
.popContent .blockCenter {
  height: 12px;
  width: 100%;
  background: #F2F2F2;
}
.popContent .clickCancel {
  height: 55px;
  line-height: 55px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #828282;
}
