.samples {
  position: fixed;
  top: 20%;
  left: 10%;
  width: 8rem;
  background-color: #ffffff;
  text-align: center;
  padding: 0 12px;
}
.samples .license {
  margin-top: 16px;
}
.samples .license img {
  width: 246px;
  max-height: 302px;
}
.samples .license .nonbill {
  width: 186px;
}
