#statement-list {
  background-color: #fafafa;
}
#statement-list .adm-nav-bar {
  background-color: #fff;
  padding-bottom: 20px;
}
#statement-list .statement-list-content {
  margin-top: 8px;
  padding-bottom: 20px;
}
#statement-list .statement-list-content .alignCenter {
  text-align: center;
  font-size: 12px;
  color: #666666;
}
#statement-list .statement-list-content .list-item {
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin: 12px 10px 0;
  padding: 12px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#statement-list .statement-list-content .list-item .list-left {
  font-size: 14px;
  color: #161719;
  text-align: left;
}
#statement-list .statement-list-content .list-item .list-left .date {
  line-height: 19px;
}
#statement-list .statement-list-content .list-item .list-left .title {
  color: #999999;
  font-size: 12px;
  line-height: 16px;
}
#statement-list .statement-list-content .no-message {
  width: 100%;
  padding-top: 10vh;
  font-family: 'PingFang HK';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.165px;
  color: #999999;
  text-align: center;
}
#statement-list .statement-list-content .no-message img {
  width: 45%;
  margin-bottom: 20px;
}
#statement-list .selectYear {
  margin: 12px 25px 0;
}
#statement-list .selectYear .adm-dropdown {
  background-color: transparent;
  text-align: left;
}
#statement-list .selectYear .adm-dropdown-item {
  justify-content: left;
}
#statement-list .selectYear .adm-dropdown-item .adm-dropdown-item-title {
  padding: 0;
  font-size: 14px;
}
#statement-list .adm-picker {
  max-width: 375px;
  width: 100%;
  background-color: #fff;
}
#email-pop-container {
  width: 300px;
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  padding-top: 48px;
  padding-bottom: 38px;
}
#email-pop-container .close {
  position: absolute;
  right: 0;
  top: -53px;
}
#email-pop-container .title {
  font-weight: 500;
  font-size: 22px;
  color: #222;
}
#email-pop-container .email-section {
  margin: 36px 20px 12px;
  position: relative;
  padding-bottom: 36px;
}
#email-pop-container .email-section .email {
  width: 260px;
  padding: 10px 12px;
  background-color: #f2f2f2;
  font-size: 16px;
  --adm-color-text: #333;
}
#email-pop-container .email-section .email.error {
  --adm-color-text: #ff3841;
}
#email-pop-container .email-section .email-error {
  position: absolute;
  text-align: left;
  bottom: 12px;
  left: 12px;
  color: #ff3841;
}
#email-pop-container .send-email {
  width: 260px;
  background-color: #ff3841;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
#statement-pop-container {
  padding-top: 48px;
  padding-bottom: 38px;
  position: relative;
  background: rgba(0, 0, 0, 0.6);
}
#statement-pop-container .close {
  position: absolute;
  right: 20px;
  top: 20px;
}
#statement-pop-container .pdfView {
  width: 100%;
  padding: 30px 12px;
  min-height: 100vh;
  max-width: 375px;
}
#statement-pop-container .send-email {
  position: fixed;
  bottom: 40px;
  left: calc(50% - 130px);
  width: 260px;
  background-color: #ff3841;
  border: 0;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
