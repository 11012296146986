.resetPasswordC-page {
  margin: 0 auto;
  max-width: 400px;
  padding: 12px 32px;
  min-height: 700px !important;
}
.resetPasswordC-page .adm-nav-bar {
  padding: 0;
}
.resetPasswordC-page .resetPasswordC {
  background: #fff;
  height: 100vh;
  padding-top: 40px;
}
.resetPasswordC-page .resetPasswordC .title-warp {
  position: static;
  top: auto;
  width: 100%;
}
.resetPasswordC-page .resetPasswordC .title-warp p {
  width: 100%;
  font-size: 26px;
  text-align: left;
  color: #000000;
}
.resetPasswordC-page .resetPasswordC .title-warp .sub-title {
  margin-top: 6px;
  font-size: 12px;
  text-align: left;
}
.resetPasswordC-page .resetPasswordC .name-warp .name-section {
  display: flex;
  justify-content: space-between;
}
.resetPasswordC-page .resetPasswordC .name-warp .input-box {
  height: 44px;
  line-height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 38px;
  padding-left: 24px;
  padding-right: 12px;
  position: relative;
}
.resetPasswordC-page .resetPasswordC .name-warp .input-box img {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.resetPasswordC-page .resetPasswordC .name-warp .input-box .input {
  height: 100%;
}
.resetPasswordC-page .resetPasswordC .name-warp .input-box.right-input {
  margin-left: 16px;
}
.resetPasswordC-page .resetPasswordC .name-warp .ssn-section {
  margin-top: 16px;
  position: relative;
  display: flex;
  align-items: center;
}
.resetPasswordC-page .resetPasswordC .btn-container {
  padding-top: 20px;
}
.resetPasswordC-page .resetPasswordC .btn-container p {
  color: #FF3841;
}
.resetPasswordC-page .resetPasswordC .btn-container button {
  margin-top: 12px !important;
}
.resetPasswordC .verification-warp {
  width: 310px;
  margin: 0 auto;
}
.resetPasswordC .verification-warp .lista {
  display: flex;
  justify-content: space-between;
}
.resetPasswordC .verification-warp .lista input {
  width: 38px;
  height: 48px;
  border-radius: 5px;
  background: #EFEFEF;
  margin: 46px 5px 20px;
  text-align: center;
  display: block;
}
.resetPasswordC .varification-tips {
  width: 310px;
  display: flex;
}
.resetPasswordC .unvla {
  color: #FF3841;
}
.resetPasswordC .name-warp {
  width: 320px;
  margin: 20px auto;
}
.resetPasswordC .name-warp input {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
}
.resetPasswordC .name-warp input::-webkit-input-placeholder {
  color: #aab2bd;
  font-size: 16px;
  text-align: left;
}
.resetPasswordC .is-reg {
  background: #FF8F94;
}
