.active-start {
  background: #fff;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}
.active-start .active-start-top {
  padding-left: 36px;
  padding-top: 24px;
  position: relative;
  overflow: hidden;
}
.active-start p:nth-of-type(1) {
  font-weight: 700;
  font-size: 24px;
  color: #FF3841;
}
.active-start p:nth-of-type(2) {
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}
.active-start .active-bg {
  width: 375px;
  height: 329px;
  margin-top: 30px;
}
.active-start .active-start-close {
  position: absolute;
  right: 20px;
  top: 25px;
}
.active-start .active-start-close svg {
  width: 22px;
  height: 22px;
}
.active-start .active-start-btn {
  margin-top: 30px;
  width: 100%;
  background: #FFFFFF;
  padding-top: 10px;
  z-index: 100;
}
.active-start .active-start-btn .next-button1 {
  width: 315px;
  height: 40px;
  background: #FF3841;
  border-radius: 40px;
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 12px;
}
.active-start .active-start-btn .next-button2 {
  background: #FF8F94;
}
.bind {
  font-size: 16px;
  color: #999;
  text-align: center;
}
