.relateStart {
  background: #fff;
  height: 100vh;
}
.relateStart .wrap {
  padding: 0 32px;
  margin-top: 12px;
}
.relateStart .icon_bank {
  width: 51px;
  height: 51px;
}
.relateStart .icon_bank img {
  width: 100%;
}
.relateStart .tip {
  width: fit-content;
  padding: 3px 12px 3px 7px;
  border-radius: 23px;
  background-color: #F2F5FF;
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  color: #1F49DA;
  text-align: center;
}
.relateStart .tip img {
  margin-right: 4px;
}
.relateStart .title {
  font-family: Alibaba PuHuiTi;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  margin: 20px 0 17px 0;
  text-align: left;
}
.relateStart .text_introduction {
  font-family: Alibaba PuHuiTi;
  font-size: 16px;
  line-height: 160%;
  text-align: justify;
  color: #999999;
  margin-bottom: 20px;
}
.relateStart .info_item {
  height: 24px;
  line-height: 24px;
  font-family: Alibaba PuHuiTi;
  font-size: 16px;
  color: #999999;
  margin-bottom: 20px;
  text-align: left;
}
.relateStart .info_item img {
  margin-right: 6px;
  vertical-align: middle;
}
.relateStart .relate-start-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #FFFFFF;
  padding-top: 10px;
  z-index: 100;
}
.relateStart .relate-start-btn .link_text {
  font-family: Alibaba PuHuiTi;
  font-size: 16px;
  line-height: 160%;
  color: #CCCCCC;
  text-align: center;
  margin-bottom: 16px;
}
.relateStart .relate-start-btn .next-button1 {
  width: 315px;
  height: 40px;
  line-height: 40px;
  background: #FF3841;
  border-radius: 40px;
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 16px;
}
.relateStart .relate-start-btn .next-button2 {
  background: #FF8F94;
}
.relateStart .relate-start-btn .product-introduce {
  font-family: Alibaba PuHuiTi;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #828282;
  margin-bottom: 43px;
}
.relateStart .relate-start-btn .product-introduce label {
  color: #FF3841;
}
.relateStart .follow-up {
  font-size: 16px;
  padding: 0 0 16px;
  color: #ccc;
}
.relateStart .tutorial-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #FF3841;
  margin-bottom: 10px;
}
.relate-start-btn .product-introduce span {
  color: #1f49da;
}
