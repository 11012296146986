.stepSix {
  padding-bottom: 140px;
}
.stepSix .subtitle {
  margin-top: 0;
}
.stepSix .upload-image {
  position: relative;
  margin-top: 16px;
}
.stepSix .upload-image .holder-image {
  width: 100%;
}
.stepSix .upload-image .tip-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  position: absolute;
  width: 242px;
  left: 42px;
  bottom: 13px;
}
.stepSix .upload-image .tip-text p:nth-of-type(1) {
  color: #999;
}
.stepSix .upload-image .tip-text p:nth-of-type(2) {
  color: #1F49DA;
}
.stepSix .upload-image .tps {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  border: 1px solid transparent;
  background: linear-gradient(white, white) padding-box, repeating-linear-gradient(-45deg, #ccc 0, #ccc 25%, transparent 0, transparent 50%) 0 / 0.7em 0.7em;
  padding: 4px;
}
.stepSix .upload-image .bottom-btn {
  position: absolute;
  bottom: 4px;
  left: 0;
  margin-left: 5px;
  margin-right: 4px;
  width: calc(100% - 10px);
  height: 32px;
  background: rgba(0, 0, 0, 0.6);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.stepSix .upload-image .bottom-btn input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 100;
}
.stepSix .upload-image .bottom-btn .action {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepSix .upload-image .bottom-btn .action img {
  margin-right: 4px;
  width: 15px;
}
.step-bottom-six {
  position: fixed;
  width: 100%;
  max-width: 373.5px !important;
  bottom: 0;
  text-align: center;
  margin: 1rem auto 0px;
  border-top: 1px solid #ececec;
  background-color: #ffffff;
}
.step-bottom-six p {
  margin-bottom: 0.3rem;
}
.step-bottom-six button {
  margin: 0 auto;
}
