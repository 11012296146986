.recordList {
  background: #F4F4F4;
  max-width: 100%;
  margin: 0 auto;
}
.recordList .search-container {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 32px;
}
.recordList .search-container .input-container {
  width: 287px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 40px;
  position: relative;
}
.recordList .search-container .input-container .input {
  width: 287px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 40px;
  padding-left: 48px;
  padding-right: 10px;
  font-size: 14px;
  color: #999999;
}
.recordList .search-container .input-container img {
  position: absolute;
  left: 12px;
  top: 8px;
  width: 22px;
  height: 22px;
}
.recordList .search-container span {
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}
.listTop {
  margin: 20px auto;
  width: 86%;
}
#transaction-search-list {
  background-color: #fafafa;
}
#transaction-search-list .no-search-data {
  width: 144px;
  margin-top: 100px;
}
#transaction-search-list .no-search-data-text {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #A5A9AD;
}
#transaction-search-list .adm-search-bar {
  padding: 24px;
}
#transaction-search-list .adm-search-bar .adm-search-bar-input-box {
  background: #F4F4F4;
  border: 1px solid #D9DFF7;
  border-radius: 40px;
  height: 36px;
  line-height: 36px;
}
#transaction-search-list .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input-box-icon {
  color: #999;
  font-size: 14px;
}
#transaction-search-list a {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
#transaction-search-list .adm-list-item {
  padding-left: 0;
}
#transaction-search-list .adm-list-item-content {
  padding: 0;
}
