.common-upload-image {
  margin: 20px 24px;
  position: relative;
  text-align: left;
}
.common-upload-image .item-title {
  font-size: 18px;
  color: #000000;
}
.common-upload-image .item-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
}
.common-upload-image .kyc-active-address {
  background: #F6F6F6;
  border-radius: 4px;
  text-align: left;
  padding: 8px 12px;
}
.common-upload-image .kyc-active-address p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}
.common-upload-image .imageUpload {
  margin-top: 20px;
}
.common-upload-image .imageUpload .uploadBtn {
  width: 4.2rem;
  height: 4.2rem;
  background: #fff;
  border: 1px dashed #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.common-upload-image .imageUpload .adm-image-uploader-cell-delete {
  width: 18px;
  height: 18px;
  font-size: 12px;
}
.common-upload-image .upload-item {
  position: relative;
  margin-top: 16px;
}
.common-upload-image .upload-item .bg-image {
  width: 100%;
}
.common-upload-image .upload-item .bg-opacity {
  opacity: 0;
}
.common-upload-image .upload-item .uploadInput {
  position: absolute;
  top: 22%;
  left: 37%;
  width: 26%;
  height: 50%;
  opacity: 0;
}
.common-upload-image .upload-item .footer-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  position: absolute;
  width: 242px;
  left: 42px;
  bottom: 13px;
}
.common-upload-image .upload-item .footer-text p:nth-of-type(1) {
  color: #999;
}
.common-upload-image .upload-item .footer-text p:nth-of-type(2) {
  color: #1F49DA;
}
.common-upload-image .upload-item .tps-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  border: 1.5px solid transparent;
  background: linear-gradient(white, white) padding-box, repeating-linear-gradient(-45deg, #ccc 0, #ccc 0.5em, white 0, white 0.75em);
  padding: 2px;
}
.common-upload-image .upload-item .reUpload-btn {
  position: absolute;
  bottom: 4px;
  left: 0;
  margin-left: 5px;
  margin-right: 4px;
  width: calc(100% - 10px);
  height: 32px;
  background: rgba(0, 0, 0, 0.6);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.common-upload-image .upload-item .reUpload-btn input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 100;
}
.common-upload-image .upload-item .reUpload-btn .action {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.common-upload-image .upload-item .reUpload-btn .action img {
  margin-right: 4px;
  width: 15px;
}
.adm-image-viewer-image-wrapper {
  max-width: 100vw;
}
.adm-image-viewer-image-wrapper img {
  max-width: 375px;
}
