#kyc-begin {
  background-color: #fff;
}
#kyc-begin .title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: block;
  text-align: center;
  color: #ff3841;
}
#kyc-begin .ssn-select {
  text-align: center;
  width: auto;
  margin-top: 100px;
}
#kyc-begin .ssn-select div {
  width: auto;
  height: 44px;
  line-height: 44px;
  padding: 0px;
  border: 1px solid #000000;
  border-radius: 37px;
  margin: 30px;
  font-size: 18px;
  color: #333333;
}
