#bank-selected {
  background: #F5F5F5;
}
#bank-selected .adm-nav-bar {
  background: #FFFFFF;
  padding-bottom: 16px;
}
#bank-selected .bank-selected-container .title {
  margin: 22px 24px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #222;
}
#bank-selected .bank-selected-container .bank-lists {
  background: #FFFFFF;
  padding: 0 24px;
}
#bank-selected .bank-selected-container .bank-lists .bank-list {
  border-bottom: 1px solid #F4F4F4;
  display: flex;
  align-items: center;
  padding: 16px 0 24px;
  width: 100%;
}
#bank-selected .bank-selected-container .bank-lists .bank-list img {
  width: 40px;
  height: 40px;
}
#bank-selected .bank-selected-container .bank-lists .bank-list .bank-info {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
#bank-selected .bank-selected-container .bank-lists .bank-list .bank-info .bank-account {
  padding-top: 4px;
  text-align: left;
  font-size: 18px;
  color: #222;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
#bank-selected .bank-selected-container .bank-lists .bank-list .bank-info .bank-account .tip {
  display: inline-block;
  padding: 2px 4px;
  font-size: 10px;
  border-radius: 18px;
  margin-left: 8px;
}
#bank-selected .bank-selected-container .bank-lists .bank-list .bank-info .bank-account .tip.error-tip {
  color: #FF3841;
  background-color: #FFE3E5;
}
#bank-selected .bank-selected-container .bank-lists .bank-list .bank-info .bank-account .tip.warning-tip {
  color: #F9941E;
  background-color: #FEF4E8;
}
#bank-selected .bank-selected-container .bank-lists .bank-list .bank-info .balance-info {
  margin-top: 4px;
  font-size: 12px;
  color: #999;
  text-align: left;
}
#bank-selected .bank-selected-container .bank-lists .bank-list .selected {
  margin-left: auto;
}
#bank-selected .bank-selected-container .bank-lists .add-bank .add-icon {
  background-color: #FFF9F9;
  border: 1px solid #FFEAEB;
  border-radius: 50%;
  padding: 5px;
}
#bank-selected .bank-selected-container .bank-lists .add-bank .add-tip {
  margin-left: 12px;
  font-size: 14px;
  color: #161719;
}
