#statement-padding {
  background-color: #fafafa;
}
#statement-padding .adm-nav-bar {
  background-color: #fff;
  padding-bottom: 20px;
}
#statement-padding .paddingTitle {
  width: 100%;
  display: flex;
  padding: 10px 20px 20px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #161719;
  justify-content: space-between;
}
#statement-padding .paddingTitle .amount {
  font-size: 16px;
}
#statement-padding .statement-padding-content {
  margin-top: 8px;
  padding-bottom: 20px;
}
#statement-padding .statement-padding-content .alignCenter {
  text-align: center;
  padding-bottom: 20px;
  font-size: 12px;
  color: #666666;
}
#statement-padding .statement-padding-content .list-item {
  background-color: #fff;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
  width: 100%;
}
#statement-padding .statement-padding-content .list-item .list-left {
  font-size: 14px;
  color: #161719;
  text-align: left;
  width: 70%;
}
#statement-padding .statement-padding-content .list-item .list-left .date {
  line-height: 19px;
}
#statement-padding .statement-padding-content .list-item .list-left .title {
  color: #999999;
  font-size: 12px;
  line-height: 18px;
}
#statement-padding .statement-padding-content .list-item .list-right {
  width: 30%;
}
#statement-padding .statement-padding-content .list-item .list-right .amount {
  text-align: right;
  letter-spacing: -0.165px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
#statement-padding .statement-padding-content .list-item .list-right .pending {
  text-align: right;
  letter-spacing: -0.165px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  min-height: 14px;
}
#statement-padding .statement-padding-content .no-message {
  width: 100%;
  padding-top: 10vh;
  font-family: 'PingFang HK';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.165px;
  color: #999999;
  text-align: center;
}
#statement-padding .statement-padding-content .no-message img {
  width: 45%;
  margin-bottom: 20px;
}
