#transfer-account .transferAccounts {
  position: relative;
  background: #f8f8f8;
  height: 100vh;
}
#transfer-account .transferAccounts .transfer-navbar {
  width: 100%;
  background: #f8f8f8;
  padding-left: 24px;
  padding-right: 24px;
  height: 50px;
}
#transfer-account .transferAccounts .transfer-navbar .transferTitle {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}
#transfer-account .transferAccounts .transfer-navbar .tag-count {
  display: inline-block;
  width: auto;
  height: 20px;
  line-height: 20px;
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
  padding: 0px 4px;
  background: #FF3841;
  border-radius: 10px;
  margin-left: 4px;
}
#transfer-account .transferAccounts .transfer-navbar .transferDesc {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #999999;
}
#transfer-account .transferAccounts .transfer-inputs {
  width: 100%;
  padding: 24px;
}
#transfer-account .transferAccounts .transfer-inputs .prompts {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
  text-align: left;
  padding-left: 12px;
}
#transfer-account .transferAccounts .transfer-inputs .red {
  color: #ff3841;
}
#transfer-account .transferAccounts .transfer-inputs .input-item {
  width: 100%;
  background: #fff;
  min-height: 40px;
  margin-bottom: 14px;
  text-align: left;
  display: flex;
  align-items: baseline;
  border-radius: 20px;
  line-height: 32px;
}
#transfer-account .transferAccounts .transfer-inputs .input-item .tags-input {
  display: inline-block;
  text-align: left;
  width: calc(100% - 55px);
  max-height: 75px;
  overflow: auto;
}
#transfer-account .transferAccounts .transfer-inputs .input-item .tags-input .item-tags {
  display: inline;
}
#transfer-account .transferAccounts .transfer-inputs .input-item .tags-input .item-tags .tags {
  font-size: 15px;
  padding: 2px 5px 4px;
}
#transfer-account .transferAccounts .transfer-inputs .input-item .tags-input .input-area {
  display: inline-block;
  margin-left: 3px;
  max-width: calc(100% - 10px);
}
#transfer-account .transferAccounts .transfer-inputs .input-item .tags-input .input-area input {
  caret-color: inherit;
}
#transfer-account .transferAccounts .transfer-inputs .input-item .tags-input .width-all {
  width: 100%;
}
#transfer-account .transferAccounts .transfer-inputs .input-item .item-label {
  font-weight: 400;
  font-size: 15px;
  line-height: 40px;
  color: #999999;
  display: inline-block;
  width: 50px;
  padding-left: 10px;
}
#transfer-account .transferAccounts .transfer-inputs .input-item .text-area {
  width: 90%;
}
#transfer-account .transferAccounts .submit-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 375px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  padding-top: 10px;
}
#transfer-account .transferAccounts .submit-area .submit-btn {
  width: 90%;
  background: #ff3841;
  border-radius: 40px;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}
#transfer-account .transferAccounts .submit-area .dis-btn {
  background-color: #ff8f94;
}
#transfer-account .transferAccounts .search-warp {
  width: 100%;
  min-height: calc(100vh - 175px - 60px - 52px);
  max-height: calc(100vh - 175px - 60px - 52px);
  overflow: auto;
  background-color: #ffffff;
}
#transfer-account .transferAccounts .search-warp .alignCenter {
  text-align: center;
  padding-bottom: 12px;
  font-size: 12px;
  color: #666666;
}
#transfer-account .transferAccounts .search-warp .search-section {
  background: #fff;
  padding-top: 44px;
  padding-bottom: 20px;
}
#transfer-account .transferAccounts .search-warp .search-section .search-input-label {
  padding-left: 12px;
  white-space: nowrap;
  color: #999;
}
#transfer-account .transferAccounts .search-warp .contact-list {
  margin-top: 0;
  padding: 12px 24px;
  padding-bottom: 70px;
}
#transfer-account .transferAccounts .search-warp .contact-list .list-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #000;
}
#transfer-account .transferAccounts .search-warp .contact-list .user-list {
  list-style: none;
}
#transfer-account .transferAccounts .search-warp .contact-list .user-list .adm-checkbox .adm-checkbox-icon {
  border-radius: 4px;
}
#transfer-account .transferAccounts .search-warp .contact-list .user-list .user-list-item {
  margin-top: 12px;
  position: relative;
}
#transfer-account .transferAccounts .search-warp .contact-list .user-list .item-list {
  display: flex;
  align-items: center;
}
#transfer-account .transferAccounts .search-warp .contact-list .user-list .item-list .item-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
#transfer-account .transferAccounts .search-warp .contact-list .user-list .item-list:last-child {
  margin-top: 5px;
}
#transfer-account .transferAccounts .search-warp .contact-list .user-list .item-list .user-info {
  margin-left: 16px;
  padding: 0;
  display: flex;
  flex-direction: column;
}
#transfer-account .transferAccounts .search-warp .contact-list .user-list .item-list .user-info .name {
  color: #333;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
#transfer-account .transferAccounts .search-warp .contact-list .user-list .item-list .user-info .mobile {
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
}
#transfer-account .transferAccounts .search-warp .no-contact-list {
  text-align: center;
  font-size: 18px;
  color: #999;
}
#transfer-account .transferAccounts .search-warp .UserBlock {
  padding: 30px 24px;
}
#transfer-account .transferAccounts .search-warp .UserBlock .listUser {
  width: 100%;
  position: relative;
  font-size: 18px;
  text-align: left;
}
#transfer-account .transferAccounts .search-warp .UserBlock .listUser img {
  width: 48px;
  height: 48px;
}
#transfer-account .transferAccounts .search-warp .UserBlock .listUser span {
  padding-left: 20px;
}
#transfer-account .transferAccounts .search-warp .UserBlock .listUser .l-top {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #333;
  position: absolute;
  top: 0;
}
#transfer-account .transferAccounts .search-warp .UserBlock .listUser .l-bootom {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #999;
}
.transferAccounts .search-warp .search-input-warp {
  height: 44px;
  border-radius: 44px;
  background: #fff;
  border: 1px solid #fff;
  line-height: 44px;
  margin: 0 24px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.transferAccounts .search-warp .search-input-warp p {
  color: #999;
  flex-shrink: 0;
}
.transferAccounts .search-warp .search-input-warp input {
  padding-left: 10px;
}
.transferAccounts .search-warp .search-input-warp .search-img {
  flex-shrink: 0;
}
.transferAccounts .search-warp .search-title {
  margin-top: 12px;
  padding-left: 12px;
  width: 315px;
  color: #999;
  text-align: left;
}
.transferAccounts .search-warp .search-title span,
.transferAccounts .search-warp .search-title .exceed-daily-limit {
  color: #ff3841;
}
.transferAccounts .search-warp .search-img {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}
.transferAccounts .user-warp {
  width: 300px;
  margin: 24px 48px;
}
.transferAccounts .user-warp .title {
  width: 300px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  display: flex;
}
.transferAccounts .user-acount-list {
  margin-top: 24px;
}
.transferAccounts .user-acount-list .u-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 28px;
}
.transferAccounts .user-acount-list .u-list .adm-image {
  width: 48px;
  margin-right: 20px;
}
.transferAccounts .user-acount-list .u-list .num-t {
  color: #999;
  margin-top: 4px;
}
.transferAccounts .user-acount-list .u-list .red {
  color: #ff3841 !important;
}
.transferAccounts .unfind-user {
  width: 144px;
  padding-top: 40px;
  text-align: center;
}
.transferAccounts .unfind-user p {
  font-size: 16px;
}
.transferAccounts .no-user {
  text-align: center;
  font-size: 14px;
  color: #999;
  padding-top: 40px;
}
.period {
  color: #999999;
}
.period span {
  color: #ff3841;
}
.modal-bao .adm-modal-main .adm-modal-body {
  padding: 0;
}
.search-transfer {
  text-align: center;
}
.search-transfer p {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
  color: #000000;
}
.search-transfer button {
  margin-top: 0.5rem;
  width: 55%;
  letter-spacing: 1px;
}
.contact-list {
  text-align: left;
  margin-top: 0.5rem;
  background-color: #fff;
}
