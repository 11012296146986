.RelateExceptionPop {
  position: relative;
  width: 295px;
  padding: 72px 0 24px;
  margin: auto;
  background: #fff;
  border-radius: 12px;
  text-align: center;
}
.RelateExceptionPop .close {
  position: absolute;
  right: 0;
  top: -64px;
}
.RelateExceptionPop .noticeIcon {
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translate3d(-50%, 0, 0);
  width: 85.5px;
  height: 97px;
}
.RelateExceptionPop .row1 {
  padding-bottom: 17px;
}
.RelateExceptionPop .bank {
  width: 40px;
  height: 40px;
}
.RelateExceptionPop .bankText {
  padding-left: 14px;
  color: #161719;
  font-size: 14px;
}
.RelateExceptionPop .p1 {
  font-size: 18px;
  line-height: 24px;
  color: #333;
}
.RelateExceptionPop .p2 {
  padding-top: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #666;
}
.RelateExceptionPop .row2 {
  display: flex;
  justify-content: space-between;
  padding: 24px 20px 0;
}
.RelateExceptionPop .buttonClear {
  padding: 0 !important;
  border: none !important;
}
.RelateExceptionPop .button {
  box-sizing: border-box;
  width: 120px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px !important;
  font-size: 16px;
}
.RelateExceptionPop .whiteBtn {
  color: #999;
  border: 1px solid #999;
}
.RelateExceptionPop .redBtn {
  background: #FF3841 !important;
  color: #fff;
}
