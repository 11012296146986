#authentication-container {
  background-color: #FAFAFA;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}
#authentication-container .adm-nav-bar {
  background-color: #FFF;
  padding-bottom: 20px;
}
#authentication-container .authentication-content {
  margin: 20px 10px;
  background-color: #FFF;
  padding: 24px 16px 20px 14px;
  height: 194px;
}
#authentication-container .authentication-content .user-info {
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: 122px;
  border-bottom: 0.5px solid #E7E7E7;
}
#authentication-container .authentication-content .user-info .user-info-detail {
  display: flex;
  flex-direction: column;
}
#authentication-container .authentication-content .user-info .user-info-detail .user-name .name {
  font-size: 24px;
  color: #000;
}
#authentication-container .authentication-content .user-info .user-info-detail .user-name .status {
  padding: 2px 8px;
  background-color: #FFF7F7;
  border-radius: 59px;
  display: inline-flex;
  align-items: center;
  margin-left: 6px;
}
#authentication-container .authentication-content .user-info .user-info-detail .user-name .status .personal_verify {
  width: 14px;
  height: 14px;
}
#authentication-container .authentication-content .user-info .user-info-detail .user-name .status .status-text {
  margin-left: 4px;
  font-size: 14px;
  color: #FF3841;
}
#authentication-container .authentication-content .user-info .user-info-detail .mobile-number {
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #999;
  text-align: left;
}
#authentication-container .authentication-content .user-info .user-info-detail .mobile-number.strong {
  font-size: 24px;
  color: #000;
}
#authentication-container .authentication-content .user-info .user-info-detail .status-tip {
  margin-top: 20px;
  font-size: 12px;
  color: #999;
  text-align: left;
}
#authentication-container .authentication-content .user-info .avatar {
  width: 64px;
  height: 64px;
}
#authentication-container .authentication-content .user-info .portrait {
  width: 64px;
  height: 64px;
  line-height: 64px;
  background: #FF3841;
  color: #FFF;
  font-weight: 700;
  font-size: 28.8px;
  color: #FFFFFF;
  border-radius: 50%;
}
#authentication-container .authentication-content .user-info-bottom {
  margin-top: 14px;
  text-align: left;
}
#authentication-container .authentication-content .user-info-bottom .to-activation,
#authentication-container .authentication-content .user-info-bottom .failed {
  display: inline-block;
  background: #FFF7F7;
  border-radius: 50px;
  padding: 4px 10px;
}
#authentication-container .authentication-content .user-info-bottom .to-activation span,
#authentication-container .authentication-content .user-info-bottom .failed span {
  font-size: 14px;
  color: #FF3841;
}
#authentication-container .authentication-content .user-info-bottom .under-view {
  display: inline-block;
  background: #F9F9F9;
  border-radius: 50px;
  padding: 4px 10px;
}
#authentication-container .authentication-content .user-info-bottom .under-view span {
  font-size: 14px;
  color: #999;
}
#authentication-container .authentication-content .user-info-bottom span {
  margin-left: 6px;
  font-size: 12px;
  color: #1F49DA;
}
