#transaction-status .purchase-waiting {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.151724px;
  color: #333333;
}
#transaction-status .purchase-waiting span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 2px 4px;
  text-align: center;
  letter-spacing: -0.151724px;
  background: #FFF6EB;
  border: 1px solid #FFF2E1;
  border-radius: 4px;
  color: #FFA338;
}
#transaction-status .purchase-waiting label {
  color: #FFA338;
}
#transaction-status .expire-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #999999;
  border: 1px solid #FAFAFA;
}
#transaction-status .expire-text span {
  color: #333;
}
#transaction-status .refund-text {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #1F49DA;
}
#transaction-status .card-name {
  font-size: 20px;
  color: #666;
  margin-bottom: 16px;
}
#transaction-status .loading-text {
  margin-top: 12px;
}
#consumption .merchant-name {
  font-size: 18px;
  text-align: center;
  color: #666666;
  max-width: 335px;
  word-break: break-all;
}
#consumption .consumption-text {
  font-size: 16px;
  text-align: center;
  color: #999999;
}
#rmb-payment-order .base .amount {
  font-size: 24px;
  line-height: 30px;
  padding: 0;
  margin-top: 0;
}
#rmb-payment-order .base .order-loading .base-icon {
  margin-bottom: 8px;
}
#switch-rmb .main {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.151724px;
  color: #1F49DA;
}
#switch-rmb .text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.151724px;
  font-family: 'Alibaba PuHuiTi';
  color: #999999;
  border: 1px solid #FAFAFA;
  margin: 8px -15px;
  border-style: solid none none;
  padding-top: 14px;
}
#switch-rmb .text label {
  color: #1F49DA;
}
#usd-refund-transaction .usd-refund-des-text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  letter-spacing: -0.151724px;
  padding: 10px 0;
  color: #FF0C0C;
}
#usd-refund-transaction .usd-refund-tip {
  font-size: 16px;
  line-height: 22px;
  color: #999;
}
.rmb-status-text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.167503px;
  color: #666666;
}
