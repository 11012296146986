.policy {
  position: relative;
  padding: 12px;
}
.policy .close {
  position: absolute;
  right: 12px;
  top: 12px;
}
.policy .close svg {
  width: 22px;
  height: 22px;
}
