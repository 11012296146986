#update-name-container .title {
  margin: 72px 24px;
  font-weight: 700;
  font-size: 18px;
  color: #FF3841;
  text-align: left;
}
#update-name-container .adm-input-wrapper {
  margin: 28px 24px 12px;
  border: 1px solid #EEEEEE;
  border-radius: 38px;
  padding: 6px 16px 6px 22px;
  width: calc(100% - 48px);
  position: relative;
}
#update-name-container .adm-input-wrapper::before {
  content: "*";
  position: absolute;
  top: 12px;
  left: 12px;
  color: #FF3841;
  font-size: 16px;
  font-weight: 500;
}
#update-name-container .adm-input-wrapper:not(:first-child) {
  margin-top: 12px;
}
#update-name-container .adm-button {
  margin-top: 68px;
  background-color: #FF8F94;
  border-radius: 40px;
  color: #FFF;
  font-weight: 500;
  font-size: 16px;
  width: 200px;
}
#update-name-container .adm-button.active {
  background-color: #FF3841;
}
