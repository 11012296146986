@keyframes blink {
  0% {
    background-color: white;
  }
  50% {
    background-color: #000000;
  }
  100% {
    background-color: white;
  }
}
@keyframes blink {
  0% {
    background-color: white;
  }
  50% {
    background-color: #000000;
  }
  100% {
    background-color: white;
  }
}
.stepOne .stepOne-top {
  padding-top: 24px;
  height: 174px;
  background: #F9F9F9;
}
.stepOne .stepOne-top .close {
  position: absolute;
  right: 20px;
  top: 20px;
}
.stepOne .stepOne-top .close svg {
  width: 24px;
  height: 24px;
}
.stepOne .stepOne-top .stepOne-top-tip {
  padding-left: 24px;
  margin-bottom: 27px;
}
.stepOne .stepOne-top .stepOne-top-tip p:nth-of-type(1) {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #FF3841;
}
.stepOne .stepOne-top .stepOne-top-tip p:nth-of-type(2) {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #FF3841;
  margin-bottom: 12px;
}
.stepOne .stepOne-top .stepOne-top-tip p:nth-of-type(3) {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
.stepOne .stepOne-top .stepOne-top-tip p:nth-of-type(4) {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
.stepOne .input-container {
  margin: 24px;
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 17px;
  font-size: 16px;
  color: #333333;
}
.stepOne .imitate-placeholder {
  color: #bbbbbb;
}
.stepOne .stepOne-bottom {
  background: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.stepOne .stepOne-bottom .next-button2 {
  width: 315px;
  height: 40px;
  background: #FF3841;
  border-radius: 40px;
  color: #FFFFFF;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.stepOne .stepOne-bottom .next-button2.disbaled {
  opacity: 0.5;
}
.stepOne .stepOne-bottom .product-introduce {
  margin-bottom: 16px;
  font-size: 14px;
  color: #CCCCCC;
}
.stepOne .stepOne-bottom .product-introduce p:nth-of-type(1) {
  margin-bottom: 6px;
}
.stepOne .stepOne-bottom .keyboard-container {
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: bottom 0.8s;
  display: block;
}
.stepOne .stepOne-bottom .keyboard-container1 {
  background: #fff;
  bottom: -300px;
  display: none;
}
.stepOne .stepOne-bottom .keyboard-title {
  width: 100%;
  height: 26px;
}
.stepOne .stepOne-bottom .keyboard-title svg {
  width: 20px;
  height: 4px;
}
.stepOne .stepOne-bottom .keyboard {
  border-left: 1px solid #F7F7F7;
  border-top: 1px solid #F7F7F7;
  width: 100%;
}
.stepOne .stepOne-bottom .keyboard .keyboard-left {
  flex: 1;
}
.stepOne .stepOne-bottom .keyboard .keyboard-left li {
  width: 33.33%;
  height: 64px;
  text-align: center;
  color: #000000;
  font-size: 24px;
  border-bottom: 1px solid #F7F7F7;
  border-right: 1px solid #F7F7F7;
}
.stepOne .stepOne-bottom .keyboard .keyboard-left li:nth-of-type(10) {
  flex: 1;
}
.stepOne .stepOne-bottom .keyboard .keyboard-left li:nth-of-type(11) {
  width: 33.33%;
}
.stepOne .stepOne-bottom .keyboard .keyboard-right {
  width: 96px;
}
.stepOne .stepOne-bottom .keyboard .keyboard-right .del-button {
  height: 64px;
}
.stepOne .stepOne-bottom .keyboard .keyboard-right .del-button img {
  width: 37px;
  height: 29px;
}
.stepOne .stepOne-bottom .keyboard .keyboard-right .next-button {
  height: 192px;
  background: #FF8F94;
  color: #FFFFFF;
  font-size: 18px;
}
.stepOne .stepOne-bottom .keyboard .keyboard-right .next-button1 {
  background: #FF3841;
}
.stepOne .imitate-input {
  margin: 32px 30px 48px 30px;
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 17px;
  font-size: 16px;
  color: #333333;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-size: px2rem(16);
  border: px2rem(1) solid #999999;
  user-select: text;
  -webkit-user-select: text;
}
.stepOne .imitate-input .selected::after {
  content: '';
  display: block;
  width: 1px;
  height: 16px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
.stepOne .yes::after {
  content: '';
  display: block;
  width: 1px;
  height: 16px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
.stepOne .not:empty::before {
  content: '';
  display: block;
  width: 1px;
  height: 16px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
.stepOne .not:empty::after {
  content: attr(placeholder);
  color: #999999;
  font-size: px2rem(16);
}
#recharge-withdraw .adm-number-keyboard-popup.adm-popup {
  z-index: 10;
}
#recharge-withdraw .adm-number-keyboard {
  max-width: 375px;
}
.recharge-withdraw-keyboard .with-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 68px;
}
.recharge-withdraw-keyboard .with-title .adm-number-keyboard-title {
  color: #828282;
}
.recharge-withdraw-keyboard .adm-number-keyboard-key.ok-key {
  background-color: #FF3841;
}
.recharge-withdraw-keyboard.keyboard-disabled .adm-number-keyboard-key.ok-key {
  opacity: 0.5;
}
