.address-form .input-container {
  margin: 24px 24px 16px;
  position: relative;
}
.address-form .input-container .input-inner-container {
  position: relative;
}
.address-form .input-container .input-inner-container .down-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.address-form .input-container .input-inner-container .clear-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  right: 42px;
}
.address-form .input-container img {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.address-form .input-container .address-input {
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 28px;
  font-size: 16px;
  color: #333333;
}
.address-form .input-container .address-input.active {
  border: 1px solid #1F49DA;
}
.address-form .search {
  background-color: #fff;
  width: 100%;
  position: absolute;
  z-index: 111;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.address-form .search .search-ul {
  max-height: 200px;
  overflow-y: scroll;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.address-form .search .search-ul::-webkit-scrollbar {
  width: 0;
}
.address-form .search .search-ul .search-item {
  height: 25px;
  line-height: 25px;
  padding-left: 25px;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}
