.plaidFail {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 65px 0 72px;
  min-height: 100vh;
}
.plaidFail .closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
}
.plaidFail .failPic {
  width: 72px;
  height: 72px;
}
.plaidFail .p1 {
  padding: 28px 0 0;
  font-size: 20px;
  line-height: 28px;
  color: #333;
}
.plaidFail .p2 {
  padding: 8px 0 0;
  width: 327px;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}
.plaidFail .button {
  box-sizing: border-box;
  width: 327px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  border-radius: 20px;
}
.plaidFail .buttonRed {
  padding: 8px 0 0;
  font-size: 14px;
  line-height: 20px;
  margin: auto 0 0;
  background: #FF3841;
  color: #fff;
}
.plaidFail .buttonWhite {
  border: 1px solid #FF3841;
  margin: 14px 0 0;
  color: #FF3841;
}
