#rmb-paymenmt-explain {
  background-color: #F9F9F9;
  padding-bottom: 100px;
}
#rmb-paymenmt-explain .adm-tabs-tab-list {
  background-color: #fff;
}
#rmb-paymenmt-explain .adm-tabs-tab {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #999999;
}
#rmb-paymenmt-explain .adm-tabs-tab-active {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FF3841;
}
#rmb-paymenmt-explain .adm-tabs-tab-line {
  color: #FF3841;
  background-color: #FF3841;
}
#rmb-paymenmt-explain img {
  width: 100%;
}
#rmb-paymenmt-explain .adm-nav-bar {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 64px;
  height: 64px;
  background-color: #fff;
  text-align: center;
  color: #000000;
  padding: 0;
  padding-left: 10px;
}
#rmb-paymenmt-explain .adm-tabs-content {
  padding: 0;
}
