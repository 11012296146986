@keyframes cursor-blinks {
  0% {
    opacity: 1;
    display: block;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
#setting-password {
  margin: 0 auto;
  max-width: 400px;
}
#setting-password .setting-password-b {
  background: #fff;
  height: 100vh;
  margin: 0 32px 40px;
  padding-top: 0;
}
#setting-password .setting-password-b .header {
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#setting-password .setting-password-b .header .adm-nav-bar {
  width: 100%;
  padding: 0;
}
#setting-password .setting-password-b .title-warp-password-b {
  width: 100%;
  margin-top: 60px;
  text-align: left;
}
#setting-password .setting-password-b .title-warp-password-b span {
  color: #999;
  font-size: 14px;
}
#setting-password .setting-password-b .title-warp-password-b .special-tip {
  font-family: 'Alibaba PuHuiTi';
  font-size: 28px;
}
#setting-password .setting-password-b .title-warp-password-b .title-warp-password-b-two {
  font-size: 28px;
  text-align: left;
  color: #000;
  font-weight: 500;
  margin-bottom: 6px;
}
#setting-password .setting-password-b .verification-code {
  margin-top: 64px;
  width: 100%;
}
#setting-password .setting-password-b .verification-code .adm-passcode-input-cell-container {
  display: flex;
  justify-content: flex-start;
}
#setting-password .setting-password-b .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #EFEFEF;
  border-radius: 4px;
  border: none;
}
#setting-password .setting-password-b .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell.focused {
  box-shadow: 0 0 2px 0 #1F49DA;
}
#setting-password .setting-password-b .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #FF3841;
  box-shadow: none;
}
#setting-password .setting-password-b .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell.dot::before {
  background-color: #FFF;
}
#setting-password .setting-password-b .varification-tips-b {
  margin-left: 0;
  margin-right: 0;
}
#setting-password .setting-password-b .setting-password-b-times {
  margin-top: 0px;
  text-align: z left;
  line-height: 18px;
  font-size: 11px;
}
#setting-password .setting-password-b .btn-container {
  width: 100%;
  margin-top: 55px;
}
#setting-password .setting-password-b .btn-container .warning-btn {
  width: 100% !important;
}
#setting-password .setting-password-b .btn-container button {
  margin-top: 0 !important;
}
.setting-password-b .varification-tips-b {
  margin: 20px 24px;
  color: #999;
  text-align: left;
}
.setting-password-b .unvla {
  color: #FF3841;
}
.setting-password-b .dispaly-n {
  display: none;
}
.setting-password-b .erro-tips {
  width: 310px;
  margin-bottom: 12px;
  text-align: left;
  display: block;
  color: #FF3841;
}
.setting-password-b .is-reg {
  background: #FF8F94;
  color: #fff;
}
