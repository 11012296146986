.sample {
  width: 8rem;
  height: 9rem;
  background-color: #ffffff;
  text-align: center;
  padding: 0 12px;
}
.sample .passport {
  margin-top: 16px;
}
.sample .passport img {
  width: 246px;
  height: 166px;
}
.sample-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 174.2%;
  text-align: center;
  margin-top: 0.5rem;
  color: #1F49DA;
}
.sample-describe {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
