#rmb-payment-order {
  background-color: #fafafa;
  overflow-y: scroll;
  padding-bottom: 200px;
}
#rmb-payment-order::-webkit-scrollbar {
  width: 0;
}
#rmb-payment-order .adm-nav-bar {
  height: 64px;
}
#rmb-payment-order .nav-bar {
  background-color: #fff;
}
#rmb-payment-order #transaction-status {
  margin-top: 12px;
}
#rmb-payment-order .base {
  background-color: #fff;
  padding: 21px 15px;
  position: relative;
}
#rmb-payment-order .base .compliance-tips {
  background-color: #FFF6EB;
  position: relative;
  height: 53px;
  border: 1px solid #FFF6EB;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
#rmb-payment-order .base .compliance-tips img {
  width: 29px;
  height: 29px;
  max-height: 29px;
  margin: 0 4px;
}
#rmb-payment-order .base .compliance-tips .text {
  line-height: 15px;
  color: #F9941E;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
#rmb-payment-order .base .compliance-tips .text .bold-tips {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}
#rmb-payment-order .base .compliance-tips::before {
  content: '';
  width: 0;
  height: 0;
  border: 20px solid;
  position: absolute;
  bottom: -41px;
  left: 48%;
  border-color: #fff transparent transparent;
}
#rmb-payment-order .base .compliance-tips::after {
  content: '';
  width: 0;
  height: 0;
  border: 20px solid;
  position: absolute;
  bottom: -30px;
  left: 48%;
  border-color: #FFF6EB transparent transparent;
}
#rmb-payment-order .base .consumption-tips {
  background-color: #FFF6EB;
  position: relative;
  height: 53px;
  border: 1px solid #FFF6EB;
  border-radius: 4px;
  display: flex;
  margin: 0px 10px;
  align-items: center;
}
#rmb-payment-order .base .consumption-tips img {
  width: 29px;
  height: 29px;
  max-height: 29px;
  margin: 0 8px 0px 20px;
}
#rmb-payment-order .base .consumption-tips .text {
  line-height: 15px;
  color: #F9941E;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
}
#rmb-payment-order .base .consumption-tips::before {
  content: '';
  width: 0;
  height: 0;
  border: 20px solid;
  position: absolute;
  bottom: -41px;
  left: 48%;
  border-color: #fff transparent transparent;
}
#rmb-payment-order .base .consumption-tips::after {
  content: '';
  width: 0;
  height: 0;
  border: 20px solid;
  position: absolute;
  bottom: -30px;
  left: 48%;
  border-color: #FFF6EB transparent transparent;
}
#rmb-payment-order .base .order-loading {
  margin-top: 20px;
}
#rmb-payment-order .base .order-loading .base-icon {
  width: 56px;
  margin-bottom: 20px;
}
#rmb-payment-order .base .order-loading .base-icon.warning {
  margin-bottom: 20px;
}
#rmb-payment-order .base .purchase-success-text {
  font-size: 16px;
  color: #999;
}
#rmb-payment-order .base .purchase-success-text span {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  letter-spacing: -0.151724px;
  color: #FF3841;
}
#rmb-payment-order .base .amount {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  letter-spacing: -0.151724px;
  color: #333333;
  padding: 0 0 12px;
}
#rmb-payment-order .base .loading-text {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.151724px;
  color: #1F49DA;
}
#rmb-payment-order .base .loading-text.error {
  color: #FF0C0C;
}
#rmb-payment-order .risk {
  margin: 12px 9px 0;
  background: #FFFAFA;
  border: 1px solid #FFEBEB;
  border-radius: 4px;
  padding: 13px 16px;
  text-align: left;
}
#rmb-payment-order .risk .risk-tips {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.151724px;
  color: #000000;
}
#rmb-payment-order .risk .risk-tips label {
  color: #FF0000;
}
#rmb-payment-order .risk .risk-sub-tips {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.151724px;
  color: #999999;
}
#rmb-payment-order .faq {
  margin-top: 12px;
  background-color: #fff;
  padding: 12px;
}
#rmb-payment-order .faq .title {
  padding: 0px 23px;
  display: flex;
  height: 40px;
  line-height: 40px;
  align-items: center;
}
#rmb-payment-order .faq .title .left {
  flex: 1;
  text-align: left;
  margin-left: 0;
  vertical-align: middle;
  align-items: center;
}
#rmb-payment-order .faq .title .left img {
  width: 16px;
  height: 16px;
  max-height: 16px;
}
#rmb-payment-order .faq .title .left label {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 8px;
  letter-spacing: -0.151724px;
  color: #333333;
}
#rmb-payment-order .faq .title a {
  text-decoration: none;
}
#rmb-payment-order .faq .title .right {
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #999999;
}
#rmb-payment-order .faq .list {
  padding: 12px 25px;
  border: 1px solid #F7F7F7;
  border-style: solid none none;
  text-align: left;
}
#rmb-payment-order .faq .list .question {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.151724px;
  color: #333333;
}
#rmb-payment-order .faq .list .answer {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 18px;
  letter-spacing: -0.151724px;
  color: #333333;
}
#rmb-payment-order .check-out-order {
  background-color: #fff;
  margin-top: 12px;
  padding: 20px 24px;
}
#rmb-payment-order .check-out-order .order-detail-item {
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 30px;
  line-height: 30px;
}
#rmb-payment-order .check-out-order .order-detail-item .order-key {
  flex: 1;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: -0.151724px;
  color: #999999;
  text-align: left;
}
#rmb-payment-order .check-out-order .order-detail-item .icon {
  width: 15px;
  max-height: 20px;
  margin-right: 4px;
}
#rmb-payment-order .check-out-order .order-detail-item .order-value {
  font-size: 11px;
  line-height: 17px;
  text-align: right;
  color: #000000;
}
#rmb-payment-order .check-out-order .order-detail-item .order-value .code-copy {
  font-size: 11px;
  line-height: 16px;
  border: 1px solid #F9941E;
  text-align: right;
  letter-spacing: -0.151724px;
  color: #F9941E;
  border-radius: 10px;
  padding: 1px 4px;
  margin-left: 4px;
}
#rmb-payment-order .operater-order-tips {
  height: 32px;
  width: 100%;
  background: #F2F5FF;
  filter: drop-shadow(0px -2px 7px rgba(0, 0, 0, 0.1));
  max-width: 375px;
  position: fixed;
  bottom: 90px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #1F49DA;
  line-height: 32px;
}
#rmb-payment-order .operater-order-action {
  height: 90px;
  width: 100%;
  background-color: #fff;
  filter: drop-shadow(0px -2px 7px rgba(0, 0, 0, 0.1));
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 0 20px;
  position: fixed;
  bottom: 0;
  z-index: 1;
  max-width: 375px;
  justify-content: space-between;
}
#rmb-payment-order .operater-order-action .cs {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.165px;
  color: #1F49DA;
  padding-right: 10px;
  display: flex;
  white-space: nowrap;
}
#rmb-payment-order .operater-order-action .cs label {
  color: #666;
}
#rmb-payment-order .operater-order-action .cs img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
#rmb-payment-order .operater-order-action button {
  max-width: 170px;
  flex: 1;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.165px;
  color: #bdbdbd;
  border: 1px solid #bdbdbd;
  border-radius: 44px;
  height: 44px;
  line-height: inherit;
  background-color: #fff;
}
#notice-pop {
  background-color: #FFF;
  border-radius: 12px;
  width: 295px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 28px;
}
#notice-pop .header-icon {
  width: 86px;
  margin: -28px auto 0;
  position: relative;
}
#notice-pop .title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 32px;
}
#notice-pop button {
  width: 120px;
  height: 40px;
  font-size: 16px;
  color: #FFF;
  background: #FF3841;
  border-radius: 62px;
  cursor: pointer;
}
.title {
  margin: 0;
}
.title::after {
  content: none;
  align-items: top;
  color: #FF3841;
}
.related-transaction {
  height: 67px;
  background-color: #fff;
  margin-top: 12px;
  padding: 11px 23px 11px 28px;
  display: flex;
  cursor: pointer;
}
.related-transaction .re-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.151724px;
  color: #333333;
  vertical-align: top;
}
.related-transaction .re-value {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  text-align: right;
  flex: 1;
  line-height: 20px;
}
.related-transaction .re-value .re-des {
  font-size: 14px;
  letter-spacing: -0.151724px;
  color: #1F49DA;
}
.related-transaction .re-value span {
  color: #FF3841;
}
.related-transaction .re-value .timestamp {
  font-size: 12px;
  text-align: right;
  letter-spacing: -0.151724px;
  color: #999999;
}
