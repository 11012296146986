#freezeAccountC-page {
  max-width: 496px;
  margin: 0 auto;
  padding-top: 12px;
  min-height: 700px !important;
}
#freezeAccountC-page .adm-nav-bar {
  padding-bottom: 20px;
}
#freezeAccountC-page .freezeAccountC {
  background: #F4F4F4;
  height: 100vh;
  padding-top: 72px;
}
#freezeAccountC-page .freezeAccountC img {
  width: 56px;
  height: 56px;
}
#freezeAccountC-page .freezeAccountC p {
  width: 300px;
  height: 54px;
  font-size: 16px;
  text-align: center;
  color: #000000;
  margin: 12px 0 64px;
}
#freezeAccountC-page .freezeAccountC .for-back {
  background: none;
  color: #FF3841;
  border: solid 1px #FF3841;
}
#freezeAccountC-page .erro-warp {
  position: relative;
}
#freezeAccountC-page .erro-warp .mask-close {
  position: absolute;
  top: -100px;
  right: 0;
  cursor: pointer;
}
#freezeAccountC-page .erro-warp .notice-icon {
  width: 85.5px;
  height: 97px;
  position: absolute;
  top: -58px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 111;
}
#freezeAccountC-page .erro-warp .erro-bx {
  padding-top: 58px;
  text-align: center;
}
#freezeAccountC-page .erro-warp .erro-bx .title {
  font-size: 20px;
  color: #000;
}
#freezeAccountC-page .erro-warp .erro-bx h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}
#freezeAccountC-page .erro-warp .erro-bx .copy-btn {
  background: #FF3841;
  border-radius: 40px;
  width: 240px;
  height: 40px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}
#freezeAccountC-page .btn-container .freezeAccountC-btn {
  margin-top: 0px !important;
}
#freezeAccountC-page .btn-container .btn-wraning {
  margin-top: 15px;
}
#freezeAccountC-page .btn-container .btn-wraning .warning-btn {
  background-color: #fff;
  border: 1px solid #FF3841;
  color: #FF3841;
  margin-top: 12px;
}
