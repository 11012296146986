#choose-bank {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 0 32px 80px;
  max-width: 375px;
}
#choose-bank .nav {
  padding-left: 0;
  padding-right: 0;
}
#choose-bank .banks-space {
  width: 100%;
  margin-top: 24px;
}
#choose-bank .banks-space .bank-option {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #FAFAFA;
  padding-top: 20px;
  padding-bottom: 20px;
}
#choose-bank .banks-space .bank-option .bank-info {
  display: flex;
  align-items: center;
  flex: 1;
}
#choose-bank .banks-space .bank-option .bank-info img {
  width: 30px;
  height: 30px;
  margin-left: 21px;
  margin-right: 22px;
}
#choose-bank .banks-space .bank-option .bank-info .bank-detail {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;
}
#choose-bank .banks-space .bank-option .bank-info .bank-detail .name {
  font-size: 16px;
  color: #333333;
}
#choose-bank .banks-space .bank-option .bank-info .bank-detail .datetime {
  margin-top: 8px;
  font-size: 12px;
  color: #999999;
}
#choose-bank .bottom-btn {
  width: 100vw;
  max-width: 375px;
  background: #FFFFFF;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-top: 12px;
  padding-bottom: 28px;
}
#choose-bank .bottom-btn button {
  width: 327px;
  height: 40px;
  line-height: inherit;
  background-color: #FF3841;
  border-radius: 40px;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
}
#delete-bank-pop {
  background-color: #FFFFFF;
  border-radius: 10px;
  width: 294px;
  display: flex;
  flex-direction: column;
  padding: 24px 22px 38px;
  align-items: center;
  position: relative;
}
#delete-bank-pop .close {
  position: absolute;
  top: -48px;
  right: 0;
}
#delete-bank-pop .content {
  margin-top: 14px;
  margin-bottom: 23px;
  font-weight: 500;
  font-size: 20px;
  color: #161719;
}
#delete-bank-pop .bank-info {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
#delete-bank-pop .bank-info img {
  width: 40px;
  height: 40px;
  margin-right: 4px;
}
#delete-bank-pop .bank-info .name {
  font-size: 16px;
  color: #333;
}
#delete-bank-pop button {
  position: static;
  margin: 0 auto;
  background-color: #FF3841;
  border-radius: 40px;
  font-size: 16px;
  color: #FFFFFF;
  width: 240px;
  height: 40px;
}
