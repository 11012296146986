#tutorial-bankCard {
  background-color: #f9f9f9;
  min-height: 100vh;
  position: relative;
}
#tutorial-bankCard .close {
  position: absolute;
  top: 30px;
  right: 20px;
}
#tutorial-bankCard .topImg {
  padding-top: 25px;
  background-color: #fff;
}
#tutorial-bankCard .topImg .firstImg {
  width: 100%;
}
#tutorial-bankCard .topCard {
  width: 100%;
  padding: 20px 30px 30px;
  background-color: #fff;
  text-align: left;
}
#tutorial-bankCard .topCard .selectType {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 32px;
  height: 40px;
  padding: 8px 12px;
}
#tutorial-bankCard .topCard .selectType .selectTypeItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#tutorial-bankCard .topCard .selectType .selectTypeItem .itemLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}
#tutorial-bankCard .topCard .selectType .selectTypeItem .itemLeft img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
#tutorial-bankCard .content {
  background-color: #f9f9f9;
}
#tutorial-bankCard .content img {
  width: 100%;
}
#tutorial-bankCard .content .bottomBtn {
  padding: 30px;
}
#tutorial-bankCard .content .bottomBtn .closeBtn {
  background: #ff3841;
  border-radius: 40px;
  height: 40px;
  border-radius: 32px;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
}
.checkTypePopup {
  width: 100%;
  max-width: 375px;
  height: 100%;
  position: relative;
  display: flex !important;
}
.checkTypePopup .typePopContent .popItems {
  max-width: 375px;
  padding: 20px 20px 15px;
}
.checkTypePopup .typePopContent .popItems .popItem {
  width: 100%;
}
.checkTypePopup .typePopContent .popItems .cancel {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #BDBDBD;
  padding: 15px;
}
.checkTypePopup .typePopContent .popItems .selectTypeItem {
  width: 100%;
}
.checkTypePopup .typePopContent .popItems .selectTypeItem .itemLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  padding: 0px 0 0;
}
.checkTypePopup .typePopContent .popItems .selectTypeItem .itemLeft .itemLeftImg {
  width: 10%;
  display: flex;
  align-items: center;
  text-align: right;
}
.checkTypePopup .typePopContent .popItems .selectTypeItem .itemLeft .itemLeftImg img {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.checkTypePopup .typePopContent .popItems .selectTypeItem .itemLeft .itemLeftText {
  width: 50%;
  text-align: center;
}
.accountNumberPopup {
  width: 100%;
  max-width: 375px;
  height: 100%;
  position: relative;
  display: flex !important;
}
.accountNumberPopup > .adm-mask {
  position: fixed;
}
.accountNumberPopup > .adm-popup-body {
  height: 220px !important;
}
.accountNumberPopup .accountNumberPopup-inner {
  width: 100%;
  max-width: 375px;
  border-top: 0.1px solid #eee;
  border-left: 0.1px solid #eee;
  border-right: 0.1px solid #eee;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 100%;
  position: relative;
  padding-top: 20px;
}
.accountNumberPopup .accountNumberPopup-inner .closeDialog {
  left: 147.5px;
}
