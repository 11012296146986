#kyc-header {
  padding: 24px 24px 24px;
  background: #F9F9F9;
}
#kyc-header .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #FF3841;
  text-align: left;
}
#kyc-header .subtitle {
  margin-top: 12px;
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  color: #999999;
}
#kyc-header .close-action {
  text-align: right;
  margin-bottom: 12px;
}
