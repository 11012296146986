#recharge-money {
  position: relative;
  width: 320px;
  height: fit-content;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 32px 24px;
  background: #FFFFFF;
  border-radius: 12px;
}
#recharge-money .title {
  color: #999;
  font-size: 18px;
  margin-bottom: 15px;
}
#recharge-money .close-img {
  width: 28px;
  height: 27px;
  position: absolute;
  top: -40px;
  right: 0;
}
#recharge-money .amount {
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #1F49DA;
  margin-bottom: 16px;
}
#recharge-money .money-section .item {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
#recharge-money .money-section .item .label {
  color: #999;
}
#recharge-money .money-section .item .label .question {
  margin-left: 5px;
}
#recharge-money .money-section .item .money {
  color: #333;
}
#recharge-money .friendly-reminder {
  margin: 12px auto;
  background: #FFF8EF;
  border-radius: 34px;
  padding: 4px 8px;
  color: #F9941E;
}
#recharge-money .friendly-reminder.enjoy {
  background: #EFF3FF;
  color: #1F49DA;
}
#recharge-money .tips {
  color: #ffa338;
  background: #ffdfbb5d;
  padding: 6px 10px;
  border-radius: 4px;
  position: relative;
  white-space: nowrap;
  font-size: 12px;
}
#recharge-money .tips .triangle {
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #ffdfbb5d;
}
#recharge-money .btn-width {
  width: 6.4rem;
}
#recharge-money .warning-btn {
  background: #FF3841;
  border: 0;
  font-weight: 500;
  border-radius: 40px;
  margin: 12px auto 0;
  color: #FFFFFF;
  font-size: 16px;
}
#recharge-money .confirmAuto {
  margin-top: 12px;
}
#recharge-money .confirmAuto p:nth-of-type(1) {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #555555;
}
