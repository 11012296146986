#kyc-header-conatiner {
  text-align: left;
  padding: 0px 20px 10px;
  background-color: #f9f9f9;
}
#kyc-header-conatiner .title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: #ff3841;
}
#kyc-header-conatiner .sub-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: #999999;
}
