.app-guide-login {
  background: #FF3841;
  border-radius: 40px;
  height: 40px;
  margin: 30px;
  line-height: 40px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  min-height: 0;
}
