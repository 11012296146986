#record-transaction-list {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#record-transaction-list .header-section {
  padding: 32px 12px 42px;
  background-color: #F5F5F5;
}
#record-transaction-list .header-section .adm-search-bar .adm-search-bar-input-box {
  border-color: #D9DFF7;
}
#record-transaction-list .header-section .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input-box-icon {
  padding-left: 12px;
}
#record-transaction-list .header-section .adm-search-bar .adm-search-bar-input-box .adm-search-bar-input {
  padding-top: 11px;
  padding-bottom: 11px;
  height: 36px;
}
#record-transaction-list .header-section .adm-search-bar .adm-search-bar-suffix a {
  color: #333;
  font-size: 14px;
}
#record-transaction-list .header-section .title-section {
  margin: 18px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#record-transaction-list .header-section .title-section .left-section {
  display: flex;
  align-items: center;
}
#record-transaction-list .header-section .title-section .left-section .title {
  font-size: 24px;
  color: #222222;
}
#record-transaction-list .header-section .title-section .left-section .switch {
  margin-left: 8px;
  background-color: #333;
  border-radius: 21px;
  color: #FFF;
  font-size: 12px;
  padding: 5px 10px;
  cursor: pointer;
}
#record-transaction-list .header-section .title-section .left-section .switch img {
  width: 8px;
  height: 7.2px;
  margin-right: 4px;
}
#record-transaction-list .header-section .title-section .avatar {
  width: 28px;
  height: 28px;
  cursor: pointer;
}
#record-transaction-list .header-section .statement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 12px 16px;
  background-color: #FAFBFF;
  border-radius: 30px;
  height: 28px;
  line-height: 28px;
  padding: 5px 10px 5px 11px;
}
#record-transaction-list .header-section .statement .statement-left svg {
  margin-right: 8px;
}
#record-transaction-list .header-section .statement .statement-left .statement-txt {
  display: inline-block;
  font-size: 14px;
  color: #333;
}
#record-transaction-list .card-info {
  padding: 28px 20px 24px;
  background: linear-gradient(286.86deg, #FFF8F9 -2.02%, #FFFBFC 105.49%);
  box-shadow: 0px 5.33333px 8px rgba(162, 71, 75, 0.1);
  border-radius: 10.6667px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin-bottom: 22px;
  width: calc(100% - 20px);
  height: 175px;
}
#record-transaction-list .card-info .top {
  width: 100%;
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: left;
}
#record-transaction-list .card-info .top .balance .label {
  color: #666;
  font-size: 12px;
}
#record-transaction-list .card-info .top .balance .label svg {
  margin-left: 5px;
}
#record-transaction-list .card-info .top .balance .value {
  margin-top: 7px;
  color: #C35A69;
  display: flex;
  align-items: flex-start;
}
#record-transaction-list .card-info .top .balance .value .symbol {
  font-size: 12px;
}
#record-transaction-list .card-info .top .balance .value .amount {
  margin-left: 4px;
  font-size: 20px;
}
#record-transaction-list .card-info .top .balance .value .amount svg {
  margin-left: 14px;
}
#record-transaction-list .card-info .top .tillstar-logo {
  margin-top: -12px;
  width: 51.7px;
  height: 16px;
}
#record-transaction-list .card-info .bottom {
  margin-top: 28px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
}
#record-transaction-list .card-info .bottom .balance .label {
  color: #666;
  font-size: 12px;
}
#record-transaction-list .card-info .bottom .balance .label svg {
  margin-left: 5px;
}
#record-transaction-list .card-info .bottom .balance .value {
  margin-top: 7px;
  color: #333;
  display: flex;
  align-items: flex-start;
}
#record-transaction-list .card-info .bottom .balance .value .symbol {
  font-size: 12px;
}
#record-transaction-list .card-info .bottom .balance .value .amount {
  margin-left: 4px;
  font-size: 20px;
}
#record-transaction-list .adm-tabs-header {
  background-color: #fff;
}
#record-transaction-list .no-record {
  width: 180px;
  height: 166px;
  background-color: #ffeced;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
#record-transaction-list .no-record img {
  width: 60%;
}
#record-transaction-list .no-record-text {
  font-size: 16px;
  color: #d8d8d8;
  margin-top: 0.42667rem;
}
#record-transaction-list .myHeader {
  background-color: #fff;
  margin-bottom: 0px;
  padding-bottom: 20px;
}
#record-transaction-list .filter-content {
  background-color: #f5f5f5;
  text-align: left;
}
#record-transaction-list .adm-tabs-content {
  padding: 0px;
}
#record-transaction-list .adm-list-item-content {
  padding: 0px;
}
#record-transaction-list .month-filter {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 23px;
  color: #222222;
  padding: 15px 24px;
}
#record-transaction-list .month-filter .cost {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  padding-top: 5px;
}
#record-transaction-list .month-filter .cost span {
  font-size: 12px;
  padding-right: 10px;
}
#record-transaction-list .month-filter span {
  font-size: 14px;
}
#record-transaction-list .lists-section {
  margin-top: -36px;
  background-color: #FFF;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  padding-bottom: 68px;
}
#record-transaction-list .lists-section .transaction-lists {
  height: calc(100vh - 200px);
  overflow-y: auto;
}
#record-transaction-list .lists-section .adm-tabs .adm-tabs-header {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding-top: 20px;
}
#record-transaction-list .list .list-title {
  padding: 20px 24px 12px;
  color: #999;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
}
#record-transaction-list .list .adm-list-item {
  padding-left: 0;
}
#record-transaction-list .adm-tabs-tab {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #222222;
}
#record-transaction-list .adm-tabs-tab-active {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #222222;
}
#record-transaction-list .adm-tabs-tab-line {
  background-color: #FF3841;
}
#record-transaction-list .no-login {
  margin-top: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#record-transaction-list .no-login img {
  width: 248px;
}
#record-transaction-list .no-login button {
  width: 88px;
  height: 25px;
  margin-top: 22px;
  background: #FF3841;
  border-radius: 41px;
  color: #FFF;
  font-size: 12px;
}
#record-transaction-list .leading-mask button {
  margin-top: 24px;
  height: 40px;
  line-height: auto;
  width: 200px;
  color: #fff;
  background: #FF3841;
  border-radius: 29px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}
#record-transaction-list .leading-steps {
  max-width: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#record-transaction-list .leading-steps img {
  width: 100%;
}
#record-transaction-list .rocket-box {
  position: absolute;
  right: 24px;
  bottom: 172px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(171, 80, 80, 0.1);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#record-transaction-list .rocket-box .rocket {
  width: 19.22px;
  height: 22px;
  margin-top: 5px;
}
#record-transaction-list .rocket-box span {
  font-size: 11px;
  text-align: center;
  color: #000000;
}
#record-transaction-list .card-info-box {
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -ms-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  visibility: 1;
  opacity: 1;
  height: 196px;
}
#record-transaction-list .card-info-box.hide {
  opacity: 0;
  visibility: 0;
  height: 0;
}
#record-transaction-list .guide-box {
  margin-top: 153px;
  height: 100vh;
}
#record-transaction-list .guide-box .guide-card {
  width: calc(100% - 24px);
  margin-left: 24px;
  margin-right: 0;
}
#record-transaction-list .guide-box .guide-arrow {
  margin-top: 15px;
  width: 85.5px;
  height: 86.5px;
}
#record-transaction-list .guide-box .guide-tip {
  margin-top: 15px;
  color: #FFF;
  font-size: 16px;
  line-height: 32px;
}
#record-transaction-list .guide-box button {
  margin-top: 11px;
  background: #FF3841;
  border-radius: 29px;
  width: 200px;
  height: 40px;
  color: #FFF;
  font-size: 16px;
  border: none;
}
