.password-dialog {
  padding: 0 8px;
}
.password-title {
  font-size: 24px;
  color: #161719;
  text-align: center;
}
.password-tip {
  color: #999;
  margin-top: 8px;
  padding: 0 !important;
}
.password-tip p {
  font-size: 14px;
}
.forget-pwd-tip {
  margin-top: 20px;
  font-size: 14px;
  color: #FF3841;
}
.verifyPWDDialog .password-tip {
  margin-bottom: 27px;
}
.verifyPWDDialog .xss {
  font-size: 12px;
  margin-top: 12px;
}
.verifyPWDDialog .edit-box {
  margin-top: 12px;
}
.verifyPWDDialog .verification-code {
  width: 100%;
}
.verifyPWDDialog .verification-code .adm-passcode-input-cell-container {
  display: flex;
  justify-content: flex-start;
}
.verifyPWDDialog .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #EFEFEF;
  border-radius: 4px;
  border: none;
}
.verifyPWDDialog .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell.focused {
  box-shadow: 0 0 2px 0 #1F49DA;
}
.verifyPWDDialog .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #FF3841;
  box-shadow: none;
}
.verifyPWDDialog .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell.dot::before {
  background-color: #FFF;
}
.verifyPWDDialogMine .password-tip {
  margin-bottom: 27px;
}
.verifyPWDDialogMine .xss {
  font-size: 12px;
  margin-top: 12px;
}
.verifyPWDDialogMine .edit-box {
  margin-top: 12px;
}
.verifyPWDDialogMine .verification-code {
  width: 100%;
}
.verifyPWDDialogMine .verification-code .adm-passcode-input-cell-container {
  display: flex;
  justify-content: flex-start;
}
.verifyPWDDialogMine .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #EFEFEF;
  border-radius: 4px;
  border: none;
}
.verifyPWDDialogMine .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell.focused {
  box-shadow: 0 0 2px 0 #1F49DA;
}
.verifyPWDDialogMine .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #FF3841;
  box-shadow: none;
}
.verifyPWDDialogMine .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell.dot::before {
  background-color: #FFF;
}
