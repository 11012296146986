#rmb-tutorial {
  background-color: #fff;
  margin-top: 12px;
  font-style: normal;
  font-weight: 400;
}
#rmb-tutorial .official {
  padding: 24px;
}
#rmb-tutorial .official div {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.151724px;
  color: #000000;
  white-space: nowrap;
}
#rmb-tutorial .official div label {
  color: #FFA338;
}
#rmb-tutorial .official img {
  width: 100%;
  margin: 10px 0px 15px;
}
#rmb-tutorial .apple-pay {
  padding: 18px;
}
#rmb-tutorial .apple-pay div {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.151724px;
  color: #000000;
}
#rmb-tutorial .apple-pay img {
  width: 100%;
  margin: 10px 0px 15px;
}
