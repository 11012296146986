.home {
  max-width: 100%;
  margin: 0;
  min-height: 660px;
  background-color: #fafafa;
  position: relative;
}
.home .mask-center {
  position: relative;
}
.home .mask-center .common-header-subtitle {
  margin-top: -48px;
}
.home .mask-center .close {
  position: absolute;
  top: -72px;
  right: -12px;
}
.home .mask-center .qrcode {
  max-width: 196px;
  width: 100%;
}
.home .mask-center .notice-icon {
  width: 85.5px;
  height: 97px;
  position: relative;
  top: -58px;
  z-index: 111;
}
.home .activate-success {
  z-index: 9;
}
.home .activate-success .activate-success-content {
  position: relative;
  width: 300px;
  height: 400px;
}
.home .activate-success .activate-success-content .activate-success-img {
  width: 300px;
  height: 400px;
}
.home .activate-success .activate-success-content .activate-success-btn {
  position: absolute;
  bottom: 38px;
  left: 55px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  background: #a41e00;
  border-radius: 53px;
  width: 190px;
  height: 48px;
  text-align: center;
}
.home .activate-success .close {
  width: 300px;
  text-align: right;
  margin-bottom: 28px;
}
.home .home-top {
  background-color: #f5f5f5;
  padding-bottom: 12px;
}
.home .home-top .freeze-account {
  height: 36px;
  background: #fff8f8;
  border-radius: 36px;
  margin: 10px 24px 16px;
  padding-left: 14px;
}
.home .home-top .freeze-account p {
  font-size: 12px;
  line-height: 12px;
  color: #333333;
  display: flex;
  align-items: flex-start;
  margin-right: 4px;
}
.home .home-top .freeze-account img {
  margin-bottom: -4px;
  margin-right: 2px;
  position: relative;
}
.home .home-top .freeze-account .client-btn {
  width: 73px;
  height: 26px;
  background: #ff3841;
  border-radius: 36px;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  margin-right: 6px;
}
.home .home-top .account-info {
  margin-top: 30px;
  padding: 0 50px;
  text-align: center;
  max-width: 396px;
}
.home .home-top .account-info .accout {
  font-size: 12px;
  line-height: 12px;
}
.home .home-top .account-info .text {
  font-size: 12px;
  line-height: 12px;
  color: #666666;
  margin-top: 7px;
}
.home .home-top .isFreeze {
  color: #999999;
}
.home .home-top .isFreeze .routing {
  font-size: 12px;
  line-height: 12px;
  color: #000;
}
.home .home-top .isFreeze .routing .text {
  font-size: 12px;
  line-height: 12px;
  color: #999999;
  margin-top: 7px;
}
.home .home-top .isFreeze .text {
  font-size: 12px;
  line-height: 12px;
  color: #999999;
  margin-top: 7px;
}
.home .home-top .isFreeze .accout .text {
  font-size: 12px;
  line-height: 12px;
  color: #999999;
}
.home .home-top .accout .num {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #000;
}
.home .actions-section {
  margin: 30px 10px 0;
}
.home .actions-section img {
  width: 48px;
  height: 48px;
}
.home .activity-banner-item {
  margin-top: 20px;
}
.home .recommend {
  margin-top: 27px;
}
.home .recommend .recommend-title {
  margin: 0 16px 0 24px;
}
.home .recommend .recommend-title .title-re img {
  width: 13px;
  margin-right: 5px;
}
.home .recommend .recommend-title .title-re span {
  font-size: 18px;
  color: #000000;
}
.home .recommend .recommend-title span {
  font-size: 12px;
  color: #999999;
}
.home .recommend .recommend-list {
  padding: 12px 24px;
  width: 100%;
  overflow-x: auto;
}
.home .recommend .recommend-list .recommend-list-item {
  margin-right: 18px;
}
.home .recommend .recommend-list .recommend-list-item img {
  width: 180px;
  height: 100px;
}
.home .btn-wrapper {
  width: 334px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.home .btn-wrapper .add-btn {
  background: #f5f5f5;
  width: 156px;
  height: 40px;
  border-radius: 40px;
  border: 0 !important;
  font-size: 14px;
  margin-top: 20px;
}
.home .btn-wrapper .cash-btn {
  background: #f5f5f5;
  width: 156px;
  height: 40px;
  border-radius: 40px;
  border: 0 !important;
  font-size: 14px;
  margin-top: 20px;
}
#short-cut {
  width: 100%;
  max-width: 375px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
#short-cut::-webkit-scrollbar {
  opacity: 0;
  width: 0;
}
#short-cut img {
  width: 100vw;
  max-width: 375px;
}
.card-money-freeze {
  color: #999999;
}
.routing .num {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #000;
}
.adm-popup-body .list-card {
  padding: 20px 30px 0 30px;
  max-width: 375px;
  width: 100%;
}
.adm-popup-body .list-card .card-name {
  font-weight: 500;
  font-size: 14px;
  color: #999999;
}
.adm-popup-body .list-card .card-num {
  font-size: 24px;
  color: #222222;
  margin-top: 12px;
}
.adm-popup-body .list-card .card-btn {
  padding: 4px 6px;
  background: #ff3841;
  border-radius: 35px;
  font-size: 14px;
  color: #ffffff;
  border: 0;
}
.adm-popup-body > div {
  background-color: #fff;
}
.block-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 375px;
  margin: 0 auto;
  border: 0.1px solid #eee;
  padding-top: 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.block-top .show-pwd-btn {
  margin-top: 20px;
  margin-bottom: 40px;
}
.block-top-account {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 375px;
  margin: 0 auto;
}
.show-pwd-btn {
  margin: 22px 0;
  width: 300px;
  height: 35px;
  background: #ff3841;
  border-radius: 35px;
  font-size: 14px;
  color: #ffffff;
  border: 0;
}
.adm-nav-bar {
  padding-top: 12px;
}
#no-balance-popup .mask-top {
  margin-top: 20px;
}
#no-balance-popup .mask-bootom {
  justify-content: space-between;
}
.closeDialog {
  width: 80px;
  height: 5px;
  background-color: #c4c4c4;
  border-radius: 10px;
  position: absolute;
  top: 12px;
  padding: 0;
}
.accountNumberPopup {
  width: 100%;
  max-width: 375px;
  height: 100%;
  position: relative;
  display: flex !important;
}
.accountNumberPopup > .adm-mask {
  position: fixed;
}
.accountNumberPopup > .adm-popup-body {
  height: 220px !important;
}
.accountNumberPopup .accountNumberPopup-inner {
  width: 100%;
  max-width: 375px;
  border-top: 0.1px solid #eee;
  border-left: 0.1px solid #eee;
  border-right: 0.1px solid #eee;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 100%;
  position: relative;
  padding-top: 20px;
}
.accountNumberPopup .accountNumberPopup-inner .closeDialog {
  left: 147.5px;
}
.cardDetailPopup {
  width: 100%;
  max-width: 375px;
  height: 100%;
  position: relative;
  display: flex !important;
}
.cardDetailPopup > .adm-mask {
  position: fixed;
}
.verifyPWDDialog {
  width: 100%;
  max-width: 375px;
  height: 100%;
  position: relative;
  display: flex !important;
}
.verifyPWDDialog > .adm-mask {
  position: fixed;
}
.verifyPWDDialog > .adm-dialog-wrap {
  width: 300px !important;
}
.home-grid-item {
  justify-content: center;
}
.home-grid-item img {
  width: 48px;
}
.home-grid-item div {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: 10px;
  line-height: 14px;
  color: #333333;
}
.identity-verification-banner {
  width: 100%;
  margin-top: 30px;
  padding-bottom: 50px;
}
.adm-mask {
  max-width: 375px;
  left: 50%;
  transform: translateX(-50%);
}
.activity-banner-item img {
  width: 100%;
  max-height: 88px;
}
.guide-mask {
  z-index: 1;
}
.guide-mask .guideBox {
  margin-top: 153px;
  height: 100vh;
}
.guide-mask .guideBox .guideArrow {
  width: 30%;
  margin-top: 167px;
}
.guide-mask .guideBox .guideTip {
  width: 55%;
  margin: 14px 0 24px;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.165px;
  color: #ffffff;
}
.guide-mask .guideBox .guideBtn {
  height: 40px;
  width: 60%;
  line-height: 40px;
  color: #fff;
  background: #ff3841;
  border-radius: 40px;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  flex: 1;
  color: #ffffff;
  cursor: pointer;
}
#newHome {
  position: relative;
}
#newHome .card-container {
  width: 100%;
  background: #f5f5f5;
  position: relative;
}
#newHome .card-container .tutorial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 24px;
  padding: 28px 18px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.3);
  cursor: pointer;
}
#newHome .card-container .tutorial .left {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
#newHome .card-container .tutorial .left img {
  width: 18px;
  height: 18px;
  margin-right: 15px;
  margin-top: -3px;
}
#newHome .card-container .packUp {
  width: 60px;
  height: 60px;
  display: none;
  cursor: pointer;
  box-shadow: 0px 2px 12px #cccccc;
  border-radius: 30px;
  transition: all 0.7s;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
#newHome .card-container .packUp img {
  width: 60px;
  height: 60px;
}
#newHome .card-container1 {
  height: calc(100vh - 145px);
}
#newHome .card-container1 .packUp {
  display: block;
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 15vh;
  left: calc(50% - 30px);
  cursor: pointer;
  box-shadow: 0px 2px 12px #cccccc;
  border-radius: 30px;
}
#newHome .card-container1 .packUp img {
  width: 60px;
  height: 60px;
}
#newHome .home-content {
  width: 100%;
  max-width: 375px;
  min-height: calc(100vh - 20px);
  position: fixed;
  background-color: #fff;
  top: 100vh;
  z-index: 0;
  transition: all 0.7s;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px;
}
#newHome .home-content .packDown {
  display: block;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0px;
  left: calc(50% - 18px);
  cursor: pointer;
  box-shadow: 0px 2px 12px #cccccc;
  border-radius: 18px;
}
#newHome .home-content .packDown img {
  width: 36px;
  height: 36px;
}
#newHome .home-content .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #333333;
  text-align: left;
  padding-left: 10px;
}
#newHome .home-content .title .desc {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #999999;
}
#newHome .home-content .balanceContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}
#newHome .home-content .balanceContent .balance {
  width: 166px;
  height: 124px;
  margin-right: 12px;
  box-shadow: 0px 4px 8px rgba(223, 223, 223, 0.3);
  border-radius: 12px;
  padding: 15px 10px;
  text-align: left;
}
#newHome .home-content .balanceContent .balance .top {
  font-size: 12px;
  line-height: 16px;
  color: #666666;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#newHome .home-content .balanceContent .balance .top .text {
  display: block;
  margin-right: 10px;
}
#newHome .home-content .balanceContent .balance .top .text .eyes {
  margin-left: 5px;
  cursor: pointer;
  font-size: 14px;
}
#newHome .home-content .balanceContent .balance .top .rightText {
  font-size: 12px;
  color: #ff3841;
  background: #fff5f6;
  border-radius: 2px;
  padding: 1px 3px;
}
#newHome .home-content .balanceContent .balance .bottom {
  display: flex;
  justify-content: space-between;
}
#newHome .home-content .balanceContent .balance .bottom .rightText {
  font-size: 11px;
  color: #ff3841;
  background: #fff5f6;
  border-radius: 2px;
  padding: 1px 3px;
}
#newHome .home-content .balanceContent .balance .amount {
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
  color: #000000;
  opacity: 0.9;
  display: flex;
  align-items: flex-start;
}
#newHome .home-content .balanceContent .balance .amount .dollar {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.165px;
  color: #000000;
  margin-right: 3px;
}
#newHome .home-content .balanceContent .balance .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
#newHome .home-content .balanceContent .balance .actions .action {
  width: 70px;
  height: 28px;
  background: rgba(255, 56, 65, 0.8);
  border-radius: 42px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  text-align: center;
  margin-right: 8px;
  cursor: pointer;
  padding: 0;
}
#newHome .home-content .balanceContent .balance .actions .action .adm-button-loading-wrapper {
  height: auto;
}
#newHome .home-content .balanceContent .balance .actions .action_en {
  padding: 0 3px;
  font-size: 12px;
}
#newHome .home-content .listItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
  cursor: pointer;
}
#newHome .home-content .listItem .itemLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#newHome .home-content .listItem .itemLeft img {
  width: 24px;
  height: 24px;
}
#newHome .home-content .listItem .itemLeft .titles {
  text-align: left;
  margin-left: 8px;
}
#newHome .home-content .listItem .itemLeft .titles .itemTitle {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 6px;
}
#newHome .home-content .listItem .itemLeft .titles .btm_0 {
  margin-bottom: 0px;
}
#newHome .home-content .listItem .itemLeft .titles .itemDesc {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #999999;
}
#newHome .home-content .listItem .noAlignCenter {
  align-items: flex-start;
}
#newHome .home-content .listItem .itemRight {
  display: flex;
  align-items: center;
}
#newHome .home-content .listItem .itemRight .extra {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.165px;
  color: #ff3841;
  background: #fff5f6;
  border-radius: 2px;
  padding: 1px 3px;
  margin-right: 5px;
}
#newHome .home-content .listItem .itemRight .smallSize {
  font-size: 14px;
}
#newHome .home-content .listItem .itemRight .littleSize {
  font-size: 14px;
}
#newHome .home-content .listItem .itemRight .maxSmallSize {
  font-size: 12px;
}
#newHome .home-content .rewards {
  margin: 12px 0;
}
#newHome .home-content .rewards .smallSize {
  font-size: 14px;
}
#newHome .home-content .rewards .littleSize {
  font-size: 14px;
}
#newHome .home-content .rewards .maxSmallSize {
  font-size: 13px;
}
#newHome .home-content .moreFeature .moreTitle {
  padding-left: 8px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 2px;
  color: #999999;
  margin-bottom: 10px;
}
#newHome .home-content .moreFeature .adm-divider {
  margin: 5px 0;
  color: #fafafa;
}
#newHome .home-content .rmbPay {
  margin: 0 3px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(223, 223, 223, 0.3);
  border-radius: 12px;
}
#newHome .home-content .rmbPay .red {
  color: #FF3841;
}
#newHome .home-content .rmbPay .itemTitle .titleText {
  margin-right: 5px;
}
#newHome .home-content .rmbPay .itemTitle .titleTextRight {
  color: #999999;
}
#newHome .home-content .rmbPay .itemRight {
  display: flex;
  align-items: flex-start;
}
#newHome .home-content .rmbPay .itemRight .dollor {
  font-size: 10px;
}
#newHome .home-content .rmbPay .itemRight .rmbAmount {
  font-size: 16px;
  margin-top: -2px;
  margin-left: 2px;
}
#newHome .home-content1 {
  position: absolute;
  transition: all 0.7s;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  top: 150px;
}
#newHome .home-content1 .packDown {
  top: -16px;
  left: calc(50% - 18px);
}
#newHome .common-tip-pop .common-tip-content .subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #999999;
}
#newHome .common-tip-pop .common-tip-content .center-btn {
  width: 80%;
  background: #FF3841;
  border-radius: 62px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}
