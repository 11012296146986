@keyframes cursor-blinks {
  0% {
    opacity: 1;
    display: block;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
.editTransactionPassword-success-page {
  max-width: 400px;
  margin: 0 auto;
}
.editTransactionPassword-success-page .editTransactionPassword-success {
  background: #fff;
  height: 100vh;
  padding-top: 72px;
}
.editTransactionPassword-success-page .editTransactionPassword-success .success-warp {
  font-size: 24px;
}
.editTransactionPassword-success-page .editTransactionPassword-success .success-warp .icons {
  margin: 0 auto 17px;
}
.editTransactionPassword-success-page .editTransactionPassword-success .btn-container {
  margin-top: 120px;
}
