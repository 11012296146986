.setting-password {
  background: #fff;
  height: 100vh;
  padding-top: 44px;
}
.setting-password .title-warp p {
  width: 310px;
  font-size: 26px;
  text-align: left;
  color: #000000;
  font-style: normal;
  font-weight: normal;
}
.setting-password .title-warp .sub-title {
  width: 310px;
  font-size: 12px;
  margin: 20px auto;
}
.setting-password .verification-warp {
  width: 350px;
  margin: 0 auto;
}
.setting-password .verification-warp .lista {
  display: flex;
  justify-content: space-between;
}
.setting-password .verification-warp .lista input {
  width: 32px;
  height: 42px;
  border-radius: 5px;
  background: #EFEFEF;
  margin: 46px 5px 20px;
  text-align: center;
  display: block;
}
.setting-password .varification-tips {
  width: 310px;
  display: flex;
}
.setting-password .unvla {
  color: #FF3841;
}
