#edit-address {
  background: #FAFAFA;
}
#edit-address .nav-bar {
  background-color: #FFF;
  --height: 65px;
}
#edit-address .address-form {
  margin-top: 40px;
}
#edit-address .address-bottom .is-synchronous {
  padding: 0 24px;
  margin-bottom: 35px;
}
#edit-address .address-bottom .is-synchronous .is-synchronous-tip {
  font-size: 12px;
  color: #828282;
}
#edit-address .address-bottom .is-synchronous .is-synchronous-btn {
  width: 54px;
  height: 20px;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 32px;
  font-size: 12px;
  color: #BDBDBD;
  margin-left: 8px;
}
#edit-address .address-bottom .is-synchronous .is-synchronous-btn.active {
  border-color: #1F49DA;
  color: #1F49DA;
}
#edit-address .address-bottom .update-address-section {
  padding-left: 24px;
  padding-right: 24px;
}
#edit-address .address-bottom .update-address-section .update-address {
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #BDBDBD;
  border: 1px solid #BDBDBD;
  border-radius: 40px;
  display: block;
  background-color: #FFF;
  width: 100%;
  box-sizing: border-box;
}
#edit-address .address-bottom .update-address-section .update-address.active {
  border-color: #FF3841;
  color: #FF3841;
}
