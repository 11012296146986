@keyframes blink {
  0% {
    background-color: white;
  }
  50% {
    background-color: #000000;
  }
  100% {
    background-color: white;
  }
}
@keyframes blink {
  0% {
    background-color: white;
  }
  50% {
    background-color: #000000;
  }
  100% {
    background-color: white;
  }
}
.stepOne .input-container {
  margin: 24px;
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 17px;
  font-size: 16px;
  color: #333333;
}
.stepOne .imitate-placeholder {
  color: #bbbbbb;
}
.stepOne .stepOne-bottom {
  background: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.stepOne .stepOne-bottom .next-button2 {
  width: 315px;
  height: 40px;
  background: #FF3841;
  border-radius: 40px;
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 12px;
}
.stepOne .stepOne-bottom .product-introduce {
  margin-bottom: 16px;
  font-size: 14px;
  color: #CCCCCC;
}
.stepOne .stepOne-bottom .product-introduce p:nth-of-type(1) {
  margin-bottom: 6px;
}
.stepOne .stepOne-bottom .keyboard-container {
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: bottom 0.8s;
}
.stepOne .stepOne-bottom .keyboard-container1 {
  background: #fff;
  bottom: -300px;
}
.stepOne .stepOne-bottom .keyboard-title {
  width: 100%;
  height: 26px;
}
.stepOne .stepOne-bottom .keyboard-title svg {
  width: 20px;
  height: 4px;
}
.stepOne .stepOne-bottom .keyboard {
  border-left: 1px solid #F7F7F7;
  border-top: 1px solid #F7F7F7;
  width: 100%;
}
.stepOne .stepOne-bottom .keyboard .keyboard-left {
  flex: 1;
}
.stepOne .stepOne-bottom .keyboard .keyboard-left li {
  width: 33.33%;
  height: 64px;
  text-align: center;
  color: #000000;
  font-size: 24px;
  border-bottom: 1px solid #F7F7F7;
  border-right: 1px solid #F7F7F7;
}
.stepOne .stepOne-bottom .keyboard .keyboard-left li:nth-of-type(10) {
  width: 100%;
}
.stepOne .stepOne-bottom .keyboard .keyboard-right {
  width: 96px;
}
.stepOne .stepOne-bottom .keyboard .keyboard-right .del-button {
  height: 64px;
}
.stepOne .stepOne-bottom .keyboard .keyboard-right .del-button img {
  width: 37px;
  height: 29px;
}
.stepOne .stepOne-bottom .keyboard .keyboard-right .next-button {
  height: 192px;
  background: #FF8F94;
  color: #FFFFFF;
  font-size: 18px;
}
.stepOne .stepOne-bottom .keyboard .keyboard-right .next-button1 {
  background: #FF3841;
}
.stepOne .imitate-input {
  margin: 32px 30px 48px 30px;
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 17px;
  font-size: 16px;
  color: #333333;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-size: px2rem(16);
  border: px2rem(1) solid #999999;
  user-select: text;
  -webkit-user-select: text;
}
.stepOne .imitate-input .selected::after {
  content: '';
  display: block;
  width: 1px;
  height: 16px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
.stepOne .yes::after {
  content: '';
  display: block;
  width: 1px;
  height: 16px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
.stepOne .not:empty::before {
  content: '';
  display: block;
  width: 1px;
  height: 16px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
.stepOne .not:empty::after {
  content: attr(placeholder);
  color: #999999;
  font-size: px2rem(16);
}
.ssn-input-wrapper {
  padding: 24px;
}
.ssn-input-wrapper .ssn-inner-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.ssn-input-wrapper .ssn-inner-wrapper img {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
}
.ssn-input-wrapper .ssn-inner-wrapper .ssn-input {
  width: 100%;
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 38px;
  padding-left: 20px;
  position: relative;
}
.ssn-input-wrapper .ssn-inner-wrapper .ssn-input.star input {
  font-size: 24px;
  margin-top: 10px;
}
.ssn-input-wrapper .ssn-inner-wrapper .ssn-eye {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.ssn-input-wrapper .ssn-inner-wrapper .clear-ssn-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  right: 48px;
}
.ssn-input-wrapper .ssn-inner-wrapper .adm-input-wrapper.active {
  border: 1px solid #1F49DA;
}
