@keyframes cursor-blinks {
  0% {
    opacity: 1;
    display: block;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
.editTransactionPasswordC-page {
  max-width: 400px;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;
}
.editTransactionPasswordC-page .adm-nav-bar {
  margin-top: 8px;
  padding: 0;
}
.editTransactionPasswordC {
  background: #fff;
  height: 100vh;
  padding-top: 76px;
}
.editTransactionPasswordC .title-warp {
  position: static;
  margin: 0px auto;
  width: 100%;
  font-size: 26px;
  text-align: left;
  color: #000;
}
.editTransactionPasswordC .title-warp .error-message {
  font-size: 14px;
  margin-top: 6px;
  text-align: left;
  color: #FF3841;
  width: 100%;
  height: 20px;
  line-height: 20px;
}
.editTransactionPasswordC .verification-code {
  margin-top: 32px;
  width: 100%;
}
.editTransactionPasswordC .verification-code .adm-passcode-input-cell-container {
  display: flex;
  justify-content: flex-start;
}
.editTransactionPasswordC .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #EFEFEF;
  border-radius: 4px;
  border: none;
}
.editTransactionPasswordC .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell.focused {
  box-shadow: 0 0 2px 0 #1F49DA;
}
.editTransactionPasswordC .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #FF3841;
  box-shadow: none;
}
.editTransactionPasswordC .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell.dot::before {
  background-color: #FFF;
}
.editTransactionPasswordC .varification-tips-b {
  margin-top: 20px;
  color: #999;
  text-align: left;
  width: 100%;
}
.editTransactionPasswordC .varification-tips {
  width: 310px;
  display: flex;
  justify-content: space-between;
}
.editTransactionPasswordC .unvla {
  color: #FF3841;
}
.editTransactionPasswordC .dispaly-n {
  display: none;
}
.editTransactionPasswordC .erro-tips {
  width: 310px;
  margin-bottom: 12px;
  text-align: left;
  display: block;
  color: #FF3841;
}
.editTransactionPasswordC .is-reg {
  background: #FF8F94;
  color: #fff;
}
.editTransactionPasswordC .success-warp {
  font-size: 24px;
}
.editTransactionPasswordC .success-warp .icons {
  width: 72px;
  height: 72px;
  margin: 0 auto 17px;
}
.editTransactionPasswordC .success-warp .icons img {
  width: 72px;
  height: 72px;
}
