.tradePassword-page {
  max-width: 400px;
  margin: 0 auto;
  padding-top: 12px;
}
.tradePassword {
  background: #F4F4F4;
  height: 100vh;
  padding-top: 12px;
}
.tradePassword .input-container {
  width: 355px;
  height: 108px;
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}
.tradePassword .input-container img:last-child {
  width: 5px;
  height: 10px;
  margin-left: 8px;
}
.tradePassword .input-container span {
  font-size: 14px;
  color: #161719;
}
.tradePassword .input-container .modify-password {
  height: 54px;
  margin: 0 20px;
  border-bottom: 1px solid #E7E7E7;
}
.tradePassword .input-container .reset-password {
  height: 54px;
  margin: 0 20px;
}
