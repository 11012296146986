@keyframes cursor-blinks {
  0% {
    opacity: 1;
    display: block;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
.setting-password-a-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 12px 32px;
}
.setting-password-a-page .adm-nav-bar {
  padding: 0;
}
.setting-password-a-page .setting-password-a {
  background: #fff;
  height: 100vh;
  padding-top: 10px;
}
.setting-password-a-page .setting-password-a .verification-code {
  width: 100%;
}
.setting-password-a-page .setting-password-a .verification-code .adm-passcode-input-cell-container {
  display: flex;
  justify-content: flex-start;
}
.setting-password-a-page .setting-password-a .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #EFEFEF;
  border-radius: 4px;
  border: none;
}
.setting-password-a-page .setting-password-a .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell.focused {
  box-shadow: 0 0 2px 0 #1F49DA;
}
.setting-password-a-page .setting-password-a .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #FF3841;
  box-shadow: none;
}
.setting-password-a-page .setting-password-a .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell.dot::before {
  background-color: #FFF;
}
.setting-password-a-page .setting-password-a .varification-tips {
  position: static;
  margin-top: 20px;
  color: #FF3841;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.setting-password-a .title-warp-setting-password-a {
  width: 310px;
  font-size: 12px;
  margin: 30px auto;
  color: #999;
}
.setting-password-a .title-warp-setting-password-a p {
  width: 310px;
  font-size: 26px;
  text-align: left;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 10px;
}
.setting-password-a .error-message {
  width: 100%;
  text-align: left;
  color: #FF3841;
  margin-bottom: 20px;
  height: 20px;
  line-height: 20px;
}
.setting-password-a .dispaly-n {
  display: none;
}
.setting-password-a .erro-tips {
  width: 310px;
  margin-bottom: 12px;
  text-align: left;
  display: block;
  color: #FF3841;
}
.setting-password-a .is-reg {
  background: #FF8F94;
  color: #fff;
}
.sub-title-setting-password-a {
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
