#verify-bank .title {
  margin-top: 48px;
  font-size: 24px;
  color: #1F49DA;
}
#verify-bank .bank-info {
  margin: 64px 30px 39px;
  text-align: left;
}
#verify-bank .bank-info label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
#verify-bank .bank-info .bank-nickname {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}
#verify-bank .amount-info {
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
}
#verify-bank .amount-info .amount-label {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
#verify-bank .amount-info svg {
  margin-left: 8px;
  margin-right: 5px;
}
#verify-bank .amount-info .amount-faq {
  font-size: 12px;
  color: #999;
}
#verify-bank .amount-info .amount-input {
  border-bottom: 1px solid #E2E2E2;
  padding: 24px 0 16px;
  position: relative;
}
#verify-bank .amount-info .amount-input::before {
  content: '$ 0.';
  position: absolute;
  top: 26px;
  left: 0;
  font-size: 16px;
  color: #333;
}
#verify-bank .amount-info .amount-input .adm-input {
  text-indent: 28px;
}
#verify-bank button {
  width: 327px;
  height: 40px;
  line-height: inherit;
  background-color: #FF3841;
  border-radius: 40px;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  bottom: 40px;
  margin-left: -163.5px;
}
#error-pop {
  background-color: #FFFFFF;
  border-radius: 10px;
  width: 294px;
  display: flex;
  flex-direction: column;
  padding: 24px 22px 38px;
  align-items: center;
}
#error-pop .content {
  margin-top: 14px;
  margin-bottom: 23px;
  font-weight: 500;
  font-size: 20px;
  color: #161719;
}
#error-pop button {
  position: static;
  margin: 0 auto;
  background-color: #FF3841;
  border-radius: 40px;
  font-size: 16px;
  color: #FFFFFF;
  width: 240px;
  height: 40px;
}
