.setting {
  background: #F4F4F4;
  height: 100vh;
  padding-top: 12px;
}
.setting .common-container {
  height: 54px;
  padding: 0 20px;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin: 10px 10px 12px 10px;
}
.setting .common-container .pointer {
  cursor: pointer;
}
.setting .common-container img:last-child {
  width: 5px;
  height: 10px;
  margin-left: 8px;
}
.setting .common-container span {
  font-size: 12px;
  color: #BFBFBF;
}
.setting .common-container .label {
  font-size: 14px;
  color: #161719;
}
.setting .manage-container {
  height: 108px;
}
.setting .manage-container .account {
  height: 54px;
  box-sizing: border-box;
}
.setting .manage-container .address {
  height: 54px;
  box-sizing: border-box;
  border-top: 0.5px solid #E7E7E7;
}
#personInfo {
  background: #fafafa;
}
#personInfo .adm-nav-bar {
  background: #FFFFFF;
  padding: 25px 12px;
}
#personInfo .mrg_12 {
  margin-top: 12px;
}
#personInfo .module-list {
  padding: 5px 24px;
  background: #fff;
  text-align: left;
}
#personInfo .module-list .list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
  cursor: pointer;
  width: 100%;
  max-width: 375px;
}
#personInfo .module-list .list-item .item-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
#personInfo .module-list .list-item .item-left img {
  width: 16px;
}
#personInfo .module-list .list-item .item-left p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #161719;
}
#personInfo .module-list .list-item .item-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: #999;
  width: 70%;
  text-align: right;
}
#personInfo .module-list .list-item .item-right .item-avatar {
  width: 35px;
  height: 35px;
  border-radius: 100%;
}
#personInfo .module-list .list-item .item-right .item-avatar img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
}
#personInfo .module-list .list-item .item-right p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  margin-right: 8px;
  color: #999999;
}
#personInfo .module-list .list-item .item-right .langName {
  width: 70%;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0;
}
