.activateAlert_dialog {
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 300;
  position: fixed;
  max-width: 375px;
}
.activateAlert_dialog .dialog__alert {
  width: 320px;
  height: 160px;
  background: #FFFFFF;
  border-radius: 12px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 200px;
  border: 1px solid #efefef;
  z-index: 10000002;
  position: absolute;
  text-align: center;
}
.activateAlert_dialog .dialog__content {
  position: relative;
}
.activateAlert_dialog .dialog__content .title {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: #000000;
  margin: 26px 0 10px 0;
}
.activateAlert_dialog .dialog__content .sub-title {
  font-size: 14px;
  text-align: center;
  color: #333333;
}
.activateAlert_dialog .dialog_bottom__btns {
  padding: 20px;
}
.activateAlert_dialog .dialog_bottom__btns .confirm_btn {
  width: 120px;
  font-size: 16px;
  color: #FFFFFF;
  background: #8F8E94;
  border-radius: 40px;
}
.activateAlert_dialog .dialog_bottom__btns .cancel_btn {
  width: 120px;
  font-size: 16px;
  color: #FFFFFF;
  background: #FF3841;
  border-radius: 40px;
}
