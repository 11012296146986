.list-item {
  display: flex;
  padding: 15px 20px;
}
.list-item .icon img {
  width: 20px;
}
.list-item .transaction-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.165px;
  color: #333333;
  flex: 1;
}
.list-item .transaction-title .top {
  display: flex;
  text-align: left;
}
.list-item .transaction-title .top .left {
  text-align: left;
  margin-left: 8px;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  display: flex;
}
.list-item .transaction-title .top .left .title-txt {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-item .transaction-title .top .left img {
  width: 77px;
  margin-left: 8px;
}
.list-item .transaction-title .top .left .instant-tip {
  background: #EAEFFF;
  border-radius: 18px;
  margin-left: 8px;
  justify-content: center;
  text-align: center;
  padding: 2px 6px;
}
.list-item .transaction-title .top .left .instant-tip img {
  width: 10px;
  margin-left: 0;
}
.list-item .transaction-title .top .left .instant-tip .instant-text {
  font-size: 14px;
  color: #1F49DA;
  margin-left: 3px;
}
.list-item .transaction-title .top .left .instant-tip.back {
  background: #FFF9F1;
}
.list-item .transaction-title .top .left .instant-tip.back .instant-text {
  color: #F9941E;
}
.list-item .transaction-title .top .right {
  flex: 1;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.list-item .transaction-title .bottom {
  display: flex;
  text-align: left;
}
.list-item .transaction-title .bottom .left {
  text-align: left;
  margin-left: 8px;
  font-size: 12px;
  line-height: 30px;
  color: #999999;
}
.list-item .transaction-title .bottom .right {
  flex: 1;
  font-size: 12px;
  line-height: 30px;
  text-align: right;
  color: #828282;
}
.list-item .transaction-title .bottom .right .tip-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #FF3841;
}
.list-item .transaction-title .bottom .right .tip-grey-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #333333;
}
.list-item .transaction-title .fee-tips {
  background: #FFF6EB;
  border-radius: 17px;
  font-size: 12px;
  text-align: right;
  color: #FFA338;
  padding: 2px 4px;
  margin-left: 8px;
}
.list-item .transaction-title .fee {
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  text-decoration-line: line-through;
  color: #333333;
}
.list-item .transaction-title .status-tips {
  padding: 2px 4px;
  font-weight: 500;
  font-size: 10px;
  text-align: right;
  color: #FFB649;
  border: 0.5px solid #FFB649;
  border-radius: 37px;
  margin-right: 8px;
}
.list-item .transaction-title .origin-fee {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  color: #333333;
}
.list-item .timestamp {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.165px;
  color: #999999;
}
.list-item .item-tips {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: -0.165px;
  color: #999999;
}
