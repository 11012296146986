.mine {
  position: relative;
  background: #f4f4f4;
  max-width: 100%;
}
.mine .authen::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 45px;
  right: 20px;
  height: 1px;
  background: #e7e7e7;
}
.mine .safe::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 45px;
  right: 20px;
  height: 1px;
  background: #e7e7e7;
}
.mine .mine-top {
  width: 100%;
  background: #ffffff;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}
.mine .mine-top .close {
  position: absolute;
  right: 10px;
  top: 10px;
}
.mine .mine-top .close svg {
  width: 24px;
  height: 24px;
}
.mine .mine-top .visa-newcard-wrapper {
  margin: 10px auto 10px;
}
.mine .card-container {
  width: 374px;
  background: #ffffff;
}
.mine .active-btn {
  width: 153px;
  height: 36px;
  background: #ffffff;
  opacity: 0.5;
  border: 1px solid #000000;
  border-radius: 23px;
  margin: 24px auto 24px;
  font-size: 12px;
  line-height: 12px;
}
.mine .main-content {
  position: absolute;
  top: 360px;
  z-index: 100;
  transition: all 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background: #f4f4f4;
}
.mine .main-content1 {
  top: 120px;
}
.mine .userInfo {
  width: 375px;
  height: 90px;
  position: relative;
}
.mine .userInfo .packUp {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: -32px;
  transform: translateX(-50%);
}
.mine .userInfo .userDetail {
  width: 375px;
  height: 90px;
  background: #f4f4f4;
  padding-top: 20px;
}
.mine .userInfo .userDetail span {
  font-weight: 500;
  font-size: 18px;
}
.mine .userInfo .userDetail img {
  width: 20px;
  height: 20px;
  margin-left: 7px;
}
.mine .userInfo .userDetail1 {
  background: #ffffff;
  border-bottom: 12px solid #f4f4f4;
  border-left: 0.1px solid #eee;
  border-right: 0.1px solid #eee;
}
.mine .userInfo1 {
  background: #ffffff;
}
.mine .activate-container {
  background: #f4f4f4;
  width: 100%;
}
.mine .activate-container .activate-tip {
  width: 353px;
  height: 48px;
  background: #fff4f5;
  border-radius: 6px;
  margin: 0px 0 12px 0;
  padding: 0 10px;
}
.mine .activate-container .activate-tip svg {
  width: 12px;
  height: 12px;
  margin-top: 6px;
}
.mine .activate-container .activate-tip span {
  font-size: 12px;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.mine .activate-container .activate-tip .btn {
  width: 64px;
  height: 22px;
  background: #ff3841;
  border-radius: 20px;
  font-size: 12px;
  color: #ffffff;
}
.mine .activate-container .tip-btn-text {
  width: 64px;
  height: 22px;
  left: 289px;
  top: 295.5px;
  background: #ff3841;
  border-radius: 20px;
  color: #fff;
}
.mine .set-container {
  width: calc(100% - 24px);
  min-height: 108px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin: 12px;
}
.mine .client-container {
  width: calc(100% - 24px);
  height: 54px;
  padding: 0 20px;
  margin-top: 0px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}
.mine .common-container {
  height: 54px;
  padding: 0 20px;
  position: relative;
}
.mine .common-container img:nth-of-type(1) {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.mine .common-container img:last-child {
  width: 5px;
  height: 10px;
}
.mine .common-container .tip-text {
  font-size: 14px;
  color: #000000;
  margin-left: 12px;
}
.mine .btn-container .warning-btn {
  height: 40px;
  font-size: 16px;
}
.mine .btn-wrapper {
  width: 334px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.mine .btn-wrapper .add-btn {
  background: #f5f5f5;
  width: 156px;
  height: 40px;
  border-radius: 40px;
  border: 0 !important;
  font-size: 14px;
  margin-top: 20px;
}
.mine .btn-wrapper .cash-btn {
  background: #f5f5f5;
  width: 156px;
  height: 40px;
  border-radius: 40px;
  border: 0 !important;
  font-size: 14px;
  margin-top: 20px;
}
.mine .my-order {
  background-color: #fff;
  width: 355px;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0px 1px 1px 0px #0000000f;
}
.mine .my-order p {
  display: flex;
  justify-content: space-between;
  font-family: " Noto Sans";
  font-size: 14px;
  font-weight: 500;
}
.mine .my-order p i {
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #f0f4ff;
  font-family: " Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #4460c0;
}
.mine .my-order .order {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.mine .my-order .order .order-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: #999;
  cursor: pointer;
}
.mine .my-order .order .order-item img {
  width: 32px;
  height: 32px;
}
.mine .my-order .order .order-item span {
  margin-top: 5px;
}
.rz-tip {
  width: 1.72rem !important;
  height: 0.48rem !important;
  margin-left: 8px;
}
.verifyPWDDialogMine {
  width: 100%;
  max-width: 375px;
  height: 100%;
  position: relative;
  display: flex !important;
}
.verifyPWDDialogMine > .adm-dialog-wrap {
  width: 300px !important;
}
#personal-center .service-agreement {
  margin-top: 12px;
  cursor: pointer;
}
#personal-center .btn-container {
  width: 100%;
  margin-top: 40px;
  padding-bottom: 20px;
}
#personal-center .btn-container button {
  width: calc(100% - 24px);
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  color: #161719;
  height: 54px;
  line-height: 54px;
}
#mine-page {
  background-color: #fafafa;
  padding-bottom: 50px;
}
#mine-page .mine-top {
  width: 100%;
  background: #ffffff;
  position: relative;
  padding-top: 30px;
  padding-bottom: 22px;
  margin-bottom: 12px;
}
#mine-page .mine-top .close {
  position: absolute;
  right: 10px;
  top: 10px;
}
#mine-page .mine-top .close svg {
  width: 24px;
  height: 24px;
}
#mine-page .mine-top .visa-newcard-wrapper {
  margin: 10px auto 10px;
}
#mine-page .mine-top .mine-top-avatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
  position: relative;
}
#mine-page .mine-top .mine-top-avatar img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
#mine-page .mine-top .mine-top-avatar .camera {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background: #000;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  position: absolute;
  right: 0;
  bottom: 0;
}
#mine-page .mine-top .light-height {
  line-height: 100px;
  background: #fff5f6;
  box-shadow: 0px 0px 18.75px rgba(255, 228, 228, 0.25);
}
#mine-page .mine-top .userDetail {
  width: 375px;
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  margin-bottom: 10px;
}
#mine-page .mine-top .userDetail span {
  font-weight: 500;
  font-size: 24px;
}
#mine-page .mine-top .userDetail img {
  width: 20px;
  height: 20px;
  margin-left: 7px;
}
#mine-page .mine-top .nick-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 70%;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#mine-page .mine-top .edit-info {
  width: 106px;
  height: 32px;
  background: #fff5f6;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  border: none;
}
#mine-page .share-item {
  margin: 12px;
  background: #ffffff;
  border-radius: 6px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#mine-page .share-item .share-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}
#mine-page .share-item .share-left .share-title {
  margin-left: 12px;
}
#mine-page .share-item .share-left .share-icon {
  width: 32px;
  height: 32px;
  font-size: 22px;
  background: #fff5f6;
  border-radius: 32px;
  line-height: 32px;
}
#mine-page .share-item .share-button {
  width: 78px;
  height: 32px;
  background: #ff3841;
  border-radius: 100px;
  border: none;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
}
#mine-page .my-order {
  background-color: #fff;
  margin: 0 12px;
  border-radius: 6px;
  padding: 0 5px;
  box-shadow: 0px 1px 1px 0px #0000000f;
}
#mine-page .my-order p {
  display: flex;
  justify-content: space-between;
  font-family: " Noto Sans";
  font-size: 14px;
  font-weight: 500;
}
#mine-page .my-order p i {
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #f0f4ff;
  font-family: " Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #4460c0;
}
#mine-page .my-order .order {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#mine-page .my-order .order .order-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: #999;
  margin: 0;
  cursor: pointer;
}
#mine-page .my-order .order .order-item img {
  width: 32px;
  height: 32px;
}
#mine-page .my-order .order .order-item span {
  margin-top: 5px;
}
#mine-page .my-order .order .order-item .order-title {
  padding-right: 12px;
  border-right: 1px solid #f4f4f4;
  color: #000000;
}
#mine-page .my-order .order .order-item-first {
  padding-left: 0;
  padding-right: 0;
}
#mine-page .module-title {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #999999;
  text-align: left;
  padding: 20px 20px 0;
}
#mine-page .module-list {
  margin-top: 12px;
  padding: 10px;
  background: #fff;
  text-align: left;
}
#mine-page .module-list .list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
}
#mine-page .module-list .list-item .item-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#mine-page .module-list .list-item .item-left img {
  width: 16px;
}
#mine-page .module-list .list-item .item-left p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #161719;
  margin-left: 12px;
}
#mine-page .module-list .list-item .item-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #999;
}
#mine-page .module-list .list-item .item-right p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  margin-right: 8px;
  color: #999999;
}
#mine-page .module-list .opacity-6 .item-left p {
  color: #999;
}
#mine-page .module-list .opacity-6 .item-left img {
  opacity: 0.5;
}
#mine-page .btn-container {
  width: 100%;
  margin-top: 40px;
  padding-bottom: 10px;
}
#mine-page .btn-container button {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 0px;
  font-size: 14px;
  text-align: center;
  color: #ff3841;
  height: 54px;
  line-height: 54px;
  margin-top: 0;
}
#mine-page .btn-container .versions {
  margin-top: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #999999;
}
