#refund {
  background-color: #fafafa;
}
#refund .nav-bar {
  background-color: #fff;
}
#refund .refund-content {
  background: #fff;
  margin-top: 12px;
  padding: 20px 24px;
}
#refund .refund-content .flow {
  background: #F2F4FF;
  height: 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1F49DA;
  padding: 10px 0;
}
#refund .refund-content .list {
  margin-top: 20px;
}
#refund .refund-content .list .item {
  border: 1px solid #E1E2EC;
  border-radius: 28px;
  font-style: normal;
  font-weight: 400;
  font-size: 12.8736px;
  line-height: 28px;
  height: 28px;
  letter-spacing: -0.151724px;
  color: #999999;
  margin-top: 12px;
}
#refund .refund-content .list .item.enabled {
  background: #FFFAFA;
  border: 1px solid #FF3841;
  color: #FF3841;
}
#refund .refund-content .submit {
  width: 100%;
  background: #FF8F94;
  border-radius: 40px;
  height: 40px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  margin-top: 60px;
}
#refund .refund-content .submit.enabled {
  background: #FF3841;
}
#refund .refund-content .comments {
  margin-top: 24px;
}
#refund .refund-content .comments .comments-text {
  margin-top: 14px;
  border: 1px solid #E1E2EC;
  border-radius: 12px;
  padding: 12px;
}
.refund-title {
  line-height: 28px;
  height: 28px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.151724px;
  color: #666666;
  text-align: left;
  margin: 0;
}
.refund-title::after {
  content: '*';
  align-items: top;
  color: #FF3841;
}
