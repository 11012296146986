#account-page {
  background: #fafafa;
  /*padding-top: 19.1px;*/
  height: unset !important;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 24px;
  position: relative;
  top: -12px;
}
#account-page .topNotice {
  margin-top: -12px;
  width: 100%;
}
#account-page .adm-modal .adm-modal-wrap .adm-modal-footer {
  padding-bottom: 0;
}
#account-page .calculator {
  display: flex;
  flex-wrap: wrap;
}
#account-page .calculator li {
  width: 33.33%;
  text-align: center;
  font-size: 28px;
  color: #333333;
  height: 80px;
  line-height: 80px;
  font-weight: 600;
  font-size: 23px;
}
#account-page .calculator li img {
  width: 38px;
  height: 28px;
}
#account-page .btn-wrapper {
  width: 334px;
  margin: 0 auto;
  padding-bottom: 20px;
}
#account-page .btn-wrapper .add-btn,
#account-page .btn-wrapper .cash-btn {
  background: #FF8F94;
  width: 156px;
  height: 40px;
  border-radius: 40px;
  border: 0 !important;
  font-size: 14px;
  margin-top: 20px;
  color: #ffffff;
}
#account-page .btn-wrapper .add-btn1,
#account-page .btn-wrapper .cash-btn1 {
  background: #FF3841;
}
#account-page .input {
  font-size: 36px;
  text-align: center;
  margin: 20px 0 32px 0;
  font-weight: 600;
  position: relative;
}
#account-page .input .grayNumber {
  color: #EBEBEB;
}
.overlayContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: white;
  border-radius: 16px;
}
/* .mask{
  /* width: 100vw;
  height: 100vh; */
/* background-color: rgba(56, 56, 56, 0.75); */
/* position: absolute;
  top: 0;
  left: 0; */
.mask-center-A {
  width: 280px;
}
.mask-top {
  width: 90%;
  height: 60%;
  /* margin: 0 auto; */
}
.mask-top .qrcode {
  margin-top: 20px;
  width: 196px;
  height: 183px;
}
.mask-top h3 {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  color: #FF3841;
}
.mask-center-A .mask-top .shrinkage {
  letter-spacing: -0.161554px;
}
.mask-center-A .mask-top .addName {
  letter-spacing: -0.161554px;
}
.mask-top p {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding-left: 5px;
}
.mask-center-A .mask-bootom {
  width: 90%;
  margin: 0 auto;
  margin-top: 25px;
}
.mask-bootom span {
  display: inline-block;
  width: 120px;
  height: 40px;
  border-radius: 35px;
  border: 1px solid #FF3841;
  text-align: center;
  font-size: 15px;
  line-height: 40px;
}
.mask-left {
  margin-right: 5px;
  color: red;
}
.mask-right {
  background-color: #FF3841;
  color: #fff;
}
.adm-modal-wrap {
  min-height: 164px;
}
.mask-center-A .mask-bootoms {
  width: 90%;
  margin: 0 auto;
  margin-top: 8px;
}
.mask-bootoms span {
  display: inline-block;
  width: 120px;
  height: 40px;
  border-radius: 35px;
  border: 1px solid #FF3841;
  text-align: center;
  font-size: 15px;
  line-height: 40px;
}
.mask-left {
  margin-right: 5px;
  color: red;
}
.mask-right {
  background-color: #FF3841;
  color: #fff;
}
