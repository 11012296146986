#update-avatar-container {
  background-color: #fafafa;
}
#update-avatar-container .adm-nav-bar {
  background-color: #FFF;
}
#update-avatar-container .image-uploader-box {
  position: relative;
}
#update-avatar-container .image-uploader-box .image-uploader {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  left: 50%;
  margin-left: -150px;
}
#update-avatar-container .image-uploader-box .image-uploader.opacity {
  opacity: 0;
}
#update-avatar-container .image-uploader-box .image-uploader .adm-space,
#update-avatar-container .image-uploader-box .image-uploader .adm-image-uploader-upload-button,
#update-avatar-container .image-uploader-box .image-uploader .adm-space-item,
#update-avatar-container .image-uploader-box .image-uploader .adm-image-uploader-upload-button-wrap {
  width: 100%;
  height: 100%;
}
#update-avatar-container .image-uploader-box .image-uploader .adm-space .adm-image-uploader-cell,
#update-avatar-container .image-uploader-box .image-uploader .adm-image-uploader-upload-button .adm-image-uploader-cell,
#update-avatar-container .image-uploader-box .image-uploader .adm-space-item .adm-image-uploader-cell,
#update-avatar-container .image-uploader-box .image-uploader .adm-image-uploader-upload-button-wrap .adm-image-uploader-cell {
  border-radius: 50%;
}
#update-avatar-container .image-uploader-box .image-uploader .adm-space .adm-image-uploader-cell .adm-image-uploader-cell-delete,
#update-avatar-container .image-uploader-box .image-uploader .adm-image-uploader-upload-button .adm-image-uploader-cell .adm-image-uploader-cell-delete,
#update-avatar-container .image-uploader-box .image-uploader .adm-space-item .adm-image-uploader-cell .adm-image-uploader-cell-delete,
#update-avatar-container .image-uploader-box .image-uploader .adm-image-uploader-upload-button-wrap .adm-image-uploader-cell .adm-image-uploader-cell-delete {
  width: 300px;
  height: 300px;
}
#update-avatar-container .image-uploader {
  margin-top: 24px;
}
#update-avatar-container .default-avatar {
  margin-top: 24px;
  background-color: #F3F3F3;
  width: 300px;
  height: 300px;
}
#update-avatar-container .default-avatar .avatar-text {
  line-height: 300px;
  background: #FF3A43;
  box-shadow: 0px 0px 18.75px rgba(255, 228, 228, 0.25);
  border-radius: 50%;
  color: #FFF;
  font-size: 135px;
  font-weight: 700;
}
#update-avatar-container .avatar {
  width: 300px;
  height: 300px;
}
#update-avatar-container .upload-avatar {
  margin-top: 32px;
  background-color: #FF3841;
  border-radius: 40px;
  font-size: 16px;
  color: #FFF;
  width: 315px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.cropper-mask {
  height: 100vh;
}
.cropper-mask .action {
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: space-between;
  padding: 24px 12px;
  width: 100%;
}
.cropper-mask .action .action-btn {
  border: none;
  color: #FFF;
  background-color: transparent;
}
