#recharge-page {
  background: #f7f7f7;
  position: relative;
}
#recharge-page .topNotice {
  position: inherit;
  z-index: 0;
}
#recharge-page .wrap {
  padding: 12px 24px;
  position: relative;
}
#recharge-page .wrap .tutorial {
  position: absolute;
  top: 46px;
  right: 16px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  background: #FFF9FA;
  border: 1px solid #FFEAEB;
  border-radius: 38px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FF3841;
}
#recharge-page .wrap .tutorial .tutorial-title {
  margin-left: 3px;
}
#recharge-page .bank-list {
  width: 100%;
}
#recharge-page .bank-tip {
  margin: 0px 0 10px;
  color: #222;
  font-size: 14px;
  text-align: left;
}
#recharge-page .bank-info {
  display: flex;
}
#recharge-page .logo {
  width: 40px;
  height: 40px;
}
#recharge-page .logo img {
  width: 40px;
}
#recharge-page .bank-name {
  padding-left: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
}
#recharge-page .bank-name .one-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.165px;
  color: #222222;
}
#recharge-page .bank-name .font14 {
  font-size: 14px;
}
#recharge-page .bank-name .two-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 12px;
  letter-spacing: -0.165px;
  color: #999999;
}
#recharge-page .bank-names {
  width: fit-content;
  color: #222;
  font-size: 16px;
}
#recharge-page .bank-detail {
  color: #999;
  font-size: 12px;
  width: 100%;
  white-space: normal;
}
#recharge-page .bank-account-status {
  width: fit-content;
  line-height: 14px;
  font-size: 10px;
  padding: 0 4px;
  border-radius: 7px;
  margin-left: 8px;
}
#recharge-page .bank-account-status-2 {
  background: #F9efe3;
  color: #F9941E;
}
#recharge-page .bank-account-status-3 {
  background: #FFE3E5;
  color: #FF3841;
}
#recharge-page .rightIcon {
  width: 5px;
}
#recharge-page .amount-input {
  padding: 8px 0px 0;
  background: #fff;
  margin-top: 10px;
  border-radius: 12px;
}
#recharge-page .amount-input .amount {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #222;
  letter-spacing: -0.165px;
  margin-top: 0px;
  color: #222222;
}
#recharge-page .amount-input .rule {
  color: #1F49DA;
  background: #EDF1FF;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
}
#recharge-page .amount-input .unit {
  font-size: 24px;
  margin-right: 10px;
}
#recharge-page .amount-input .title-box {
  padding: 0 10px;
}
#recharge-page .amount-input .input-box {
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
  padding-bottom: 10px;
  position: relative;
  border-bottom: 1px solid #F3F3F3;
}
#recharge-page .amount-input .input-box .box-clear {
  width: 24px;
  height: 24px;
  border: 1px dashed #999;
}
#recharge-page .input-tip {
  margin-top: 12px;
}
#recharge-page .input-tip span:nth-child(1) {
  color: #999;
  font-size: 14px;
}
#recharge-page .input-tip span:last-child {
  color: #1F49DA;
  font-size: 14px;
}
#recharge-page .under-tip {
  height: 40px;
  line-height: 40px;
  text-align: left;
  font-size: 12px;
  padding: 0 12px;
}
#recharge-page .under-tip .hint {
  color: #9999;
}
#recharge-page .under-tip .hint .free-tip {
  color: #1F49DA;
}
#recharge-page .under-tip .warning {
  color: #FF3841;
  white-space: nowrap;
}
#recharge-page .under-tip-en {
  line-height: 20px;
}
#recharge-page .autoEnterIn {
  background: #FFF3F4;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 12px 15px;
  text-align: left;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
}
#recharge-page .autoEnterIn p:nth-of-type(1) {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #FF3841;
  margin-bottom: 6px;
}
#recharge-page .autoEnterIn p:nth-of-type(2) {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
}
#recharge-page .limit-tip {
  padding-left: 15px;
  margin-top: 16px;
  color: #FF3841;
  font-size: 12px;
}
#recharge-page .btn-container {
  margin-top: 50px;
}
#recharge-page .disabled {
  opacity: 0.6;
}
#recharge-page .color-tip {
  color: #999;
}
#recharge-page .red {
  color: #FF3841 !important;
}
#recharge-page .fav-title {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #999;
}
#recharge-page .amount-size {
  text-align: center;
  color: #161719;
  font-weight: 700;
  font-size: 40px;
  margin: 20px 0 25px;
}
#recharge-page .fav-text {
  color: #999;
  font-size: 16px;
}
#recharge-page .fav-num {
  text-decoration: line-through;
  color: #FF3841;
  font-size: 16px;
}
#recharge-page .fav-tip {
  color: #FFA338;
  font-size: 0.32rem;
  background: #FEF6EB;
  margin: 13px 0 10px 0;
  padding: 5px;
  border-radius: 5px;
  position: relative;
}
#recharge-page .fav-tip::before {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #FEF6EB;
  content: " ";
  position: absolute;
  top: -16px;
}
#recharge-page .edit-box {
  margin-top: 50px;
}
#recharge-page .inputBg {
  background: #EFEFEF;
  margin-right: 5px;
  height: 48px;
  border-radius: 5px;
}
#recharge-page .inputBg input {
  text-align: center;
}
#recharge-page .inputBg:last-child {
  margin-right: 0;
}
#recharge-page .cancelBtn {
  position: absolute;
  top: -50px;
  right: 0;
  border-right: 0 !important;
  color: #fff;
  font-size: 25px !important;
  width: auto;
  padding: 0 !important;
}
#recharge-page .confirmBtn {
  width: 80%;
  flex: initial !important;
  margin: 30px auto;
  background: #FF3841;
  color: #fff;
  line-height: initial !important;
  padding: 0 !important;
  height: 40px;
  border-radius: 40px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
#recharge-page .fav-dialog .adm-dialog-main .adm-dialog-footer .adm-dialog-action-row {
  border-top: 0;
}
#recharge-page .fav-dialog .repeat-tip {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
#recharge-page .fav-dialog .cancel-recharge {
  border: 1px solid #FF3841 !important;
  box-sizing: border-box;
  border-radius: 40px !important;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  text-align: center;
  color: #FF3841;
  max-width: 120px;
  height: 40px;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 10px 8px 35px 15px;
}
#recharge-page .fav-dialog .confirm-recharge {
  background: #FF3841;
  border-radius: 40px !important;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  text-align: center;
  color: #fff;
  max-width: 120px;
  height: 40px;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 10px 15px 35px 8px;
}
#recharge-page .align-center {
  text-align: center;
}
#recharge-page .imitate-input {
  height: 44px;
  font-size: 16px;
  color: #333333;
  display: flex;
  align-items: center;
  user-select: text;
  -webkit-user-select: text;
  font-size: 28px !important;
}
#recharge-page .imitate-input::after {
  content: '';
  display: block;
  width: 1px;
  height: 28px;
  margin-left: 2px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
#recharge-page .colorRed {
  color: red;
}
#recharge-page .hidd {
  width: 5px;
  height: 13px;
  margin-left: auto;
}
#recharge-page .hidd img {
  width: 5px;
  height: 10px;
}
#recharge-page .waiting-list {
  background: rgba(250, 158, 15, 0.1);
  border-radius: 23px;
  color: #F9941E;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  margin-top: 6px;
  margin-bottom: 20px;
  margin-left: 48px;
}
#recharge-page .waiting-list .waiting-list-txt {
  display: inline-block;
  width: 200px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#recharge-page .hints {
  position: relative;
  margin-top: 12px;
  padding: 6px 36px 6px 8px;
  color: #DD8A3C;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  background: #FFF5F1;
  border-radius: 5px;
}
#recharge-page .hints::after {
  content: '';
  position: absolute;
  right: 42px;
  bottom: -16px;
  width: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 8px solid transparent;
  border-top: 8px solid #FFF5F1;
}
#recharge-page .tishi {
  width: 10px;
  height: 10px;
  vertical-align: baseline;
}
#recharge-page .quanshan {
  position: absolute;
  right: 4px;
  top: 9px;
  width: 12px;
  height: 12px;
}
#recharge-page .del {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 20%;
  color: #999;
}
