.stepFour {
  height: 100%;
  padding-bottom: 60px;
}
.document-type {
  margin: 24px;
}
.document-type .type-title {
  font-size: 18px;
  color: #000000;
}
.document-type ul li {
  width: 100%;
  height: 2rem;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 12px;
  display: flex;
  align-items: center;
}
.document-type ul li img {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
}
.document-type .typeText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 0.5rem;
}
.document-type .typeText .typeText-left {
  color: #000000;
}
.document-type .typeText .typeText-right {
  color: #999999;
}
.typeText .typeText-left {
  font-size: 12px;
}
.typeText .typeText-right {
  font-size: 12px;
}
.step-bottom {
  position: fixed;
  height: 60px;
  padding-top: 16px;
  bottom: 0;
  text-align: center;
  width: 100%;
  filter: drop-shadow(0px -4px 4px rgba(133, 133, 133, 0.05));
  background-color: #ffffff;
  font-size: 14px;
  color: #CCCCCC;
  max-width: 375px;
}
