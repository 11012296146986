#instant-deposit-notice {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding-top: 21px;
  padding-bottom: 36px;
  max-width: 375px;
}
#instant-deposit-notice .title {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #1F49DA;
  margin-top: 36px;
}
#instant-deposit-notice .content {
  margin-top: 12px;
  color: #828282;
  font-size: 14px;
  line-height: 28px;
}
