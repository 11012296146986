#withdraw {
  background: #f7f7f7;
  height: 100vh;
  position: relative;
}
#withdraw .wrap {
  padding: 12px 24px;
  position: relative;
}
#withdraw .wrap .tutorial {
  position: absolute;
  top: 46px;
  right: 16px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  background: #FFF9FA;
  border: 1px solid #FFEAEB;
  border-radius: 38px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FF3841;
}
#withdraw .wrap .tutorial .tutorial-title {
  margin-left: 3px;
}
#withdraw .bank-tip {
  margin: 0px 0 20px;
  color: #222;
  font-size: 14px;
  text-align: left;
}
#withdraw .bank-info {
  display: flex;
}
#withdraw .logo {
  width: 40px;
  height: 40px;
}
#withdraw .logo img {
  width: 100%;
}
#withdraw .bank-name {
  padding-left: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
}
#withdraw .bank-name .one-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.165px;
  color: #222222;
}
#withdraw .bank-name .font14 {
  font-size: 14px;
}
#withdraw .bank-name .two-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-size: 12px;
  letter-spacing: -0.165px;
  color: #999999;
}
#withdraw .bank-names {
  width: fit-content;
  color: #222;
  font-size: 16px;
}
#withdraw .bank-detail {
  color: #999;
  font-size: 12px;
  width: 100%;
  white-space: normal;
}
#withdraw .bank-account-status {
  width: fit-content;
  line-height: 14px;
  font-size: 10px;
  padding: 0 4px;
  border-radius: 7px;
  margin-left: 8px;
}
#withdraw .bank-account-status-2 {
  background: #F9efe3;
  color: #F9941E;
}
#withdraw .bank-account-status-3 {
  background: #FFE3E5;
  color: #FF3841;
}
#withdraw .rightIcon {
  width: 5px;
  margin-left: auto;
}
#withdraw .amount-input {
  padding: 12px 12px;
  background: #fff;
  margin-top: 24px;
  border-radius: 12px;
  min-height: 150px;
}
#withdraw .amount-input .amount {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #222;
  letter-spacing: -0.165px;
  margin-top: 0px;
  color: #222222;
}
#withdraw .amount-input .rule {
  color: #1F49DA;
  background: #EDF1FF;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
}
#withdraw .amount-input .unit {
  font-size: 24px;
  margin-right: 10px;
}
#withdraw .amount-input .input-box {
  margin-top: 18px;
  padding-bottom: 10px;
  position: relative;
  border-bottom: 1px solid #F3F3F3;
}
#withdraw .amount-input .input-box .box-clear {
  width: 24px;
  height: 24px;
  border: 1px dashed #999;
}
#withdraw .input-tip {
  text-align: left;
  margin-top: 12px;
}
#withdraw .input-tip span.tip-before {
  color: #999;
  font-size: 14px;
}
#withdraw .input-tip span:last-child {
  color: #1F49DA;
  font-size: 14px;
}
#withdraw .limit-tip {
  text-align: left;
  margin-top: 16px;
  padding-left: 12px;
  color: #828282;
  font-size: 12px;
}
#withdraw .limit-tip .fee-tip {
  color: #FF3841;
}
#withdraw .btn-container {
  margin-top: 50px;
}
#withdraw .disabled {
  opacity: 0.6;
}
#withdraw .color-tip {
  color: #1F49DA;
}
#withdraw .red {
  color: #FF3841 !important;
}
#withdraw .fav-title {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #999;
}
#withdraw .amount-size {
  text-align: center;
  color: #161719;
  font-weight: 700;
  font-size: 40px;
  margin: 20px 0 25px;
}
#withdraw .fav-text {
  color: #999;
  font-size: 16px;
}
#withdraw .fav-num {
  text-decoration: line-through;
  color: #FF3841;
  font-size: 16px;
}
#withdraw .fav-tip {
  color: #FFA338;
  font-size: 0.32rem;
  background: #FEF6EB;
  margin: 13px 0 10px 0;
  padding: 5px;
  border-radius: 5px;
  position: relative;
}
#withdraw .fav-tip::before {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #FEF6EB;
  content: " ";
  position: absolute;
  top: -16px;
}
#withdraw .edit-box {
  margin-top: 50px;
}
#withdraw .inputBg {
  background: #EFEFEF;
  margin-right: 5px;
  height: 48px;
  border-radius: 5px;
}
#withdraw .inputBg input {
  text-align: center;
}
#withdraw .inputBg:last-child {
  margin-right: 0;
}
#withdraw .cancelBtn {
  position: absolute;
  top: -50px;
  right: 0;
  border-right: 0 !important;
  color: #fff;
  font-size: 25px !important;
  width: auto;
  padding: 0 !important;
}
#withdraw .confirmBtn {
  width: 80%;
  flex: initial !important;
  margin: 30px auto;
  background: #FF3841;
  color: #fff;
  line-height: initial !important;
  padding: 0 !important;
  height: 40px;
  border-radius: 40px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
#withdraw .fav-dialog .adm-dialog-main .adm-dialog-footer .adm-dialog-action-row {
  border-top: 0;
}
#withdraw .fav-dialog .repeat-tip {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
#withdraw .fav-dialog .cancel-recharge {
  border: 1px solid #FF3841 !important;
  box-sizing: border-box;
  border-radius: 40px !important;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  text-align: center;
  color: #FF3841;
  max-width: 120px;
  height: 40px;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 10px 8px 35px 15px;
}
#withdraw .fav-dialog .confirm-recharge {
  background: #FF3841;
  border-radius: 40px !important;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  text-align: center;
  color: #fff;
  max-width: 120px;
  height: 40px;
  line-height: 1 !important;
  padding: 0 !important;
  margin: 10px 15px 35px 8px;
}
#withdraw .align-center {
  text-align: center;
}
#withdraw .imitate-input {
  height: 44px;
  font-size: 16px;
  color: #333333;
  display: flex;
  align-items: center;
  user-select: text;
  -webkit-user-select: text;
  font-size: 28px !important;
}
#withdraw .imitate-input::after {
  content: '';
  display: block;
  width: 1px;
  height: 28px;
  margin-left: 2px;
  animation: 1s steps(1, start) 0s normal none infinite running blink;
}
#withdraw .waiting-list {
  background: rgba(250, 158, 15, 0.1);
  border-radius: 23px;
  color: #F9941E;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  margin-top: 6px;
  margin-bottom: 20px;
  margin-left: 48px;
}
#withdraw .waiting-list .waiting-list-txt {
  display: inline-block;
  width: 200px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#withdraw .tixian {
  border: none;
  outline: none;
  color: #1F49DA;
  background-color: transparent;
}
#withdraw .jin {
  color: #999;
}
#withdraw .del {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 20%;
  color: #999;
}
#withdraw .loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
