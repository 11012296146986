.stepTwo {
  min-height: 600px !important;
  height: 100vh;
}
.stepTwo .is-synchronous {
  padding: 0 24px;
  margin-bottom: 35px;
}
.stepTwo .is-synchronous .is-synchronous-tip {
  font-size: 12px;
  color: #828282;
}
.stepTwo .is-synchronous .is-synchronous-btn {
  width: 54px;
  height: 20px;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 32px;
  font-size: 12px;
  color: #BDBDBD;
  margin-left: 8px;
}
.stepTwo .is-synchronous .is-synchronous-btn.active {
  border-color: #1F49DA;
  color: #1F49DA;
}
.stepTwo .stepTwo-top {
  padding-top: 24px;
  background: #F9F9F9;
  position: relative;
  margin-bottom: 20px;
}
.stepTwo .stepTwo-top .step-list {
  padding-bottom: 12px;
}
.stepTwo .stepTwo-top .close {
  position: absolute;
  right: 20px;
  top: 20px;
}
.stepTwo .stepTwo-top .close svg {
  width: 24px;
  height: 24px;
}
.stepTwo .stepTwo-top-tip {
  padding-left: 32px;
  margin-bottom: 20px;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.stepTwo .stepTwo-top-tip p {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: #FF3841;
}
.stepTwo .message-container {
  width: 302px;
  margin: 28px auto 16px;
}
.stepTwo .message-container p {
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
.stepTwo .message-container p:nth-of-type(2) {
  font-size: 12px;
  margin-top: 8px;
}
.stepTwo .input-container {
  margin: 24px 24px 16px;
  position: relative;
}
.stepTwo .input-container img {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.stepTwo .input-container .input-inner-container {
  position: relative;
}
.stepTwo .input-container .input-inner-container .clear-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.stepTwo .input-container .input-inner-container .down-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.stepTwo .input-container .address-input {
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 28px;
  font-size: 16px;
  color: #333333;
}
.stepTwo .input-container .address-input.active {
  border: 1px solid #1F49DA;
}
.stepTwo .select-container {
  margin: 24px 24px 16px;
  position: relative;
}
.stepTwo .select-container .state-select {
  border-radius: 44px;
}
.stepTwo .select-container .state-select .state-select-item {
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  height: 18px;
  padding-left: 28px;
  font-size: 16px;
  color: #333333;
}
.stepTwo .select-container .state-select .state-select-item .state-select-item__control .state-select-item__value-container {
  height: 44px !important;
  border: 1px solid #EEEEEE !important;
  border-radius: 44px !important;
  height: 18px !important;
  padding-left: 28px !important;
  font-size: 16px !important;
  color: #333333 !important;
}
.stepTwo .select-container img {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.stepTwo .select-container .input-inner-container {
  position: relative;
}
.stepTwo .select-container .input-inner-container .clear-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.stepTwo .select-container .input-inner-container .down-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.stepTwo .select-container .address-input {
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 28px;
  font-size: 16px;
  color: #333333;
}
.stepTwo .select-container .address-input.active {
  border: 1px solid #1F49DA;
}
.stepTwo .isSynchronous {
  padding: 0 27px;
  margin-bottom: 35px;
}
.stepTwo .isSynchronous .stepTwo-tip {
  font-size: 12px;
  color: #333333;
}
.stepTwo .isSynchronous .btn-container span {
  width: 54px;
  height: 20px;
  border: 1px solid #FF3841;
  box-sizing: border-box;
  border-radius: 32px;
  font-family: Noto Sans;
  font-size: 12px;
  color: #FF3841;
}
.stepTwo .isSynchronous .btn-container span:last-child {
  margin-left: 8px;
}
.stepTwo .isSynchronous .btn-container .active-button {
  background: #FF3841;
  color: #fff;
}
.stepTwo .address-email {
  padding-bottom: 120px;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.stepTwo .address-email .tip1 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding-left: 24px;
}
.stepTwo .address-email .tip2 {
  font-size: 12px;
  line-height: 24px;
  color: #999999;
  padding-left: 24px;
  margin-bottom: 16px;
}
.stepTwo .stepTwo-bottom {
  position: fixed;
  height: 2.88rem;
  background: #FFFFFF;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  max-width: 372px;
  z-index: 100;
  filter: drop-shadow(0px -4px 4px rgba(133, 133, 133, 0.05));
}
.stepTwo .stepTwo-bottom .next-button1 {
  width: 315px;
  height: 40px;
  background: #FF3841;
  border-radius: 40px;
  color: #FFFFFF;
  font-size: 16px;
  margin-top: 0.3rem;
}
.stepTwo .stepTwo-bottom .next-button2 {
  background: #FF8F94;
}
.stepTwo .stepTwo-bottom .product-introduce-stepTwo {
  margin: 6px 0;
  font-size: 14px;
  color: #CCCCCC;
}
.stepTwo .stepTwo-bottom .product-introduce-stepTwo p:nth-of-type(1) {
  margin-bottom: 6px;
}
.css-yk16xz-control {
  border-width: 0.1px !important;
  border-radius: 38px !important;
  height: 44px !important;
}
.css-1s2u09g-control {
  border-width: 0.1px !important;
  border-radius: 38px !important;
  height: 44px !important;
}
.css-319lph-ValueContainer {
  height: 44px !important;
}
.css-6j8wv5-Input {
  height: 44px !important;
  margin: -2px !important;
}
.pos-rel {
  position: relative;
  width: 315px;
  margin: 0 35px 16px 25px;
}
.pos-rel img {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.pos-rel svg {
  position: absolute;
  right: 12px;
  width: 10px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.pos-rel input {
  height: 44px;
  width: 315px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 28px;
  position: relative;
  font-size: 16px;
  color: #999999;
}
.pos-rel input:focus {
  outline: none;
}
.pos-rel .autoContainer {
  position: absolute;
  z-index: 100;
  height: 280px;
  overflow-y: auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  right: 0;
  left: 0;
  top: 44px;
  border: 1px solid #EEEEEE;
  padding-top: 8px;
}
.pos-rel .autoContainer .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  height: 32px;
  line-height: 32px;
  text-transform: capitalize;
  font-family: Noto Sans;
  font-size: 16px;
  color: #333333;
}
.pos-rel .autoContainer span {
  text-transform: capitalize;
  font-family: Noto Sans;
  font-size: 16px;
  color: #333333;
}
.search {
  background-color: #fff;
  width: 100%;
  position: absolute;
  z-index: 111;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.search .search-ul {
  max-height: 200px;
  overflow-y: scroll;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.search .search-ul::-webkit-scrollbar {
  width: 0;
}
.search .search-ul .search-item {
  height: 25px;
  line-height: 25px;
  padding-left: 25px;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}
