#landing-page {
  padding-bottom: 100px;
}
#landing-page.landing-page1 {
  background-color: #659ADC;
}
#landing-page.landing-page2 {
  background-color: #FFF;
}
#landing-page.landing-page3 {
  background-color: #546FC2;
}
#landing-page img {
  width: 100%;
}
#landing-page img:not(:first-child) {
  margin-top: -3px;
}
#landing-page .share-bottom {
  max-width: 375px;
  position: fixed;
  bottom: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 120px;
}
#landing-page .share-bottom .share-btn {
  margin-top: 22px;
  background: #040404;
  border-radius: 40px;
  font-size: 20px;
  color: #FFFFFF;
  padding: 10px 100px;
}
