.card-container {
  padding-top: 0.1px;
  background: #fafafa;
}
.card-container .btn-container .warning-btn {
  width: 315px;
  height: 40px;
  background: #FF3841;
  border: 0;
  border-radius: 40px;
  margin: 24px auto 0;
  color: #FFFFFF;
  font-size: 16px;
}
.card-container .content {
  height: 120px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  user-select: none;
}
.card-container .banner-image {
  cursor: default;
  pointer-events: none;
}
.card-container .join {
  background: #FF3841;
  border-radius: 40px;
  height: 40px;
  margin: 30px;
  line-height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
}
.card-container .card-banner-content-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  color: #000000;
}
.card-container .card-banner-content-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #999999;
  padding-bottom: 44px;
}
.card-container .first-card-tips {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #666666;
  margin-top: 15px;
}
.card-container .submit-apply-success-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #1F49DA;
}
.card-container .submit-apply-success-title.en {
  font-size: 16px;
}
.card-container .submit-apply-success-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 15px;
  text-align: center;
  color: #999999;
}
.card-container .submit-apply-success-sub-title.cn {
  font-size: 12px;
}
.card-container .apply-tips-title {
  display: flex;
  vertical-align: middle;
  padding: 0 16px 0 36px;
  margin-top: 28px;
  margin-bottom: 12px;
}
.card-container .apply-tips-title .icon-img {
  width: 8px;
  vertical-align: sub;
  margin-right: 8px;
}
.card-container .apply-tips-title .text {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  text-align: left;
}
.card-container .tips-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  padding: 0 16px 0 52px;
  text-align: left;
}
.card-container .tips-sub-title.en {
  font-size: 12px;
}
.card-banner-indicator {
  width: fit-content;
}
