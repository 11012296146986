#tutorial-intro {
  background-color: #F9F9F9;
  padding-bottom: 100px;
}
#tutorial-intro .tutorial-intro-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100px;
  color: #000000;
}
#tutorial-intro .adm-nav-bar {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 64px;
  height: 64px;
  background-color: #fff;
  text-align: center;
  color: #000000;
  padding: 0;
}
#tutorial-intro .adm-nav-bar-back-arrow {
  margin-left: 17px;
}
#tutorial-intro .intro-action {
  position: fixed;
  bottom: 0;
  background: #FFFFFF;
  height: 72px;
  width: 100%;
  max-width: 375px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
}
#tutorial-intro .intro-action .use-btn {
  background: #017DD8;
  border-radius: 40px;
  height: 40px;
  line-height: 40px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  margin: 16px 30px;
}
