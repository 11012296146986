#transaction-password-user .TransactionPasswordUser {
  position: relative;
}
#transaction-password-user .TransactionPasswordUser .varification-tips {
  margin-top: 10px;
  text-align: center;
}
#transaction-password-user .TransactionPasswordUser .varification-tips .ttop {
  font-size: 14px;
  color: #FF3841;
}
#transaction-password-user .TransactionPasswordUser .overlayContent {
  width: 320px;
  padding: 40px 24px;
  background: white;
  border-radius: 6px;
  height: auto;
  position: relative;
}
#transaction-password-user .TransactionPasswordUser .verification-code .warpBox .title-warp {
  position: static;
  text-align: center;
  width: 100%;
}
#transaction-password-user .TransactionPasswordUser .verification-code .warpBox .title-warp .sxf {
  position: static;
}
#transaction-password-user .TransactionPasswordUser .verification-code .warpBox .title-warp .sxf-trans {
  text-decoration: line-through;
}
#transaction-password-user .TransactionPasswordUser .verification-code .xss {
  margin-bottom: 12px;
}
#transaction-password-user .TransactionPasswordUser .verification-code .verification {
  width: 100%;
}
#transaction-password-user .TransactionPasswordUser .verification-code .verification .adm-passcode-input-cell-container {
  display: flex;
  justify-content: center;
}
#transaction-password-user .TransactionPasswordUser .verification-code .verification .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #EFEFEF;
  border-radius: 4px;
  border: none;
}
#transaction-password-user .TransactionPasswordUser .verification-code .verification .adm-passcode-input-cell-container .adm-passcode-input-cell.focused {
  box-shadow: 0 0 2px 0 #1F49DA;
}
#transaction-password-user .TransactionPasswordUser .verification-code .verification.adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #FF3841;
  box-shadow: none;
}
#transaction-password-user .TransactionPasswordUser .verification-code .verification.adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell.dot::before {
  background-color: #FFF;
}
.TransactionPasswordUser .warpBox .title-warp p {
  font-size: 26px;
  color: #000000;
  font-style: normal;
  font-weight: normal;
}
.TransactionPasswordUser .warpBox .title-warp .sub-title {
  font-size: 14px;
  margin: 4px auto;
  color: #999;
  margin-bottom: 20px;
}
.TransactionPasswordUser .warpBox .title-warp .money {
  font-size: 28px;
  color: #FF3841;
  margin: 10px 0;
  font-family: 'Alibaba PuHuiTi';
}
.TransactionPasswordUser .warpBox .title-warp .sxf {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 14px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 12px;
}
.TransactionPasswordUser .tips-w {
  color: #FF3841;
  font-size: 12px;
  margin-top: 80px;
}
.TransactionPasswordUser .erro-warp .erro-icon {
  margin: 28px auto;
  text-align: center;
}
.TransactionPasswordUser .erro-warp .erro-icon img {
  width: 60px;
  height: 60px;
}
.TransactionPasswordUser .erro-warp .erro-bx {
  text-align: center;
}
.TransactionPasswordUser .erro-warp .erro-bx h2 {
  font-size: 20px;
  text-align: center;
}
.TransactionPasswordUser .erro-warp .erro-bx span {
  font-size: 14px;
  text-align: center;
  margin: 10px auto;
  color: #666;
  display: block;
}
.TransactionPasswordUser .erro-warp .erro-bx .copy-btn {
  background: #FF3841;
  border-radius: 40px;
  width: 240px;
  height: 40px;
  color: #fff;
  text-align: center;
  margin: 10px auto;
}
.TransactionPasswordUser .close-img {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0px;
  top: -35px;
  cursor: pointer;
}
.xss {
  position: relative;
  color: red;
}
#transfer-money {
  position: relative;
  width: 320px;
  height: fit-content;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 32px 24px;
  background: #FFFFFF;
  border-radius: 12px;
}
#transfer-money .title {
  color: #999;
  font-size: 18px;
  margin-bottom: 15px;
}
#transfer-money .close-img {
  width: 28px;
  height: 27px;
  position: absolute;
  top: -40px;
  right: 0;
}
#transfer-money .amount-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
#transfer-money .amount-item .amount {
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #1F49DA;
}
#transfer-money .amount-item .count {
  display: inline-block;
  width: auto;
  height: 22px;
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 0 5px;
  background: #FF3841;
  border-radius: 12px;
  margin-left: 10px;
}
#transfer-money .money-section .item {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
#transfer-money .money-section .item .label {
  color: #999;
}
#transfer-money .money-section .item .label .question {
  margin-left: 5px;
}
#transfer-money .money-section .item .money {
  color: #333;
}
#transfer-money .friendly-reminder {
  margin: 12px auto;
  background: #FFF8EF;
  border-radius: 34px;
  padding: 4px 8px;
  color: #F9941E;
}
#transfer-money .friendly-reminder.enjoy {
  background: #EFF3FF;
  color: #1F49DA;
}
#transfer-money .tips {
  color: #ffa338;
  background: #ffdfbb5d;
  padding: 6px 10px;
  border-radius: 4px;
  position: relative;
  white-space: nowrap;
  font-size: 12px;
}
#transfer-money .tips .triangle {
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #ffdfbb5d;
}
#transfer-money .btn-width {
  width: 6.4rem;
}
#transfer-money .warning-btn {
  background: #FF3841;
  border: 0;
  font-weight: 500;
  border-radius: 40px;
  margin: 12px auto 0;
  color: #FFFFFF;
  font-size: 16px;
}
