#rmb-payment {
  background: #FAFAFA;
  height: calc(100vh + 164px);
}
#rmb-payment .adm-nav-bar {
  padding: 0 0 0 20px;
  background-color: #fff;
}
#rmb-payment .tutorials-mask {
  padding: 0 20px;
  position: fixed;
  top: 84px;
}
#rmb-payment .tutorials-mask img {
  width: 100%;
}
#rmb-payment .tutorials-mask .tutorials-tips {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.165px;
  color: #FFFFFF;
}
#rmb-payment .tutorials-mask .got {
  background: #FF3841;
  border-radius: 29px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  margin: 100px 86px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.165px;
  color: #FFFFFF;
}
#rmb-payment .swiper {
  padding: 12px 20px 0;
  background-color: #fff;
  margin: 0px;
}
#rmb-payment .input-section {
  padding: 0px 20px;
  background-color: #fff;
}
#rmb-payment .input-section .user-cost {
  display: flex;
  align-items: center;
  height: 68px;
  border: 0.5px solid #F2F2F2;
  border-style: none none solid none;
}
#rmb-payment .input-section .user-cost .symbol {
  flex: 1;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: -0.165px;
  color: #333333;
}
#rmb-payment .input-section .user-cost input {
  width: 90%;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: right;
  letter-spacing: -0.165px;
  color: #1F49DA;
  border: none;
}
#rmb-payment .input-section .user-cost input::-webkit-outer-spin-button,
#rmb-payment .input-section .user-cost input::-webkit-inner-spin-button {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
#rmb-payment .input-section .user-cost input::-webkit-input-placeholder {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  /* identical to box height, or 75% */
  text-align: right;
  letter-spacing: -0.165px;
  color: #CCCCCC;
}
#rmb-payment .input-section .user-cost input::-moz-input-placeholder {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  /* identical to box height, or 75% */
  text-align: right;
  letter-spacing: -0.165px;
  color: #CCCCCC;
}
#rmb-payment .input-section .user-cost input:-ms-input-placeholder {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 75% */
  text-align: right;
  letter-spacing: -0.165px;
  color: #CCCCCC;
}
#rmb-payment .input-section .user-cost input:-moz-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  text-align: right;
  letter-spacing: -0.165px;
  color: #CCCCCC;
}
#rmb-payment .input-section .calculate {
  line-height: 40px;
  font-size: 14px;
  text-align: right;
  color: #999999;
}
#rmb-payment .input-section .calculate .exchange-rate {
  padding-right: 20px;
  line-height: 18px;
}
#rmb-payment .input-section .calculate .total {
  padding-right: 4px;
  line-height: 18px;
  font-weight: 700;
}
#rmb-payment .method {
  margin: 20px 10px 0px;
  background-color: #fff;
  display: flex;
  height: 60px;
  line-height: 60px;
  align-items: center;
  padding: 0px 12px;
  border-radius: 4px;
}
#rmb-payment .method .title {
  font-size: 16px;
  flex: 1;
  color: #999999;
  text-align: left;
}
#rmb-payment .method .value {
  display: flex;
  align-items: center;
  font-family: 'Alibaba PuHuiTi';
}
#rmb-payment .method .value .icon {
  width: 18px;
  height: auto;
}
#rmb-payment .method .value label {
  padding: 0 5.5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  letter-spacing: -0.165px;
  color: #333333;
}
#rmb-payment .steps {
  margin: 10px 10px 0px;
  background-color: #fff;
  padding: 10px;
  text-align: left;
  border-radius: 4px;
}
#rmb-payment .steps .title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 10px 0px;
}
#rmb-payment .steps img {
  width: 100%;
  margin-bottom: 20px;
}
#rmb-payment .steps ul {
  padding: 0px 20px;
}
#rmb-payment .steps ul li {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #666666;
  list-style-type: disc;
}
#rmb-payment .steps ul li span {
  color: #1F49DA;
}
#rmb-payment .keep-operater {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 122px;
  max-width: 375px !important;
  background-color: #fff;
}
#rmb-payment .keep-operater .limit {
  width: 100%;
  height: 32px;
  background-color: #F2F5FF;
  line-height: 32px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.212143px;
  color: #1F49DA;
}
#rmb-payment .keep-operater .actions {
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 18px 20px;
}
#rmb-payment .keep-operater .actions .item {
  padding-right: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
}
#rmb-payment .keep-operater .actions .item img {
  height: 22px;
}
#rmb-payment .keep-operater .actions .item label {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #666666;
  margin-top: 4.5px;
}
#rmb-payment .keep-operater .actions a {
  text-decoration: none;
}
#rmb-payment .keep-operater .actions button {
  height: 40px;
  flex: 1;
  line-height: inherit;
  background-color: #FF3841;
  color: #fff;
  opacity: 0.6;
  border: none;
  border-radius: 80px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.165px;
}
#rmb-payment .keep-operater .actions button.enbaled {
  background-color: #FF8F94;
  color: #FF3841;
}
.methods-action {
  padding: 30px;
}
.methods-action .menu {
  display: flex;
  height: 40px;
  line-height: 40px;
  padding-left: 10%;
}
.methods-action .menu .title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 18.5px;
  text-align: center;
  letter-spacing: -0.151724px;
  color: #000000;
  margin: 0;
  flex: 1;
}
.methods-action .menu .cancel {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  letter-spacing: -0.151724px;
  color: #999999;
}
.methods-action .list .item {
  height: 50px;
  display: flex;
  vertical-align: sub;
}
.methods-action .list .item img {
  width: 25px;
  max-height: 25px;
}
.methods-action .list .item .content {
  flex: 1;
  text-align: left;
}
.methods-action .list .item .content .title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #020202;
}
.methods-action .list .item .content .sub-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #999999;
}
.methods-action .title {
  margin: 0;
}
.methods-action .adm-list {
  --border-top: none;
}
.methods-action .adm-nav-bar {
  padding-top: 0;
}
