.adm-modal .adm-modal-mask {
  z-index: 99;
}
.adm-modal .adm-modal-wrap {
  z-index: 100;
  max-width: 100vw;
}
.adm-modal .adm-modal-main {
  overflow: visible;
}
.adm-modal .adm-modal-main .adm-modal-body-content {
  overflow: visible;
}
.adm-modal .adm-modal-main .adm-modal-footer {
  padding-bottom: 24px;
}
.adm-modal .exception-pop {
  position: relative;
}
.adm-modal .exception-pop .mask-close {
  position: absolute;
  top: -100px;
  right: 0;
  cursor: pointer;
}
.adm-modal .notice-icon {
  width: 85.5px;
  height: 97px;
  position: relative;
  top: -58px;
  z-index: 111;
}
.adm-modal .mask-center {
  height: fit-content !important;
}
.adm-modal .mask-title {
  font-size: 16px;
  color: #333333;
  position: relative;
  top: -42px;
}
.adm-modal .mask-subtitle {
  font-size: 16px;
  color: #333333;
  position: relative;
  top: -16px;
}
.adm-modal .mask-subtitle span {
  color: #1F49DA;
}
.adm-modal .warm-tip {
  font-size: 16px;
  color: #BDBDBD;
}
.adm-modal .confirm-btn {
  display: inline-block;
  width: 240px;
  height: 40px;
  border-radius: 35px;
  border: 1px solid #FF3841;
  text-align: center;
  font-size: 15px;
  line-height: 40px;
  background-color: #FF3841;
  color: #fff;
}
