#kyc-success {
  padding: 24px 24px 24px;
}
#kyc-success .close-action {
  text-align: right;
  margin-bottom: 12px;
}
#kyc-success .kyc-success-container {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
#kyc-success .kyc-success-container .kyc-success-img {
  width: 70px;
  height: 70px;
}
#kyc-success .kyc-success-container .kyc-success-title {
  margin-top: 20px;
  font-size: 27px;
  font-weight: 600;
}
#kyc-success .kyc-success-container .kyc-success-subtitle {
  margin-top: 5px;
  font-size: 14px;
  color: #999;
}
#kyc-success .kyc-success-container .kyc-success-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px;
}
#kyc-success .kyc-success-container .kyc-success-item .kyc-success-time {
  font-size: 16px;
  color: #999;
}
#kyc-success .kyc-success-container .kyc-success-item .kyc-success-number {
  font-size: 16px;
  font-weight: 400;
}
#kyc-success .gotoHome {
  position: fixed;
  bottom: 110px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #999;
}
