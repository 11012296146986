#withdraw-money .overlayContent {
  width: 295px;
  padding: 24px;
  height: fit-content;
  background: white;
  border-radius: 12px;
  position: relative;
  flex-direction: column;
  display: flex;
}
#withdraw-money .title-warp {
  width: 100%;
  text-align: center;
}
#withdraw-money .title-warp .sub-title {
  margin-top: 0;
  padding-top: 0;
  color: #999;
  font-size: 20px;
}
#withdraw-money .title-warp p {
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 700;
  color: #1F49DA;
}
#withdraw-money .title-warp .money-warp {
  margin-top: 16px;
  display: flex;
  line-height: 24px;
  justify-content: space-between;
}
#withdraw-money .title-warp .money-warp .sxf {
  font-size: 16px;
  color: #999999;
}
#withdraw-money .title-warp .money {
  font-size: 16px;
  color: #333;
}
#withdraw-money .title-warp .money-service {
  text-decoration-line: line-through;
}
#withdraw-money .title-warp .tip {
  position: relative;
  margin-top: 6px;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 12px;
  color: #ffa338;
  width: 100%;
  background: #ffdfbb5d;
  text-align: left;
}
#withdraw-money .title-warp .tip .triangle {
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #ffdfbb5d;
}
#withdraw-money .recharge-btn {
  width: 240px;
  margin-top: 24px;
}
#withdraw-money .varification-tips {
  width: 300px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
}
#withdraw-money .tips-w {
  color: #FF3841;
  font-size: 12px;
  margin-top: 80px;
}
#withdraw-money .erro-warp .erro-icon {
  margin: 28px auto;
  text-align: center;
}
#withdraw-money .erro-warp .erro-icon img {
  width: 60px;
  height: 60px;
}
#withdraw-money .erro-warp .erro-bx {
  text-align: center;
}
#withdraw-money .erro-warp .erro-bx h2 {
  font-size: 20px;
  text-align: center;
}
#withdraw-money .erro-warp .erro-bx span {
  font-size: 14px;
  text-align: center;
  margin: 10px auto;
  color: #666;
  display: block;
}
#withdraw-money .erro-warp .erro-bx .copy-btn {
  background: #FF3841;
  border-radius: 40px;
  width: 240px;
  height: 40px;
  color: #fff;
  text-align: center;
  margin: 10px auto;
}
#withdraw-money .close-img {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0px;
  top: -35px;
}
.btn {
  position: absolute;
  bottom: 25px;
}
