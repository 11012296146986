#root #stop-taking-pop {
  position: fixed;
  z-index: 9999;
}
#root #stop-taking-pop .adm-mask {
  position: absolute;
}
#root #stop-taking-pop .stop-taking-text {
  font-size: 14px;
  color: #333;
}
#root #stop-taking-pop .redText {
  color: #FF3841;
  font-size: 14px;
}
#root #stop-taking-pop .common-pop {
  width: 100%;
  padding: 30px;
}
#root #stop-taking-pop .common-pop .common-content {
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  padding-top: 80px;
  padding-bottom: 24px;
  margin-top: -50px;
}
#root #stop-taking-pop .common-pop .common-content .a-r-tips {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
  color: #666666;
}
#root #stop-taking-pop .common-pop .common-content .common-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.151724px;
  color: #000000;
}
#root #stop-taking-pop .common-pop .common-content .common-title.a-r {
  font-size: 18px;
  color: #333;
  line-height: 20px;
}
#root #stop-taking-pop .common-pop .common-content .s-r-btn {
  margin: 20px;
  display: flex;
  vertical-align: middle;
  flex-wrap: wrap;
}
#root #stop-taking-pop .common-pop .common-content .s-r-btn .l-btn {
  height: 40px;
  line-height: 40px;
  border: 1px solid #999999;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #999999;
  border-radius: 40px;
  flex: 1;
  cursor: pointer;
}
#root #stop-taking-pop .common-pop .common-content .s-r-btn .r-btn {
  height: 40px;
  margin-left: 10px;
  line-height: 40px;
  color: #fff;
  background: #FF3841;
  border-radius: 40px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  flex: 1;
  color: #FFFFFF;
  cursor: pointer;
}
#root #stop-taking-pop .common-pop .common-content .s-r-btn .r-btn a {
  color: #fff;
}
#root #stop-taking-pop .common-pop .common-content .s-r-btn .r-btn.p-t {
  margin: 0 80px;
}
#root #stop-taking-pop .common-pop .common-content .s-r-btn .r-btn.a-t {
  margin: 0 50px;
}
#root #stop-taking-pop .common-pop .common-content .common-header-title {
  margin-top: -20px;
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FF3841;
}
#root #stop-taking-pop .common-pop .common-content .common-header-subtitle {
  margin-top: 14px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000;
}
#root #stop-taking-pop .common-pop .qrcode {
  margin-top: 20px;
  width: 196px;
  height: 183px;
}
#root #stop-taking-pop .close {
  text-align: right;
  margin-bottom: 28px;
}
#root #stop-taking-pop a {
  text-decoration: none;
}
#root #stop-taking-pop .common-icon {
  width: 86px;
  margin-top: -28px;
  position: relative;
}
.stop-taking-pop .stop-taking-text {
  font-size: 14px;
  color: #333;
}
.stop-taking-pop .redText {
  color: #FF3841;
  font-size: 14px;
}
.stop-taking-pop .common-pop {
  width: 100%;
  padding: 30px;
}
.stop-taking-pop .common-pop .common-content {
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  padding-top: 80px;
  padding-bottom: 24px;
  margin-top: -50px;
}
.stop-taking-pop .common-pop .common-content .common-tip {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
  color: #666666;
  margin-top: -24px;
  margin-bottom: 12px;
}
.stop-taking-pop .common-pop .common-content .a-r-tips {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
  color: #666666;
}
.stop-taking-pop .common-pop .common-content .common-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}
.stop-taking-pop .common-pop .common-content .common-title span {
  color: #FF0C0C;
}
.stop-taking-pop .common-pop .common-content .common-wechat-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  font-size: 16px;
}
.stop-taking-pop .common-pop .common-content .common-wechat-title span {
  color: #FF0C0C;
}
.stop-taking-pop .common-pop .common-content .common-title.a-r {
  font-size: 18px;
  color: #333;
  line-height: 20px;
}
.stop-taking-pop .common-pop .common-content .common-wechat-title.a-r {
  font-size: 18px;
  color: #333;
}
.stop-taking-pop .common-pop .common-content .copy-link {
  border: 1px solid #EEEEEE;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 14px;
  padding: 4px 10px;
  font-size: 13px;
}
.stop-taking-pop .common-pop .common-content .copy-link .link-text {
  color: #555;
}
.stop-taking-pop .common-pop .common-content .copy-link .copy-btn {
  color: #1F49DA;
}
.stop-taking-pop .common-pop .common-content .copy-tip {
  margin: 6px 20px 24px;
  color: #999999;
  font-size: 12px;
}
.stop-taking-pop .common-pop .common-content .s-r-btn {
  margin: 20px;
  display: flex;
  vertical-align: middle;
  flex-wrap: wrap;
}
.stop-taking-pop .common-pop .common-content .s-r-btn .l-btn {
  height: 40px;
  line-height: 40px;
  border: 1px solid #999999;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #999999;
  border-radius: 40px;
  flex: 1;
  cursor: pointer;
}
.stop-taking-pop .common-pop .common-content .s-r-btn .r-btn {
  height: 40px;
  margin-left: 10px;
  line-height: 40px;
  color: #fff;
  background: #FF3841;
  border-radius: 40px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  flex: 1;
  color: #FFFFFF;
  cursor: pointer;
}
.stop-taking-pop .common-pop .common-content .s-r-btn .r-btn a {
  color: #fff;
}
.stop-taking-pop .common-pop .common-content .s-r-btn .r-btn.p-t {
  margin: 0 80px;
}
.stop-taking-pop .common-pop .common-content .s-r-btn .r-btn.a-t {
  margin: 0 50px;
}
.stop-taking-pop .common-pop .common-content .common-header-title {
  margin-top: -20px;
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FF3841;
}
.stop-taking-pop .common-pop .common-content .common-header-subtitle {
  margin-top: 14px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000;
}
.stop-taking-pop .common-pop .qrcode {
  margin-top: 20px;
  width: 196px;
  height: 183px;
}
.stop-taking-pop .close {
  text-align: right;
  margin-bottom: 28px;
}
.stop-taking-pop a {
  text-decoration: none;
}
.stop-taking-pop .common-icon {
  width: 86px;
  margin-top: -28px;
  position: relative;
}
