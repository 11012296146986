.resetPasswordA-page {
  margin: 0 auto;
  max-width: 400px;
  padding: 12px 32px;
}
.resetPasswordA-page .adm-nav-bar {
  padding: 0;
}
.resetPasswordA-page .resetPasswordA {
  background: #fff;
  height: 100vh;
  padding-top: 60px;
}
.resetPasswordA-page .resetPasswordA .check-warp {
  width: 100%;
  height: 89px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  max-width: 400px;
  margin: 0 auto;
}
.resetPasswordA-page .resetPasswordA .check-warp .check-left {
  display: flex;
}
.resetPasswordA-page .resetPasswordA .check-warp .check-left .check-img {
  width: 54px;
  height: 54px;
  margin: 18px 12px 18px 16px;
  border-radius: 50%;
  background: #F8F8F8;
  overflow: hidden;
}
.resetPasswordA-page .resetPasswordA .check-warp .check-left .check-img img {
  width: 54px;
  height: 54px;
}
.resetPasswordA-page .resetPasswordA .check-warp .check-left .check-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.resetPasswordA-page .resetPasswordA .check-warp .check-left .check-content .title-ch {
  font-size: 18px;
}
.resetPasswordA-page .resetPasswordA .check-warp .check-left .check-content .sub-title {
  font-size: 12px;
  margin: 0;
}
.resetPasswordA-page .resetPasswordA .check-warp svg {
  margin-right: 12px;
}
.resetPasswordA img {
  width: 81px;
  height: 81px;
}
.resetPasswordA .title-warp-container {
  width: 310px;
  margin-bottom: 32px;
}
.resetPasswordA .title-warp-container .title-warp-resetPasswordA {
  font-size: 24px;
  text-align: left;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 8px;
}
.resetPasswordA .title-warp-container .sub-title-warp-resetPasswordA {
  font-size: 12px;
  text-align: left;
  color: #999;
  font-style: normal;
  font-weight: normal;
}
