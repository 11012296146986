#fee-rules {
  position: relative;
  min-height: 100%;
  text-align: left;
}
#fee-rules .sectionTitle {
  display: flex;
  align-items: center;
  padding: 0 0 0 25px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
#fee-rules .sectionIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
#fee-rules .feeRulesClose {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 26px;
  height: 26px;
}
#fee-rules .feeRulesTitle {
  padding: 18px 0 24px 18px;
  color: #FF3841;
  font-weight: 700;
  font-size: 28px;
}
#fee-rules .rulesSection {
  padding: 0 0 40px;
}
#fee-rules .ruleItem {
  position: relative;
  padding: 10px 24px;
  font-size: 14px;
  line-height: 19px;
  --borderColor: #d4d4d4;
}
#fee-rules .ruleItem span {
  color: #FF3841;
}
#fee-rules .ruleItemOpen {
  background: #f9f9f9;
  --borderColor: #f9f9f9;
}
#fee-rules .ruleItem::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 24px;
  right: 24px;
  border-bottom: 0.5px solid var(--borderColor);
}
#fee-rules .ruleItemQ {
  position: relative;
  color: #222;
  padding-right: 32px;
}
#fee-rules .ruleItemQ svg {
  position: absolute;
  top: 6px;
  right: 0;
}
#fee-rules .ruleItemA {
  padding: 12px 0 0;
  color: #666;
}
