#app-guide {
  background-color: #ffffff;
}
#app-guide .app-guide-item {
  position: relative;
}
#app-guide .app-guide-item .switch {
  position: absolute;
  top: 32px;
  right: 30px;
  width: 33.5px;
  height: 27px;
  cursor: pointer;
}
#app-guide .app-guide-item .app-guide-title {
  font-size: 28px;
  color: #000000;
  padding-top: 90px;
}
#app-guide .app-guide-item .app-guide-title.normal {
  font-size: 22px;
  margin-top: 23px;
  padding: 0 32px;
  text-align: left;
}
#app-guide .app-guide-item .app-guide-sub-title {
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #999;
  margin-top: 26px;
}
#app-guide .app-guide-item .app-guide-sub-title.normal {
  margin-top: 13px;
  text-align: left;
  padding: 0 32px;
}
#app-guide .app-guide-item .swiper-text-tips {
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  color: #1F49DA;
}
#app-guide .app-guide-banner-indicator {
  width: fit-content;
  min-height: 0;
}
#app-guide .adm-space.adm-space-block {
  display: contents;
}
#app-guide .adm-page-indicator-dot-active {
  background-color: #FF3841;
}
#app-guide div {
  min-height: 0;
}
