#rmb-flow {
  vertical-align: middle;
  display: flex;
  align-items: center;
  height: 43px;
  line-height: 43px;
  padding: 0 21px;
  justify-content: space-around;
  font-family: 'Alibaba PuHuiTi';
}
#rmb-flow .flow-item {
  display: flex;
  align-items: center;
  font-size: 10px;
  justify-content: space-around;
}
#rmb-flow .flow-item .flow-icon {
  width: 16px;
  max-height: 17px;
  margin: 0 0 0 0px;
  margin-right: 6px;
  vertical-align: middle;
}
#rmb-flow .flow-item span {
  white-space: nowrap;
}
#rmb-flow .flow-item .flow-verctor {
  width: 21px;
  height: auto;
  margin: 0 8px;
  vertical-align: middle;
}
