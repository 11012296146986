html,
body,
#root {
  min-height: 100vh;
  font-family: "Heiti SC" "Droidsansfallback" "Dengxian";
  font-style: normal;
  /* min-width: 375px;
	max-width: 420px; */
  user-select: none;
  /* 用户可以选中的文本单独设置为 user-select: text; */
}
body {
  width: 10rem;
  margin: 0 auto;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 !important;
}
#root > div {
  max-width: 375px !important;
  min-width: 320px !important;
  margin: 0 !important;
  padding: 0 !important;
  flex: 1;
  width: 100%;
  position: relative;
}
#root > div > div {
  position: relative;
  min-height: 100vh;
  /*height: 100vh;*/
  /* border-left: 0.1px solid #eee;
	border-right: 0.1px solid #eee; */
}
li {
  list-style: none;
}
.left {
  color: #000;
}
a.warning-btn {
  width: 90%;
  margin: 30px auto 0;
  border-radius: 30px;
}
.d-flex {
  display: flex;
}
.d-none {
  display: none !important;
}
.invisible {
  visibility: hidden !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.just-content-around {
  justify-content: space-around;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.align-items-between {
  align-items: space-between;
}
.align-items-around {
  align-items: space-around;
}
.align-items-stretch {
  align-items: stretch;
}
.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.warning-btn {
  width: 315px;
  height: 40px;
  background: #FF3841;
  border: 0;
  border-radius: 40px;
  margin: 80px auto 0;
  color: #FFFFFF;
  font-size: 16px;
}
.adm-toast-mask .adm-toast-wrap-text {
  padding: 8px 12px;
}
.adm-auto-center {
  text-align: center;
}
.adm-toast-mask .adm-toast-wrap-icon {
  padding: 16px;
}
.adm-toast-mask .adm-toast-wrap-icon .adm-toast-icon {
  margin-bottom: 8px;
  font-size: 36px;
  line-height: 1;
}
.adm-toast-loading {
  font-size: 24px;
}
.adm-toast-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.adm-toast-mask .adm-toast-main {
  display: inline-block;
  position: unset;
  top: unset;
  left: unset;
  transform: unset;
  width: auto;
  min-width: 96px;
  max-width: 70%;
  max-height: 70%;
  overflow: auto;
  color: white;
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  pointer-events: all;
  font-size: 15px;
  line-height: 1.5;
  box-sizing: border-box;
}
.textAlignLeft {
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
button {
  border-color: transparent;
}
.tillstar-loading {
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 12px;
}
.tillstar-loading-icon {
  width: 42px;
  height: 39px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.adm-pull-to-refresh {
  width: 100%;
}
iframe[data-product="web_widget"] + div {
  display: flex;
  background-color: #FFF;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}
a {
  text-decoration: none;
  color: #1F49DA;
}
