.stepThree .content-tip {
  margin: 24px 24px 40px;
  text-align: left;
  font-size: 14px;
  color: #666666;
}
.stepThree .message-container {
  margin: 20px 24px;
  text-align: left;
  font-size: 14px;
  color: #666666;
}
.stepThree .message-container p {
  font-size: 14px;
  line-height: 20px;
}
.stepThree .message-container p .email {
  color: #000;
}
.stepThree .message-container p:nth-of-type(2) {
  font-size: 12px;
  margin-top: 20px;
}
.stepThree .message-container span {
  color: #1F49DA;
}
.stepThree .input-container {
  margin: 32px 24px 48px;
  position: relative;
}
.stepThree .input-container img {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
}
.stepThree .input-container .email-input {
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 24px;
  font-size: 16px;
  color: #333333;
  position: relative;
}
.stepThree .input-container .email-input.active {
  border: 1px solid #1F49DA;
}
.stepThree .verify-code-container {
  margin: 20px 24px 48px;
  padding-bottom: 0;
}
.stepThree .verify-code-container .verify-code-box {
  position: relative;
}
.stepThree .verify-code-container img {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
}
.stepThree .verify-code-container .verify-code {
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  padding-left: 24px;
  font-size: 16px;
  color: #333333;
}
.stepThree .verify-code-container .verify-code.active {
  border: 1px solid #1F49DA;
}
.stepThree .verify-code-container .error {
  padding-left: 24px;
  margin-top: 8px;
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  color: #FF3841;
}
.stepThree .stepThree-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.stepThree .stepThree-bottom .next-button1 {
  width: 315px;
  height: 40px;
  background: #FF3841;
  border-radius: 40px;
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 12px;
}
.stepThree .stepThree-bottom .next-button2 {
  background: #FF8F94;
}
.stepThree .stepThree-bottom .product-introduce {
  margin-bottom: 16px;
  font-size: 14px;
  color: #CCCCCC;
}
.stepThree .stepThree-bottom .product-introduce p:nth-of-type(1) {
  margin-bottom: 6px;
}
