.message-list-page {
  min-height: 100vh;
}
.message-list-page .message-list {
  min-height: calc(100vh - 45px);
  padding: 0 16px;
  background: #fafafa;
  text-align: left;
}
.message-list-page .message-list .alignCenter {
  text-align: center;
  padding-bottom: 20px;
  font-size: 12px;
  color: #666666;
}
.message-list-page .message-list .no-message {
  width: 100%;
  padding-top: 10vh;
  font-family: "PingFang HK";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.165px;
  color: #999999;
  text-align: center;
}
.message-list-page .message-list .no-message img {
  width: 60%;
  margin-bottom: 20px;
}
.message-list-page .message-list .message-list-item {
  width: 100%;
}
.message-list-page .message-list .message-list-item .message-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  text-align: right;
  color: #999999;
  padding-top: 13px;
}
.message-list-page .message-list .message-list-item .message-item {
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.message-list-page .message-list .message-list-item .message-item .item-left {
  margin-top: 6px;
}
.message-list-page .message-list .message-list-item .message-item .item-left .left-Icon {
  width: 36px;
  height: 36px;
}
.message-list-page .message-list .message-list-item .message-item .item-content {
  background: #ffffff;
  margin-left: 8px;
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  padding: 12px;
}
.message-list-page .message-list .message-list-item .message-item .item-content .item-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
}
.message-list-page .message-list .message-list-item .message-item .item-content .item-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
  padding: 5px 0 8px;
  border-bottom: 1px solid #f7f7f7;
}
.message-list-page .message-list .message-list-item .message-item .item-content .noAction {
  border-bottom: 0;
  padding-bottom: 0;
}
.message-list-page .message-list .message-list-item .message-item .item-content .item-action {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #666666;
}
.message-list-page .message-list .message-list-item .message-item .item-content .item-action .action {
  border: 1.5px solid #c2c0c0;
  width: 10px;
  height: 10px;
  border-left-color: transparent;
  border-top-color: transparent;
  transform: rotate(-45deg);
}
