#kyc-contact-us {
  position: fixed;
  width: 100%;
  max-width: 375px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 22px;
  bottom: 120px;
}
#kyc-contact-us .kyc-contract {
  width: 48px;
  height: 48px;
}
