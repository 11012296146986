#my-address {
  background: #F5F5F5;
}
#my-address .nav-bar {
  background-color: #FFF;
  --height: 65px;
}
#my-address .address {
  margin: 20px;
  text-align: left;
}
#my-address .address .address-title {
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  text-indent: 16px;
}
#my-address .address .address-detail {
  margin-top: 8px;
  padding: 16px;
  font-size: 14px;
  line-height: 19px;
  color: #333;
  font-weight: 500;
  background: #FFFFFF;
  border-radius: 6px;
}
#my-address .address .address-detail .item {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#my-address .address .address-detail .item .edit {
  margin-left: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #267CFF;
}
