#account-lists-section {
  background: #F5F5F5;
}
#account-lists-section .adm-nav-bar {
  background: #FFFFFF;
  padding-bottom: 16px;
}
#account-lists-section .account-lists-container {
  margin: 12px 10px;
}
#account-lists-section .account-lists-container .account-lists {
  background: #FFFFFF;
  padding: 0 24px;
}
#account-lists-section .account-lists-container .account-lists .account-list {
  border-bottom: 1px solid #F4F4F4;
  padding: 16px 0 24px;
  width: 100%;
}
#account-lists-section .account-lists-container .account-lists .account-list .info-detail {
  display: flex;
  align-items: center;
}
#account-lists-section .account-lists-container .account-lists .account-list .info-detail .bank-logo {
  position: relative;
  margin-top: 0;
  width: 40px;
  height: 40px;
}
#account-lists-section .account-lists-container .account-lists .account-list .info-detail .bank-info {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: 244px;
}
#account-lists-section .account-lists-container .account-lists .account-list .info-detail .bank-info .bank-account {
  padding-top: 4px;
  text-align: left;
  font-size: 18px;
  color: #222;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
#account-lists-section .account-lists-container .account-lists .account-list .info-detail .bank-info .bank-account .tip {
  display: inline-block;
  padding: 2px 4px;
  font-size: 10px;
  border-radius: 18px;
  margin-left: 8px;
}
#account-lists-section .account-lists-container .account-lists .account-list .info-detail .bank-info .bank-account .tip.error-tip {
  color: #FF3841;
  background-color: #FFE3E5;
}
#account-lists-section .account-lists-container .account-lists .account-list .info-detail .bank-info .bank-account .tip.warning-tip {
  color: #F9941E;
  background-color: #FEF4E8;
}
#account-lists-section .account-lists-container .account-lists .account-list .info-detail .bank-info .balance-info {
  margin-top: 4px;
  font-size: 12px;
  color: #999;
  text-align: left;
}
#account-lists-section .account-lists-container .account-lists .account-list .info-detail .delete-icon {
  width: 24px;
  margin-left: auto;
}
#account-lists-section .account-lists-container .account-lists .account-list .re-authorize {
  margin-top: 8px;
  display: flex;
  padding-left: 52px;
}
#account-lists-section .account-lists-container .account-lists .account-list .re-authorize .action-info {
  display: inline-block;
  background-color: #FEF4E8;
  border-radius: 30px;
  color: #F9941E;
  font-size: 10px;
  width: 60px;
  padding: 2px 6px;
}
#account-lists-section .account-lists-container .account-lists .add-bank {
  padding: 16px 0 24px;
  width: 100%;
  display: flex;
  align-items: center;
}
#account-lists-section .account-lists-container .account-lists .add-bank .add-icon {
  background-color: #FFF9F9;
  border: 1px solid #FFEAEB;
  border-radius: 50%;
  padding: 5px;
}
#account-lists-section .account-lists-container .account-lists .add-bank .add-tip {
  margin-left: 12px;
  font-size: 14px;
  color: #161719;
}
#delete-account-pop {
  width: 294px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding-bottom: 27px;
  position: relative;
}
#delete-account-pop .close-icon {
  position: absolute;
  top: -48px;
  right: 0;
}
#delete-account-pop .notice-icon {
  margin: 24px auto 14px;
}
#delete-account-pop .title {
  font-weight: 500;
  font-size: 20px;
  color: #161719;
}
#delete-account-pop .account-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto 32px;
}
#delete-account-pop .account-info img {
  width: 40px;
  height: 40px;
  margin-right: 14px;
}
#delete-account-pop .account-info .bank-name {
  font-size: 14px;
  color: #161719;
}
#delete-account-pop .remove-btn {
  width: 240px;
  height: 40px;
  line-height: inherit;
  background-color: #FF3841;
  border-radius: 40px;
  color: #FFF;
}
