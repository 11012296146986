@keyframes cursor-blinks {
  0% {
    opacity: 1;
    display: block;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
.setting-password-c-page {
  margin: 0 auto;
  max-width: 400px;
}
.setting-password-c-page .setting-password-c {
  background: #fff;
  height: 100vh;
  padding-top: 0;
  margin: 60px 24px 0;
}
.setting-password-c-page .setting-password-c .title-warp {
  top: auto;
  margin-bottom: 44px;
  width: 100%;
}
.setting-password-c-page .setting-password-c .title-warp p {
  text-align: left;
  font-size: 28px;
  font-weight: 500;
}
.setting-password-c-page .setting-password-c .subtitle {
  text-align: left;
  color: #999;
  font-size: 14px;
}
.setting-password-c-page .setting-password-c .verification-code {
  width: 100%;
}
.setting-password-c-page .setting-password-c .verification-code .adm-passcode-input-cell-container {
  display: flex;
  justify-content: flex-start;
}
.setting-password-c-page .setting-password-c .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #EFEFEF;
  border-radius: 4px;
  border: none;
}
.setting-password-c-page .setting-password-c .verification-code .adm-passcode-input-cell-container .adm-passcode-input-cell.focused {
  box-shadow: 0 0 2px 0 #1F49DA;
}
.setting-password-c-page .setting-password-c .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell {
  background: #FF3841;
  box-shadow: none;
}
.setting-password-c-page .setting-password-c .verification-code .adm-passcode-input.error .adm-passcode-input-cell-container .adm-passcode-input-cell.dot::before {
  background-color: #FFF;
}
.setting-password-c-page .setting-password-c-varification-tips {
  width: 100%;
  margin-top: 20px;
  text-align: left;
  color: #999;
}
.setting-password-c-page .btn-container {
  width: 100%;
  margin-top: 75px;
}
.setting-password-c-page .btn-container button {
  margin-top: 0 !important;
  width: 100% !important;
}
.setting-password-c .title-warp p {
  font-size: 26px;
  color: #000000;
  font-style: normal;
  font-weight: normal;
}
.setting-password-c .title-warp .sub-title {
  width: 310px;
  font-size: 12px;
  margin: 20px auto;
  color: #999;
}
.setting-password-c .error-message {
  margin-top: 6px;
  text-align: left;
  color: #FF3841;
}
.setting-password-c .verification-warp {
  width: 310px;
  margin: 26px auto 0;
}
.setting-password-c .verification-warp .lista {
  display: flex;
  justify-content: space-between;
}
.setting-password-c .verification-warp .lista .input-warp {
  width: 38px;
  height: 48px;
  position: relative;
  left: 0;
  top: 0;
}
.setting-password-c .verification-warp .lista input {
  width: 38px;
  height: 48px;
  border-radius: 5px;
  text-align: center;
  display: block;
  opacity: 0;
  margin: 0 auto;
  border: none;
}
.setting-password-c .verification-warp .lista .input-b {
  width: 38px;
  height: 48px;
  line-height: 48px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.setting-password-c .verification-warp .lista .input-a {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 30px;
  z-index: 0;
  width: 38px;
  height: 48px;
  line-height: 58px;
  border-radius: 5px;
  background: #EFEFEF;
  text-align: center;
  display: block;
}
.setting-password-c .verification-warp .lista .err-pass .input-a {
  background: #FF3841 !important;
  color: #fff;
}
.setting-password-c .verification-warp .lista .input-active::before {
  display: block;
  content: " ";
  background-color: #0a0a0a;
  letter-spacing: 0.88px;
  width: 2px;
  height: 26px;
  margin: 10px 5px;
  position: absolute;
  right: 0;
  z-index: 11;
  animation: cursor-blinks 1.5s infinite steps(1, start);
}
.setting-password-b .setting-password-c-varification-tips .tips-info {
  padding: 0 40px;
  width: 310px;
  margin-top: 20px;
  color: #999;
  display: flex;
}
.setting-password-b .unvla {
  color: #FF3841;
}
.setting-password-b .dispaly-n {
  display: none;
}
.setting-password-b .erro-tips {
  width: 310px;
  margin-bottom: 12px;
  text-align: left;
  display: block;
  color: #FF3841;
}
.setting-password-b .is-reg {
  background: #FF8F94;
  color: #fff;
}
