.birthday-container .birthday {
  position: relative;
  padding: 12px;
  display: flex;
  border: 1px solid #EEEEEE;
  border-radius: 38px;
}
.birthday-container .birthday .adm-input {
  text-indent: 8px;
}
.birthday-container .birthday::before {
  content: '*';
  position: absolute;
  color: #FF3841;
  font-size: 20px;
  top: 12px;
  left: 10px;
}
