#add-bank {
  background-color: #FAFAFA;
  padding-bottom: 80px;
}
#add-bank .nav {
  background-color: #FFFFFF;
}
#add-bank .header {
  background-color: #FFFFFF;
  padding-top: 24px;
  padding-bottom: 19px;
}
#add-bank .header .title {
  font-size: 24px;
  color: #000;
}
#add-bank .header .sub-title {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
}
#add-bank .header .tip {
  margin-top: 16px;
  font-size: 14px;
  color: #F9941E;
}
#add-bank .header .tutorial {
  width: 335px;
  height: 63px;
  background-color: #F1F4FF;
  border-radius: 12px;
  padding: 14px 19px 13px 0;
  margin-top: 22px;
}
#add-bank .header .tutorial.tutorial_en {
  height: 129px;
  padding-left: 13px;
}
#add-bank .bank-info {
  padding: 16px 24px;
}
#add-bank .bank-info .bank-input {
  text-align: left;
  margin-bottom: 24px;
}
#add-bank .bank-info .bank-input .label-title {
  font-size: 14px;
  color: #000;
}
#add-bank .bank-info .bank-input .label-title svg {
  margin-left: 4px;
}
#add-bank .bank-info .bank-input .account-input {
  padding-top: 8px;
  padding-bottom: 4px;
  margin-bottom: 8px;
  border-bottom: 1px solid #E2E2E2;
}
#add-bank .bank-info .bank-input .error-tip {
  color: #999;
  font-size: 12px;
  position: relative;
  text-indent: 8px;
}
#add-bank .bank-info .bank-input .error-tip::before {
  content: '*';
  position: absolute;
  color: #FF3841;
  font-size: 16px;
  left: -8px;
}
#add-bank .bottom-btn {
  width: 100vw;
  max-width: 375px;
  background: #FFFFFF;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-top: 12px;
  padding-bottom: 28px;
}
#add-bank .bottom-btn button {
  width: 327px;
  height: 40px;
  line-height: inherit;
  background-color: #FF3841;
  border-radius: 40px;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
}
