#transfer-account-user .transfer-navbar {
  background: #FFFAFA;
}
#transfer-account-user .transfer-navbar .transactionAmount {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #FF3841;
}
.TransferAccounts {
  background: #fff;
  height: 100vh;
}
.TransferAccounts .search-warp {
  padding: 44px 20px 0px;
  width: 100%;
  background: #FFFAFA;
}
.TransferAccounts .search-warp .u-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 44px;
}
.TransferAccounts .search-warp .u-list .adm-image {
  width: 48px;
  margin-right: 20px;
}
.TransferAccounts .search-warp .u-list h3 {
  font-size: 18px;
}
.TransferAccounts .search-warp .u-list .num-t {
  color: #999;
  font-size: 16px;
  line-height: 22px;
}
.TransferAccounts .search-warp .u-list .red {
  color: #FF3841 !important;
}
.TransferAccounts .search-warp .name-list {
  margin-left: 20px;
}
.TransferAccounts .search-warp .name-list h3 {
  text-align: left;
}
.TransferAccounts .search-warp .name-list .num-t {
  margin-top: 10px;
}
.TransferAccounts .submit-btn {
  width: 315px;
  height: 44px;
  background: #FF3841;
  border-radius: 44px;
  margin: 48px auto;
  color: #fff;
}
.TransferAccounts .remark-warp {
  width: 315px;
  border: 1px solid #EEEEEE;
  border-radius: 44px;
  margin: 25px auto 15px;
  padding: 6px 20px;
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
}
.TransferAccounts .remark-warp .remark {
  color: #999;
  position: absolute;
  width: 40px;
  top: 7px;
}
.TransferAccounts .remark-warp .tips-t {
  color: #333;
  width: 241px;
  height: 44px;
  line-height: 22px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.TransferAccounts .textArea {
  padding-top: 8px;
}
.avatar {
  width: 48px;
  height: 48px;
}
.mark {
  position: relative;
}
#xxx {
  margin-left: 40px;
  width: 88%;
  position: relative;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
