.tip_dialog {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9998;
  position: fixed;
  background: #fff;
  padding-bottom: 100px;
  overflow-y: auto;
}
.tip_dialog::-webkit-scrollbar {
  opacity: 0;
  width: 0;
}
.tip_dialog .close {
  margin-top: 16px;
  text-align: right;
  margin-right: 24px;
  max-width: 375px;
}
.tip_dialog .dialog__header.is_en {
  margin-top: 16px;
}
.tip_dialog .dialog__header.is_en p {
  font-size: 24px;
}
.tip_dialog .dialog__header p:nth-of-type(1) {
  font-size: 30px;
  font-weight: 700;
  color: #FF3841;
}
.tip_dialog .dialog__header p:nth-of-type(1) span {
  font-size: 58px;
}
.tip_dialog .dialog__header p:nth-of-type(2) {
  font-size: 30px;
  font-weight: 700;
  color: #333333;
}
.tip_dialog .dialog__content {
  position: relative;
}
.tip_dialog .dialog__content img {
  width: 100%;
  max-width: 375px;
  max-height: 583px;
}
.tip_dialog .dialog__footer {
  width: 100%;
  background-color: #fff;
  padding: 20px 24px 32px;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
}
.tip_dialog .dialog__footer .activate-btn {
  background: #FF3841;
  width: 320px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  border-radius: 40px;
  margin-bottom: 12px;
}
.tip_dialog .dialog__footer .activate-tip {
  color: #999999;
  font-size: 14px;
}
