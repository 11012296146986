#update-tillstar-ID-container .tillstar_logo {
  margin-top: 60px;
  width: 45.11px;
  height: 72px;
}
#update-tillstar-ID-container .tip {
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  color: #999;
}
#update-tillstar-ID-container .tillstar-ID-box {
  font-weight: 500;
  font-size: 14px;
}
#update-tillstar-ID-container .tillstar-ID-box.disabled {
  color: #BBBBBB;
}
#update-tillstar-ID-container .error-tip {
  font-size: 12px;
  color: #FF3841;
  text-align: left;
  text-indent: 48px;
}
#update-tillstar-ID-container .adm-input-wrapper {
  margin: 28px 24px 12px;
  border: 1px solid #EEEEEE;
  border-radius: 38px;
  height: 44px;
  line-height: 44px;
  width: calc(100% - 48px);
  padding-left: 24px;
  padding-right: 16px;
  position: relative;
  text-indent: 10px;
}
#update-tillstar-ID-container .adm-input-wrapper::before {
  content: "@";
  color: #333;
  font-weight: 500;
  font-size: 17px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}
#update-tillstar-ID-container .adm-button {
  margin-top: 107px;
  background-color: #FF8F94;
  border-radius: 40px;
  color: #FFF;
  font-weight: 500;
  font-size: 16px;
  width: 200px;
}
#update-tillstar-ID-container .adm-button.active {
  background-color: #FF3841;
}
#update-tillstar-ID-container #common-tip-pop-container .common-tip-pop .common-tip-content .title.tillstar-id-title {
  font-weight: 500;
  color: #333;
}
#update-tillstar-ID-container #common-tip-pop-container .common-tip-pop .common-tip-content .subtitle.tillstar-id-subtitle {
  font-size: 16px;
  color: #666666;
}
#update-tillstar-ID-container #common-tip-pop-container .common-tip-pop .common-tip-content .l-btn.tillstar-id-l-btn {
  background-color: transparent;
}
