#transaction-flow {
  background-color: #fff;
  margin-top: 12px;
  text-align: left;
  padding: 20px 0px;
}
#transaction-flow .check-action {
  font-family: 'Alibaba PuHuiTi';
  padding-bottom: 35vh;
  background: #FFFFFF;
}
#transaction-flow .check-action .check-switch-status {
  /* 主辅色 */
  border: 1px solid #1F49DA;
  border-radius: 40px;
  height: 40px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #1F49DA;
  margin: 0 30px;
}
#transaction-flow .check-action .check-switch-status.check-status {
  margin-top: 16px;
  color: #FF3841;
  border-color: #FF3841;
}
#transaction-flow .receipt {
  padding-bottom: 20px;
}
#transaction-flow .receipt .receipt-check {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.151724px;
  color: #FFA338;
  padding-bottom: 10px;
}
#transaction-flow .receipt .receipt-check.fail {
  color: #FF3841;
}
#transaction-flow .receipt .receipt-check.success {
  color: #1F49DA;
}
#transaction-flow .receipt .adm-image-viewer-image-wrapper {
  max-width: 100vw;
}
#transaction-flow .receipt .adm-image-viewer-image-wrapper img {
  max-width: 375px;
}
#transaction-flow .step-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.151724px;
  color: #999999;
}
#transaction-flow .step-process-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.151724px;
  color: #333333;
  align-items: center;
  display: flex;
  vertical-align: middle;
}
#transaction-flow .step-process-title img {
  width: 16px;
  margin-right: 9px;
}
#transaction-flow .des-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #333;
  align-items: center;
  display: flex;
  vertical-align: middle;
  padding-bottom: 10px;
}
#transaction-flow .des-title img {
  width: 16px;
  margin-right: 9px;
}
#transaction-flow .decription {
  padding-bottom: 20px;
}
#transaction-flow .decription .des-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #333;
  align-items: center;
  display: flex;
  vertical-align: middle;
  padding-bottom: 10px;
}
#transaction-flow .decription .des-title img {
  width: 16px;
  margin-right: 9px;
}
#transaction-flow .decription .item {
  display: flex;
  height: 24px;
  line-height: 24px;
}
#transaction-flow .decription .item .key {
  flex: 1;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8736px;
  letter-spacing: -0.151724px;
  color: #999999;
  text-align: left;
}
#transaction-flow .decription .item .value {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8736px;
  text-align: right;
  letter-spacing: -0.151724px;
  color: #000000;
}
#transaction-flow .adm-steps-vertical {
  padding: 8px 20px 8px 6px;
}
#transaction-flow .payment-detail {
  padding: 0 24px;
}
#transaction-flow .payment-detail li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: #000;
}
#transaction-flow .payment-detail li:not(:last-child) {
  margin-bottom: 12px;
}
#transaction-flow .payment-detail li .value {
  display: flex;
  align-items: center;
}
#transaction-flow .payment-detail li .value img {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}
#transaction-flow .payment-detail li .value .code-copy {
  cursor: pointer;
  margin-left: 6px;
  text-align: center;
  border: 1px solid #F9941E;
  border-radius: 43px;
  color: #F9941E;
  font-size: 11px;
  width: 36px;
  line-height: 16px;
}
.adm-steps-vertical .adm-step .adm-step-indicator:after {
  left: 50%;
  top: calc(13*1.5/2) px;
  width: 0.5px;
  transform: translateX(-10%);
  height: 100%;
  border-style: dashed;
  border: 0.5px solid #f2f2f2;
  border-style: none none none dashed;
}
.adm-steps-vertical .adm-step .adm-step-indicator {
  margin-right: 0px;
}
.adm-steps-vertical .adm-step .adm-step-content {
  padding-bottom: 0px;
}
#waiting-pop {
  background-color: #FFF;
  border-radius: 12px;
  width: 295px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#waiting-pop .header-icon {
  width: 86px;
  margin: -28px auto 0;
  position: relative;
}
#waiting-pop .title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 32px;
}
