#root #freeze-pop-container {
  position: fixed;
  z-index: 9999;
}
#root #freeze-pop-container .close {
  position: absolute;
  right: 0;
  top: -24px;
  text-align: right;
  margin-bottom: 28px;
}
#root #freeze-pop-container .common-pop {
  width: 320px;
}
#root #freeze-pop-container .common-pop .common-content {
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  padding-top: 80px;
  padding-bottom: 24px;
  margin-top: -50px;
}
#root #freeze-pop-container .common-pop .common-content .a-r-tips {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
  color: #666666;
}
#root #freeze-pop-container .common-pop .common-content .common-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.151724px;
  color: #000000;
}
#root #freeze-pop-container .common-pop .common-content .common-title.a-r {
  font-size: 18px;
  color: #333;
  line-height: 20px;
}
#root #freeze-pop-container .common-pop .common-content .s-r-btn {
  margin: 20px;
  display: flex;
  vertical-align: middle;
  flex-wrap: wrap;
}
#root #freeze-pop-container .common-pop .common-content .s-r-btn .l-btn {
  height: 40px;
  line-height: 40px;
  border: 1px solid #999999;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #999999;
  border-radius: 40px;
  flex: 1;
  cursor: pointer;
}
#root #freeze-pop-container .common-pop .common-content .s-r-btn .r-btn {
  height: 40px;
  margin-left: 10px;
  line-height: 40px;
  color: #fff;
  background: #FF3841;
  border-radius: 40px;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  flex: 1;
  color: #FFFFFF;
  cursor: pointer;
}
#root #freeze-pop-container .common-pop .common-content .s-r-btn .r-btn a {
  color: #fff;
}
#root #freeze-pop-container .common-pop .common-content .s-r-btn .r-btn.p-t {
  margin: 0 80px;
}
#root #freeze-pop-container .common-pop .common-content .s-r-btn .r-btn.a-t {
  margin: 0 50px;
}
#root #freeze-pop-container .common-pop .common-content .common-header-title {
  margin-top: -20px;
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FF3841;
}
#root #freeze-pop-container .common-pop .common-content .common-header-subtitle {
  margin-top: 14px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000;
}
#root #freeze-pop-container .common-pop .qrcode {
  display: block;
  margin-top: 20px;
  width: 196px;
  height: 183px;
}
#root #freeze-pop-container .common-pop .back-btn {
  background-color: #FFFFFF;
  margin-top: 20px;
  border: 1px solid #FF3841;
  border-radius: 62px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #FF3841;
  width: 210px;
  height: 40px;
}
#root #freeze-pop-container a {
  text-decoration: none;
}
#root #freeze-pop-container .common-icon {
  width: 86px;
  margin-top: -28px;
  position: relative;
}
