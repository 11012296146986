#faq {
  height: 100vh;
  background-color: #fff;
}
#faq .adm-nav-bar {
  padding: 0;
  height: 64px;
}
#faq .adm-nav-bar-back {
  margin-left: 4px;
}
#faq .adm-nav-bar-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #000000;
}
#faq a {
  text-decoration: none;
}
#faq .faq-banner {
  width: 100%;
}
#faq .faq-section {
  text-align: left;
}
#faq .faq-section .main-title {
  width: 100px;
  height: 24px;
  line-height: 24px;
  background: #000;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #FFF;
  margin: 20px auto 28px;
}
#faq .faq-section .tag {
  font-family: 'PingFang HK';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  padding: 0 6px;
  color: #FFFFFF;
  background: linear-gradient(64.34deg, #FF8D00 0%, #FFD96C 137.88%);
  border-radius: 8px;
  margin-left: 8px;
}
#faq .faq-item {
  text-align: center;
  padding: 0px 2px 20px;
}
#faq .faq-item img {
  width: 40px;
}
#faq .faq-item .text {
  font-family: 'PingFang HK';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding-top: 4px;
  line-height: 14px;
  text-align: center;
  color: #222222;
}
