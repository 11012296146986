.myHeader {
  padding: 25px 24px 12px;
  position: relative;
}
.myHeader .shortcut-section {
  position: relative;
}
.myHeader .shortcut-section .star-icon {
  width: 240px;
  position: absolute;
  right: -76px;
  top: -38px;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  background: #FFF0F1;
  border-radius: 4px;
}
.myHeader .shortcut-section .star-icon .triangle {
  position: absolute;
  width: 0;
  height: 0;
  right: 84px;
  bottom: -10px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #FFF0F1;
}
.myHeader .shortcut-section .star-icon img {
  width: 16px;
  height: 16px;
}
.myHeader .shortcut-section .star-icon span {
  font-size: 12px;
  color: #FF3841;
}
.myHeader .img-wrapper img {
  margin-left: 12px;
}
.myHeader .img-wrapper .share {
  width: 27px;
  height: 27px;
}
.myHeader .img-wrapper .avatar {
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.myHeader .img-wrapper .search {
  width: 28px;
  height: 28px;
  position: relative;
  cursor: pointer;
}
.myHeader .img-wrapper .switch-lang {
  width: 33.5px;
  height: 27px;
  cursor: pointer;
}
.myHeader .img-wrapper .msg {
  margin-right: 18px;
}
.myHeader .img-wrapper .messageImg {
  width: 26px;
  height: 26px;
  line-height: 20px;
  margin-left: 8px;
  cursor: pointer;
}
.myHeader .logo {
  width: 100.5px;
  height: 24.5px;
}
.myHeader span {
  font-size: 18px;
  color: #222222;
}
.myHeader .sub-title {
  font-size: 12px;
  color: #999999;
  margin-left: 12px;
}
.myHeader .tip {
  position: absolute;
  left: 24px;
  padding: 0 15px 0 13px;
  width: fit-content;
  height: 28px;
  background: #EDF1FF;
  border-radius: 14px;
}
.myHeader .tip img {
  width: 18px;
  height: 18px;
}
.myHeader .tip span {
  font-size: 12px;
  color: #1F49DA;
}
