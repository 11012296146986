.methods-action {
  padding: 30px;
  max-width: 375px;
}
.methods-action .menu {
  display: flex;
  height: 40px;
  line-height: 40px;
  padding-left: 10%;
}
.methods-action .menu .title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 18.5px;
  text-align: center;
  letter-spacing: -0.151724px;
  color: #000000;
  margin: 0;
  flex: 1;
}
.methods-action .menu .cancel {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  letter-spacing: -0.151724px;
  color: #999999;
}
.methods-action .list .item {
  height: 50px;
  display: flex;
  vertical-align: sub;
  align-items: center;
}
.methods-action .list .item img {
  width: 25px;
  max-height: 25px;
}
.methods-action .list .item .content {
  margin-left: 8px;
  flex: 1;
  text-align: left;
}
.methods-action .list .item .content .title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #020202;
  display: flex;
  align-items: center;
}
.methods-action .list .item .content .title span {
  margin-right: 8px;
}
.methods-action .list .item .content .title .unionpay {
  width: 98px;
  height: 16px;
}
.methods-action .list .item .content .title .non-unionpay {
  width: 59px;
  height: 16px;
}
.methods-action .list .item .content .sub-title {
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #999999;
}
.methods-action .title {
  margin: 0;
}
.methods-action .adm-list {
  --border-top: none;
}
.methods-action .adm-nav-bar {
  padding-top: 0;
}
