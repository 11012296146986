.navbar-header {
  padding-bottom: 10px;
  background-color: #f9f9f9;
}
.navbar-header .adm-nav-bar {
  height: 64px;
}
.navbar-header .adm-nav-bar-back-arrow {
  font-size: 20px;
}
