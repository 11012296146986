.steps {
  margin: 0 auto;
}
.steps .steps-top {
  padding-top: 20px;
  padding-bottom: 18px;
  background: #F9F9F9;
}
.steps .steps-top .steps-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
}
.steps .steps-top-tips {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 12px;
  margin-bottom: 24px;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.steps .steps-top-tips p:nth-of-type(1) {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #FF3841;
}
.steps .steps-top-tips p:nth-of-type(2) {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #FF3841;
  margin-bottom: 12px;
}
.steps .steps-top-tips p:nth-of-type(3) {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
}
.steps .steps-top-tips p:nth-of-type(4) {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
}
