.stopTaking {
  width: 100%;
  min-height: 100vh;
  background: #fff;
}
.stopTaking .stopTaking-content {
  width: 100%;
}
.stopTaking .stopTaking-content img {
  width: 100%;
}
