#add-bank-result {
  position: relative;
}
#add-bank-result .waiting-icon {
  margin-top: 28px;
}
#add-bank-result .title {
  margin-top: 28px;
  font-size: 24px;
  color: #000000;
}
#add-bank-result .tip {
  margin-top: 15px;
  margin-bottom: 38px;
  font-size: 14px;
  color: #999;
}
#add-bank-result .steps {
  margin: 38px 20px;
}
#add-bank-result .steps p {
  text-align: left;
  font-size: 14px;
  color: #161719;
  margin-bottom: 18px;
}
#add-bank-result button {
  width: 327px;
  height: 40px;
  line-height: inherit;
  background-color: #FF3841;
  border-radius: 40px;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  bottom: 40px;
  margin-left: -163.5px;
}
