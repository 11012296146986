.stepNine-page-footer {
  position: fixed;
  max-width: 375px;
  width: 100%;
  padding-bottom: 28px;
  background: #FFF;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px -4px 4px rgba(133, 133, 133, 0.05);
}
.stepNine-page-footer .notice {
  margin-top: 0;
  padding: 6px 24px;
  width: 100%;
  background-color: rgba(249, 148, 30, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #F9941E;
}
.stepNine-page-footer .notice span {
  text-align: left;
  margin-left: 4px;
}
.stepNine-page-footer .next-button {
  margin: 0 auto;
  width: 320px;
  height: 40px;
  background: #FF3841;
  border-radius: 40px;
  color: #FFFFFF;
  font-size: 16px;
  margin-top: 10px;
}
.stepNine-page-footer .next-button:active {
  background: #DF1A23;
}
.stepNine-page-footer p {
  margin-top: 10px;
  font-size: 14px;
  color: #CCCCCC;
}
