.common-bottom-popup {
  min-height: 164px;
  background-color: #FFF;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 40px 24px 36px;
  max-width: 375px;
}
.common-bottom-popup .popup-title {
  font-size: 20px;
  color: #000;
}
.common-bottom-popup .popup-content {
  font-size: 15px;
  color: #666;
  margin-top: 12px;
}
