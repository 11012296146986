.qa-detail {
  background-color: #fff;
}
.qa-detail .faq-banner {
  width: 100%;
}
.qa-detail .item-title {
  padding: 23px 18px;
  text-align: left;
  display: flex;
  vertical-align: middle;
  font-style: normal;
  font-weight: 400;
  font-size: 16.3043px;
  color: #4460C0;
  align-items: center;
}
.qa-detail .item-title .adm-image {
  width: 16px;
  margin-right: 8px;
}
.qa-detail label {
  color: #333;
}
.qa-detail li {
  list-style-type: disc;
  margin-left: 20px;
}
.qa-detail .adm-nav-bar {
  padding: 0;
  height: 64px;
}
.qa-detail .adm-nav-bar-back {
  margin-left: 4px;
}
.qa-detail .adm-nav-bar-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.165px;
  color: #000000;
}
.qa-detail .adm-list-item-content-main {
  font-style: normal;
  font-weight: 400;
  font-size: 13.587px;
  color: #222222;
  text-align: left;
  font-family: 'Alibaba PuHuiTi';
}
.qa-detail .adm-list-item {
  padding: 0 18px;
}
.qa-detail .adm-list-item-content {
  padding: 10px 0px;
}
.qa-detail .adm-list {
  border: none;
}
.qa-detail .adm-list-item-content-arrow {
  font-size: 10px;
  color: #666666;
}
.qa-detail .adm-collapse-panel-content-inner .adm-list-item-content-main {
  color: #9999;
  font-family: 'Alibaba PuHuiTi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.qa-detail .adm-collapse-panel-content-inner {
  background-color: #f9f9f9;
}
.qa-detail .nav-text {
  color: #4460C0;
  cursor: pointer;
}
.qa-detail .qa-list-item-content {
  padding-bottom: 120px;
}
