#bills-Of-living .exampleImage {
  position: relative;
}
#bills-Of-living .exampleImage .close {
  position: absolute;
  text-align: right;
  color: #fff;
  top: -44px;
  right: 10%;
}
#bills-Of-living .exampleImage .example {
  width: 80%;
  max-width: 375px;
}
