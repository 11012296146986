#bills-Of-living .kyc-header {
  background-color: #F9F9F9;
}
#bills-Of-living .kyc-header.show-step-list {
  padding-bottom: 22px;
}
#bills-Of-living .kyc-header .step-list {
  justify-content: flex-start;
}
#bills-Of-living .kyc-header .step-list li span {
  line-height: normal;
}
#bills-Of-living .kyc-header .step-list svg {
  margin-left: 4px;
  margin-right: 4px;
}
#bills-Of-living .exampleImage {
  position: relative;
}
#bills-Of-living .exampleImage .close {
  position: absolute;
  text-align: right;
  color: #fff;
  top: -44px;
  right: 10%;
}
#bills-Of-living .exampleImage .example {
  width: 80%;
  max-width: 375px;
}
