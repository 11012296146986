#transaction-receipt {
  background-color: #FAFAFA;
  height: 100vh;
}
#transaction-receipt .nav-bar {
  background-color: #fff;
}
#transaction-receipt .transaction-receipt-content {
  background-color: #fff;
  margin-top: 12px;
  padding: 22px;
  height: 274px;
  text-align: left;
}
#transaction-receipt .transaction-receipt-content .title {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
#transaction-receipt button {
  background: #FF8F94;
  border-radius: 40px;
  height: 40px;
  margin: 60px 28px;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  width: -webkit-fill-available;
  max-width: 496px;
  border: none;
}
#transaction-receipt .adm-image-uploader-upload-button-wrap .adm-image-uploader-upload-button-icon {
  font-size: 42px;
  color: #FF3841;
}
#transaction-receipt .adm-image-uploader-upload-button-wrap .adm-image-uploader-upload-button {
  background-color: #FFFBFB;
  border: 1.5px solid #FFECED;
}
#transaction-receipt .adm-image-uploader {
  padding-top: 18px;
}
#transaction-receipt .adm-image-uploader-cell-delete {
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
  background-color: #FF3841;
  border-radius: 0 0 0 12px;
  font-size: 8px;
  color: #fff;
  cursor: pointer;
}
#transaction-receipt .adm-image-viewer-image-wrapper img {
  max-width: 375px;
}
.adm-image-viewer-content {
  overflow: hidden;
}
