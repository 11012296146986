#policy-container {
  background-color: #FAFAFA;
}
#policy-container .adm-nav-bar {
  background-color: #FFF;
  padding-bottom: 12px;
}
#policy-container .policy-items {
  height: calc(100vh - 68px);
  padding: 24px;
  margin-top: 4px;
  background-color: #FFF;
}
#policy-container .policy-items .policy-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}
#policy-container .policy-items .policy-item span {
  font-weight: 500;
  font-size: 14px;
  color: #161719;
}
