.tpst {
  width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.stepNine {
  height: 100%;
  padding-bottom: 138px;
}
.stepNine .upload-image {
  position: relative;
  margin-top: 16px;
}
.stepNine .upload-image .holder-image {
  width: 100%;
}
.stepNine .upload-image .tip-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  position: absolute;
  width: 242px;
  left: 42px;
  bottom: 13px;
}
.stepNine .upload-image .tip-text p:nth-of-type(1) {
  color: #999;
}
.stepNine .upload-image .tip-text p:nth-of-type(2) {
  color: #1F49DA;
}
.stepNine .upload-image .tpst {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
}
.stepNine .upload-image .bottom-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 32px;
  background: rgba(0, 0, 0, 0.6);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.stepNine .upload-image .bottom-btn input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 100;
}
.stepNine .upload-image .bottom-btn .action {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepNine .upload-image .bottom-btn .action img {
  margin-right: 4px;
  width: 15px;
}
.stepNine .adm-modal-footer {
  display: none;
}
.step-bottom-Nine {
  position: fixed;
  width: 100%;
  max-width: 373px !important;
  bottom: 0;
  text-align: center;
  margin: 1rem auto 0px;
  border-top: 1px solid #ececec;
  background-color: #ffffff;
  padding-top: 12px;
}
.step-bottom-Nine p {
  margin-bottom: 0.3rem;
}
.step-bottom-Nine button {
  margin: 0 auto;
}
