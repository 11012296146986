.step-list {
  padding: 0 24px;
  justify-content: space-between;
  align-items: center;
}
.step-list li {
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.step-list li span {
  display: inline-flex;
  color: #999999;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.step-list li .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  background: rgba(31, 73, 218, 0.1);
  border-radius: 100%;
  margin-right: 4px;
}
.step-list li .circle .inner-circle {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #1F49DA;
}
.step-list .active span {
  color: #1F49DA;
}
