.login {
  background: #fafafa;
  height: 100%;
  padding-bottom: 30px;
}
.login .login-header {
  max-width: 100%;
  margin: 0 auto;
  background: #fafafa;
  position: relative;
}
.login .login-header .login-logo {
  width: 100%;
  height: 280px;
}
.login .login-header .login-close {
  position: absolute;
  right: 15px;
  top: 17px;
  width: 22px;
  height: 22px;
}
.login .login-container {
  margin-left: 30px;
  margin-right: 30px;
  background: #fafafa;
}
.login .login-container .login-dle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 54px;
}
.login .login-container .login-dle .login-title {
  font-size: 18px;
  color: #000000;
  margin-bottom: 2px;
  line-height: 25px;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.login .login-container .login-dle .languageSwitch {
  width: 33.5px;
  height: 27px;
  cursor: pointer;
}
.login .login-container .login-subTitle {
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
.login .login-container .input-container {
  position: relative;
  width: 100%;
  height: 44px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 38px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #999999;
  background-color: #fff;
  margin-top: 12px;
}
.login .login-container .input-container input {
  font-size: 16px;
}
.login .login-container .input-container .phone-prefix {
  color: #333333;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}
.login .login-container .input-container .phone-prefix svg {
  margin-left: 6px;
  padding-top: 2px;
}
.login .login-container .input-container .phone-prefix::after {
  content: "";
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 16px;
  background: #E2E2E2;
}
.login .login-container .input-container .login-btn-text {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #FF8F94;
  font-size: 16px;
}
.login .login-container .phone-container {
  padding-left: 74px;
}
.login .login-container .code-container {
  padding-left: 16px;
  padding-right: 16px;
}
.login .login-container .code-container input:-webkit-autofill {
  box-shadow: 0 0 0 500px white inset !important;
  -webkit-box-shadow: 0 0 0 500px white inset !important;
}
.login .btn-container {
  background: #fafafa;
  margin-top: 20px;
}
.login .btn-container .warning-btn {
  width: 315px;
  height: 40px;
  font-size: 16px;
}
.login .btn-container .loginButton {
  width: 285px;
  height: 40px;
  font-size: 18px;
  background: #FF3841;
  border: 0;
  border-radius: 40px;
  margin: 0;
  color: #FFFFFF;
}
.login .btn-container .warning-btn1 {
  background: #FF8F94;
}
.login .agreement {
  padding: 20px 32px;
  display: flex;
  align-items: flex-start;
  background: #fafafa;
}
.login .agreement img {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  margin-top: 2px;
}
.login .agreement span {
  font-size: 12px;
  color: #999;
}
.login .agreement span span {
  color: #000;
}
.login .am-list-item {
  width: 315px;
  height: 44px;
  border: 1px solid #EEEEEE;
  border-radius: 38px;
}
.login .am-list-item .am-input-control {
  color: pink !important;
}
.login .am-list-item .am-input-control input {
  font-size: 12px !important;
}
.login .am-list-item .am-input-label.am-input-label-5 {
  width: 60px !important;
}
.login .input {
  margin: 0 !important;
}
.adm-popup-body {
  background-color: transparent;
}
.adm-picker {
  max-width: 375px;
  width: 100%;
  background-color: #fff;
}
